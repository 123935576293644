import React, {ReactNode, useEffect, useState} from 'react'
import {css, useTheme} from '@emotion/react'
import styled from '@emotion/styled'
import {anyPass, isEmpty, isNil} from 'ramda'
import {SizesType} from 'types/Sizes'
import {ColorPath} from 'types/Theme'

import {getFirstAndLastInitials} from '@daedalus/core/src/auth/business/authentication'
// NOTE: This component uses an Icon size not supported by Icon component
import {User} from '@findhotel/atlas-assets'

import {getColorByPath} from '../../../utils/colors'
import {Text, TextVariant} from '../Text'

type AvatarSize = Extract<SizesType, 'md' | 'lg' | 'xl'>

interface Props {
  /** Border color */
  borderColorPath: ColorPath
  /** Bottom icon */
  bottomIcon?: ReactNode
  /** Pass through classname to allow styles overrides */
  className?: string
  /** The size of the Avatar */
  size?: AvatarSize
  /** Appearance of avatar */
  type?: AvatarType
  /** User's picture */
  picture?: string
  /** User's name */
  name?: string
}

interface UserPhotoFallbackProps {
  size: AvatarSize
  name?: Props['name']
}

interface StandardAvatarProps {
  size: AvatarSize
  name?: Props['name']
}

interface AvatarStyles {
  borderColorValue?: string
  name?: Props['name']
  size: AvatarSize
}

export enum AvatarType {
  Picture = 'picture',
  Icon = 'icon'
}

export const avatarSizesMap = {
  md: {
    size: 32,
    iconSize: 16,
    padding: 4,
    border: 1,
    textVariant: 'titleXS'
  },
  lg: {
    size: 72,
    iconSize: 40,
    padding: 9,
    border: 2,
    textVariant: 'titleM'
  },
  xl: {
    size: 96,
    iconSize: 48,
    padding: 12,
    border: 3,
    textVariant: 'titleXL'
  }
}

const withBorderStyles = ({size, borderColorValue}: AvatarStyles) =>
  css`
    padding: ${avatarSizesMap[size].padding}px;
    ${Boolean(borderColorValue) &&
    css`
      box-shadow: inset 0 0 0 ${avatarSizesMap[size].border}px
        ${borderColorValue};
    `}
  `

const AvatarWrapper = styled.div<AvatarStyles>(
  () => css`
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
  `,
  withBorderStyles
)

export const PictureAvatar = styled.img(
  ({size}: StandardAvatarProps) => css`
    height: ${avatarSizesMap[size].size}px;
    width: ${avatarSizesMap[size].size}px;
    border-radius: 50%;
    object-fit: cover;
    display: block;
  `
)

export const DefaultPictureWrapper = styled.div<StandardAvatarProps>(
  ({size, theme}) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ${avatarSizesMap[size].size}px;
    height: ${avatarSizesMap[size].size}px;
    border-radius: 50%;
    background-color: ${theme.colors.background.inverse.c950};
    color: ${theme.colors.content.neutral.c000};
  `
)

export const IconWrapper = styled.div(
  ({theme}) => css`
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: -${theme.layout.spacing.s200}px;
  `
)

const StandardAvatar = ({size}: StandardAvatarProps) => (
  <DefaultPictureWrapper size={size}>
    <User size={avatarSizesMap[size].iconSize} />
  </DefaultPictureWrapper>
)

const UserPhotoFallback = ({name, size}: UserPhotoFallbackProps) => {
  const initials = getFirstAndLastInitials(name || '')

  return (
    <DefaultPictureWrapper data-id="ProfilePicture" size={size}>
      <Text variant={avatarSizesMap[size].textVariant as TextVariant}>
        {initials}
      </Text>
    </DefaultPictureWrapper>
  )
}

export const getShowAvatar = ({
  type,
  name,
  picture
}: {
  type: AvatarType
  name?: string
  picture?: string
}) =>
  type === AvatarType.Icon ||
  (anyPass([isNil, isEmpty])(name) && anyPass([isNil, isEmpty])(picture))

export const Avatar = ({
  borderColorPath,
  picture,
  name,
  bottomIcon,
  size = 'md',
  type = AvatarType.Icon,
  className
}: Props) => {
  const [shouldShowFallbackAvatar, setShouldShowFallbackAvatar] = useState(true)
  const theme = useTheme()
  const shouldShowIconAvatar = getShowAvatar({type, name, picture})
  const borderColorValue = getColorByPath(theme, borderColorPath)

  useEffect(() => {
    if (!picture) return setShouldShowFallbackAvatar(true)

    setShouldShowFallbackAvatar(false)
    const image = new Image()
    image.addEventListener('error', () => setShouldShowFallbackAvatar(true))
    image.src = picture
  }, [picture])

  const AvatarElement = shouldShowIconAvatar
    ? StandardAvatar
    : shouldShowFallbackAvatar
    ? UserPhotoFallback
    : PictureAvatar

  return (
    <AvatarWrapper
      borderColorValue={borderColorValue}
      size={size}
      className={className}
    >
      <AvatarElement
        data-id="ProfilePicture"
        className="fs-exclude"
        src={picture}
        size={size}
        alt={name}
        title={name}
        name={name}
      />
      {Boolean(bottomIcon) && <IconWrapper>{bottomIcon}</IconWrapper>}
    </AvatarWrapper>
  )
}
