import React, {ReactNode} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Card} from '@daedalus/atlas/Card'
import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {PageWidthContainer as PageWidthContainerElement} from '@daedalus/atlas/helpers/PageWidthContainer'
import {mq} from '@daedalus/atlas/utils/breakpoints'
import {Brand} from '@daedalus/core/src/brand/types'
import {checkIsBrandVio} from '@daedalus/core/src/brand/utils'

import {ProfilePicture} from '../../ProfilePicture'
import {DeleteAccountModal} from './DeleteAccountModal'
import {DeleteAccountProvider} from './DeleteAccountProvider'
import {DeleteUserProfile} from './DeleteUserProfile'
import {PersonalDetailsForm} from './PersonalDetailsForm'

const SIDEBAR_MAX_WIDTH = 294
const HEADER_HEIGHT_MOBILE = 65

const PageGrid = styled.div(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: ${theme.layout.spacing.s800}px;
    padding: ${theme.layout.spacing.s700}px 0px ${theme.layout.spacing.s600}px;

    ${mq.desktopXs(css`
      display: grid;
      grid-template-columns:
        ${SIDEBAR_MAX_WIDTH}px
        minmax(min-content, auto);
      gap: ${theme.layout.spacing.s600}px;
      justify-content: normal;
      align-items: normal;
      padding: 0;
    `)}
  `
)

const PageWidthContainer = styled(PageWidthContainerElement)(
  ({theme}) =>
    css`
      height: 100%;

      ${mq.mobileSm(`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      `)}

      ${mq.desktopXs(css`
        padding: 0 ${theme.layout.spacing.s500}px;
      `)}
    `
)

const PageContainerWrapper = styled.div(
  ({theme}) =>
    css`
      height: calc(100vh - ${HEADER_HEIGHT_MOBILE}px);
      flex: 1 auto;

      ${mq.desktopXs(css`
        height: auto;
        padding: ${theme.layout.spacing.s500}px 0;
        background: ${theme.colors.background.neutral.c050};
      `)}
    `
)

const MobileContentWrapper = styled.div`
  width: 100%;
`

interface ContentWrapperProps {
  isMobile: boolean
  children: React.ReactNode
}

const ContentWrapper = ({isMobile, children}: ContentWrapperProps) =>
  isMobile ? (
    <MobileContentWrapper>{children}</MobileContentWrapper>
  ) : (
    <div>
      <Card borderRadius="md" shadow="action">
        {children}
      </Card>
    </div>
  )

interface AccountContentPropTypes {
  accountSideMenu?: ReactNode
  brand: Brand
}

export const AccountContent = ({
  accountSideMenu,
  brand
}: AccountContentPropTypes) => {
  const {isMobile} = useDeviceLayout()
  const isVio = checkIsBrandVio(brand)

  return (
    <DeleteAccountProvider>
      <PageContainerWrapper>
        <PageWidthContainer>
          <PageGrid>
            <ContentWrapper isMobile={isMobile}>
              <ProfilePicture hasBottomIcon={isVio} />
              {accountSideMenu}
            </ContentWrapper>
            <ContentWrapper isMobile={isMobile}>
              <PersonalDetailsForm brand={brand} />
            </ContentWrapper>
          </PageGrid>
          {isMobile ? (
            <PageGrid>
              <div>
                <DeleteUserProfile />
              </div>
            </PageGrid>
          ) : null}
        </PageWidthContainer>
      </PageContainerWrapper>
      <DeleteAccountModal brand={brand} />
    </DeleteAccountProvider>
  )
}
