import {ActionLinkTypes} from '@daedalus/core/src/offer/types/actionLink'

import {ErrorResponse} from './ErrorResponse'
import {LinkType} from './Link'

export enum BookingStatus {
  INITIALIZED = 'initialized',
  FINALIZING = 'finalizing',
  CONFIRMED = 'confirmed',
  THREE_DOMAIN_SECURE_REQUIRED = 'three_domain_secure_required',
  CANCELLED = 'cancelled',
  ERROR = 'error',
  FROZEN = 'frozen',
  FREEZE_CANCELLED = 'freeze_cancelled',
  FREEZE_EXPIRED = 'freeze_expired',
  CHARGE_LATER_CONFIRMED = 'charge_later_confirmed',
  CHARGE_LATER_FAILED = 'charge_later_failed',
  // When the Charge Later booking expires due to payment failure
  CHARGE_LATER_EXPIRED = 'charge_later_expired',
  // When customer cancels the Charge Later booking before the payment is done
  CHARGE_LATER_CANCELLED = 'charge_later_cancelled'
}

export interface BookingStatusBaseResponse {
  id: string
  status:
    | BookingStatus.INITIALIZED
    | BookingStatus.FINALIZING
    | BookingStatus.CANCELLED
  links: []
}

interface ThreeDomainSecureRequiredLink extends LinkType {
  href: string
  method: 'POST'
  type: ActionLinkTypes.BOOKING_FINALIZATION_ASYNC
}

interface ConfirmedLink extends LinkType {
  href: string
  method: 'GET'
  type: ActionLinkTypes.BOOKING_RETRIEVE
}

export interface EPSThreeDomainSecureParams {
  encodedChallengeConfig?: string
  encodedInitConfig?: string
  paymentSessionId?: string
}

export interface StripeThreeDomainSecureParams {
  clientSecret?: string
}

export interface BookingStatusThreeDomainSecureRequiredResponse<
  T extends EPSThreeDomainSecureParams | StripeThreeDomainSecureParams = Record<
    string,
    string
  >
> {
  id: string
  status: BookingStatus.THREE_DOMAIN_SECURE_REQUIRED
  links: [ThreeDomainSecureRequiredLink]
  threeDomainSecure: {
    enrolled: boolean
    params: T
    serverCode?: string
  }
}

export interface BookingStatusConfirmedResponse {
  id: string
  status: BookingStatus.CONFIRMED
  links: [ConfirmedLink]
}

export interface BookingStatusErrorResponse {
  id: string
  status: BookingStatus.ERROR
  links: []
  error: ErrorResponse
}
