import {configureStore} from '@reduxjs/toolkit'
import {enhancer} from 'addon-redux'
import appMiddlewareList from 'middlewares'
// Reducers
import {reducer as book} from 'modules/book'
import {reducer as common} from 'modules/common'
import {reducer as localization} from 'modules/localization'
import meta, {augmentMetaState} from 'modules/meta'
import {reducer as overlay} from 'modules/overlay'
import {reducer as paymentOptions} from 'modules/paymentOptions'
import psd2Auth from 'modules/psd2Auth/slice'
import {reducer as raaOffers} from 'modules/raaOffers'
import rooms from 'modules/rooms/slice'
import {combineReducers, Store} from 'redux'
import {localizationApi} from 'services/localizationApi'

import authMiddlewares from '@daedalus/core/src/auth/modules/middleware'
import authentication from '@daedalus/core/src/auth/modules/slice'
import {bookApi} from '@daedalus/core/src/booking/services/bookApi'
import {bookingRetrieveApi} from '@daedalus/core/src/booking/services/bookingRetrieveApi'
import {paymentOptionsApi} from '@daedalus/core/src/booking/services/paymentOptionsApi'
import experiments from '@daedalus/core/src/experiments/modules/slice'
import {offerCheckApi} from '@daedalus/core/src/offer/services/offerCheckApi'
import {offerConfigurationApi} from '@daedalus/core/src/offer/services/offerConfigurationApi'
import {searchApi} from '@daedalus/core/src/sapi/services/searchApi'
import dealFreeze from '@daedalus/shared/src/dealFreeze/modules/slice'
import {dealFreezeApi} from '@daedalus/shared/src/dealFreeze/services/dealFreezeApi'

const middlewareList = [...appMiddlewareList, ...authMiddlewares]

const createReducer = () =>
  combineReducers({
    meta,
    book,
    localization,
    overlay,
    common,
    rooms,
    raaOffers,
    authentication,
    dealFreeze,
    psd2Auth,
    experiments,
    paymentOptions,
    [bookApi.reducerPath]: bookApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [offerCheckApi.reducerPath]: offerCheckApi.reducer,
    [dealFreezeApi.reducerPath]: dealFreezeApi.reducer,
    [localizationApi.reducerPath]: localizationApi.reducer,
    [paymentOptionsApi.reducerPath]: paymentOptionsApi.reducer,
    [offerConfigurationApi.reducerPath]: offerConfigurationApi.reducer,
    [bookingRetrieveApi.reducerPath]: bookingRetrieveApi.reducer
  })

export const initializeStore = (initialState: Partial<RootState> = {}): Store =>
  configureStore({
    reducer: createReducer(),
    preloadedState: {
      ...initialState,
      meta: augmentMetaState(initialState)
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        // We wanted to share RTQ API between packages and pass
        // response transform functions as action parameter
        serializableCheck: false,
        immutableCheck: false
      }).concat(...middlewareList),
    enhancers: [enhancer]
  })

type CreateReducer = ReturnType<typeof createReducer>
export type RootState = ReturnType<CreateReducer>

export const store = initializeStore()
