import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import Mousetrap from 'mousetrap'

import {getCookie, setCookie} from '@daedalus/core/src/utils/cookies'

import {makeCookieConfiguration} from '../../auth/business/persistData'
import {trackEvent} from '../modules/actions'
import {Action, Category, Entity, Team} from '../types/Events'

const ScreenshotCountCookie = {
  name: 'screenshotCount',
  expires: 1 // 1 day
}

export const getScreenshotCount = () => {
  const screenshotCountCookie = getCookie(ScreenshotCountCookie.name)
  return screenshotCountCookie ? Number(screenshotCountCookie) : 0
}

const persistScreenshotCount = () => {
  const cookieConfig = makeCookieConfiguration()
  const screenshotCountConfig = {
    ...cookieConfig,
    expires: ScreenshotCountCookie.expires
  }

  const screenshotCount = getScreenshotCount()
  const incrementedCountString = (screenshotCount + 1).toString()

  setCookie(
    ScreenshotCountCookie.name,
    incrementedCountString,
    screenshotCountConfig
  )
}

/**
 *
 * Dispatches a redux action whenever an user tries to take a screenshot.
 * @param action - the action to be dispatched
 * @returns boolean what is it returning
 * @example
 * ```typescript
 * useScreenshotTracking(action)
 * ```
 */
const useScreenshotTracking = (action?: () => void) => {
  const dispatch = useDispatch()
  const shortcuts = [
    'printscreen',
    'ctrl+printscreen',
    'shift+printscreen',
    'alt+printscreen',
    'meta+printscreen',
    'meta+shift+s'
  ]

  const macShortcuts = ['command+shift']

  const trackKeyboard = (shortcuts: string[], type: 'keyup' | 'keydown') => {
    Mousetrap.bind(
      shortcuts,
      () => {
        persistScreenshotCount()

        dispatch(
          trackEvent({
            category: Category.User,
            entity: Entity.Screenshot,
            action: Action.Requested,
            team: Team.Retention
          })
        )

        if (action) {
          dispatch(action())
        }
      },
      type
    )
  }

  Mousetrap.addKeycodes({
    44: 'printscreen'
  })

  useEffect(() => {
    trackKeyboard(shortcuts, 'keyup')
    trackKeyboard(macShortcuts, 'keydown')
    return () => {
      Mousetrap.unbind(shortcuts, 'keyup')
      Mousetrap.unbind(macShortcuts, 'keydown')
    }
  })
}

export default useScreenshotTracking
