import * as React from 'react'
import {useIntl} from 'react-intl'

import {Icon} from '@daedalus/atlas/Icon'
import {InputButton} from '@daedalus/atlas/InputButton'
import {DatePickerType} from '@daedalus/core/src/datePicker/types'
import {dateStringToMiddayDate} from '@daedalus/core/src/utils/date'

import {datePickerMessages} from '../DatePicker/helpers/datePickerMessages'

interface Props {
  /** Date to be displayed in the FakeInput */
  value: string
  /** The type of datepicker */
  type: DatePickerType
  /** The click handler */
  onClick: () => void
}

export const FakeDatePickerInput = ({type, value, onClick}: Props) => {
  const {formatDate, formatMessage} = useIntl()

  const isCheckIn = type === DatePickerType.CheckIn

  const date = value
    ? formatDate(dateStringToMiddayDate(value), {
        day: '2-digit',
        month: 'short',
        weekday: 'short'
      })
    : ''

  return (
    <InputButton
      dataId={`DatePicker--${type}`}
      id={`DatePicker--${type}`}
      size="lg"
      icon={
        <Icon
          colorPath="content.neutral.c600"
          name={isCheckIn ? 'CalendarStart' : 'CalendarEnd'}
        />
      }
      onClick={onClick}
      placeholder={
        isCheckIn
          ? formatMessage(datePickerMessages.checkInDate)
          : formatMessage(datePickerMessages.checkOutDate)
      }
    >
      {date}
    </InputButton>
  )
}
