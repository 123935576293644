import React from 'react'
import {useIntl} from 'react-intl'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {toggle} from 'opticks'
import {SizesType} from 'types/Sizes'

import {
  formatAbbreviatedPriceValue,
  formatPriceValue
} from '@daedalus/core/src/price/business/formatPrice'
import {RoundingStrategy} from '@daedalus/core/src/utils/number'
import {Lock} from '@daedalus/shared/src/privateDeal/Lock'

import {mq} from '../../../utils/breakpoints'
import {Icon} from '../Icon'
import {TextVariant} from '../Text'

export type PriceSize = SizesType
export type LockSizeType = Extract<SizesType, 'sm' | 'md'>

interface Props {
  /** Pass through classname to allow styles overrides */
  className?: string
  /** The currency code for the price */
  currency?: string
  /** Whether the price should be styled as an anchor price */
  isAnchor?: boolean
  /** Whether the price is hidden as it is locked */
  isLocked?: boolean
  /** Whether the price is a private deal */
  isPrivate?: boolean
  /** Whether the price is an app lock deal */
  isAppLocked?: boolean
  /** Price maximum decimal places */
  maximumFractionDigits?: number
  /** Price minimum decimal places */
  minimumFractionDigits?: number
  /** Whether the price should not be styled */
  noStyle?: boolean
  /** How the price should be rounded  */
  roundingStrategy?: RoundingStrategy
  /** Whether the locked icon can be shown. It is only shown when isPrivate is also true. */
  showLockIcon?: boolean
  /** Whether the app locked icon can be shown. It is only shown when isAppLocked is also true. */
  showAppIcon?: boolean
  /** Price size */
  size?: PriceSize
  /** The value to be displayed as the price */
  value: number
  /** Whether the price should be displayed in offer */
  shouldAlignOffer?: boolean
  /** Lock size */
  lockSize?: LockSizeType
  /** When true it will abbreviate the prices to max 6 characters, e.g. €12.3k or €123M. Note that k and M are not translated */
  isAbbreviated?: boolean
  /** When true it will abbreviate the currency symbol if it exists in ShortCurrencySymbols, otherwise it will not show the currency symbol. This only works if isAbbreviated is also true */
  useShortCurrency?: boolean
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
}

const sizeVariantMap: Record<PriceSize, TextVariant> = {
  xs: 'bodyXS',
  sm: 'titleS',
  md: 'titleM',
  lg: 'titleL',
  xl: 'titleXL',
  xxl: 'title2XL'
}

const StandardPriceElement = styled.div<{
  isPrivate?: boolean
  isAppLocked?: boolean
  customSize?: PriceSize
}>(
  ({isPrivate, isAppLocked, customSize = 'lg', theme}) => css`
    display: inline-block;
    text-transform: uppercase;
    color: ${isPrivate || isAppLocked
      ? theme.colors.price.special
      : theme.colors.price.default};
    ${theme.typography.text[sizeVariantMap[customSize]]};
  `
)

const AnchorPriceElement = styled.div(({theme}) =>
  toggle(
    'b4f4f3cc-website-restyle-v3',
    css`
      color: ${theme.colors.price.anchor};
      text-decoration: line-through;
      ${theme.typography.text.bodyXS}
    `,
    css`
      color: ${theme.colors.content.neutral.c800};
      text-decoration: line-through;
      ${theme.typography.text.bodyXS}
      ${mq.desktopXs(css`
        ${theme.typography.text.bodyS}
      `)}
    `
  )
)

const Currency = styled.div(
  () => css`
    text-transform: uppercase;
  `
)

const PriceWrapper = styled.div<{
  shouldAlignOffer?: boolean
}>(
  ({theme, shouldAlignOffer}) => css`
    display: flex;
    flex-direction: 'row';
    align-items: center;
    gap: ${theme.layout.spacing.s200}px;
    ${!!shouldAlignOffer && `margin-bottom: -${theme.layout.spacing.s100}px`}
  `
)

export const Price = ({
  className,
  isAnchor = false,
  value,
  currency,
  noStyle = false,
  minimumFractionDigits = 0,
  maximumFractionDigits = 0,
  isPrivate = false,
  roundingStrategy,
  isLocked = false,
  isAppLocked = false,
  showLockIcon = true,
  showAppIcon = false,
  size = 'lg',
  shouldAlignOffer = false,
  lockSize = 'md',
  isAbbreviated = false,
  useShortCurrency = false,
  dataId = ''
}: Props) => {
  const {locale} = useIntl()
  const formattedNumber = isAbbreviated
    ? formatAbbreviatedPriceValue({
        locale,
        roundingStrategy,
        value,
        currency,
        useShortCurrency
      })
    : formatPriceValue({
        locale,
        roundingStrategy,
        value,
        currency,
        minimumFractionDigits,
        maximumFractionDigits
      })

  if (isAnchor) {
    return (
      <AnchorPriceElement className={className} data-id={dataId}>
        {formattedNumber}
      </AnchorPriceElement>
    )
  }

  return (
    <PriceWrapper
      className={className}
      shouldAlignOffer={shouldAlignOffer}
      data-id={dataId}
    >
      {isPrivate && value && showLockIcon && !isAppLocked && (
        <Lock isLocked={isLocked} size={lockSize === 'sm' ? 12 : 16} />
      )}
      {isAppLocked && value && showAppIcon && (
        <Icon name="Iphone" size="sm" colorPath="content.special.c500" />
      )}
      {noStyle ? (
        <Currency>{formattedNumber}</Currency>
      ) : (
        <StandardPriceElement
          isPrivate={isPrivate}
          isAppLocked={isAppLocked}
          customSize={size}
        >
          {formattedNumber}
        </StandardPriceElement>
      )}
    </PriceWrapper>
  )
}
