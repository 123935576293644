import {cookieSecuritySettings} from '../cookies/cookieSecuritySettings'
import {getDomainName} from '../url'

const maxAge = 365 * 2 // 2 years
const name = 'ip'

const config = {
  name,
  options: {
    maxAge,
    domain: getDomainName(window.location.hostname),
    ...cookieSecuritySettings(true)
  }
}

export default config
