import React, {ReactElement, ReactNode} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {ThemeType} from 'types/Theme'

import {Button} from '../Button'
import {Grid} from '../helpers/Grid'
import {Icon} from '../Icon'
import {ShapedBackground} from './ShapedBackground'

export type AlertVariant = keyof ThemeType['colors']['alert']
export type IconAlignment = 'flex-start' | 'center'

interface Props {
  /** An icon placed before the alert content. It can be any type of React Element */
  icon?: ReactElement
  /** The content of the Alert, a React component or pure text */
  children: ReactNode
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Pass through alignIcon to allow vertically center aligned icon for example */
  alignIcon?: IconAlignment
  /** Title string of the Alert */
  title?: string | ReactNode
  /** Passing this callback will show the close button.
   * Pressing the button will cause the callback execution */
  onClose?: () => void
  /** Color schemes are based on pre-defined themes */
  variant?: AlertVariant
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
}

export const AlertWrapper = styled('div')(({theme}) => {
  return css`
    border-radius: ${theme.layout.radius.md}px;
    position: relative;
    overflow: hidden;
    z-index: 0;
    padding: ${theme.layout.spacing.s500}px ${theme.layout.spacing.s400}px;
  `
})

export const ContentWrapper = styled('div')<{
  variant: AlertVariant
}>(({theme, variant}) => {
  const styleProps = theme.colors.alert[variant]

  return css`
    color: ${styleProps.text};
    ${theme.typography.text.bodyS};
    padding: 0 ${theme.layout.spacing.s400}px;
  `
})

export const Title = styled('div')<{variant: AlertVariant}>(({
  theme,
  variant
}) => {
  const styleProps = theme.colors.alert[variant]

  return css`
    color: ${styleProps.title};
    ${theme.typography.text.labelM};
  `
})

const getDescriptionPadding = (
  theme: ThemeType,
  hasTitle: boolean,
  hasIcon: boolean
) => {
  if (hasTitle && hasIcon) {
    return `${theme.layout.spacing.s600 + theme.layout.spacing.s200}px`
  } else if (hasTitle && !hasIcon) {
    return `${theme.layout.spacing.s400}px`
  }
  return '0px'
}

export const Description = styled('div')<{
  variant: AlertVariant
  hasTitle: boolean
  hasIcon: boolean
}>(({theme, variant, hasTitle, hasIcon}) => {
  const styleProps = theme.colors.alert[variant]
  return css`
    padding: 0px ${getDescriptionPadding(theme, hasTitle, hasIcon)};
    color: ${styleProps.text};
    ${theme.typography.text.bodyS};
  `
})

export const IconGrid = styled(Grid)<{
  alignIcon: IconAlignment
  hasTitle: boolean
}>(
  ({theme, alignIcon, hasTitle}) => css`
    align-self: ${alignIcon};
    display: flex;
    align-items: center;
    min-height: ${hasTitle
      ? theme.fonts.lineHeight.md
      : theme.fonts.lineHeight.sm};
  `
)

export const IconWrapper = styled('div')<{variant: AlertVariant}>(({
  theme,
  variant
}) => {
  const styleProps = theme.colors.alert[variant]

  return css`
    color: ${styleProps.icon};
  `
})

export const ButtonWrapper = styled('div')(
  ({theme}) => css`
    min-width: ${theme.layout.spacing.s600}px;
    color: ${theme.colors.alert.default.icon};
    margin-top: -${theme.layout.spacing.s400}px;
    margin-bottom: -${theme.layout.spacing.s400}px;
    margin-right: -${theme.layout.spacing.s400}px;
  `
)

export const Alert = ({
  icon,
  onClose,
  children,
  className,
  alignIcon = 'flex-start',
  variant = 'default',
  title,
  dataId = ''
}: Props) => {
  const handleCloseButtonClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    onClose?.()
  }

  return (
    <AlertWrapper className={className} data-id={dataId}>
      <ShapedBackground variant={variant} />
      <Grid container spacing="s000" wrap="nowrap">
        {icon && (
          <IconGrid
            mobileSm="fit"
            alignIcon={alignIcon}
            hasTitle={Boolean(title)}
          >
            <IconWrapper variant={variant}>{icon}</IconWrapper>
          </IconGrid>
        )}
        <Grid mobileSm="fill">
          <ContentWrapper variant={variant}>
            {Boolean(title) && (
              <Title data-id="AlertTitle" variant={variant}>
                {title}
              </Title>
            )}
            {!title && (
              <Description
                variant={variant}
                hasTitle={Boolean(title)}
                hasIcon={Boolean(icon)}
              >
                {children}
              </Description>
            )}
          </ContentWrapper>
        </Grid>

        {Boolean(onClose) && (
          <ButtonWrapper>
            <Button
              hasTouchArea
              variant="transparent"
              size="md"
              iconStart={
                <Icon
                  name="Close"
                  size="sm"
                  colorPath={`alert.${variant}.icon`}
                />
              }
              onClick={handleCloseButtonClick}
            />
          </ButtonWrapper>
        )}
      </Grid>
      {Boolean(title) && (
        <Description
          variant={variant}
          hasTitle={Boolean(title)}
          hasIcon={Boolean(icon)}
        >
          {children}
        </Description>
      )}
    </AlertWrapper>
  )
}
