import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Text} from '@daedalus/atlas/Text'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

import {AuthenticationButtons} from '../../../authentication/AuthenticationButtons'
import {MobileMenuUserInfo} from '../MobileMenuUserInfo'
import {MobileMenuUserSectionSkeleton} from './Skeleton'

type PropsType = {
  /** Whether the user is authenticated */
  isAuthenticated: boolean
  /** Whether user data is still loading */
  isLoading: boolean
  /** Callback for Login click */
  onLogInClick: () => void
  /** Callback for Signup click */
  onSignUpClick: () => void
  onShowAccountOverlay: () => void
}

const UserSectionWrapper = styled.div<{isAuthenticated: boolean}>(
  ({theme, isAuthenticated}) => css`
    ${isAuthenticated
      ? `padding: ${theme.layout.spacing.s600}px ${theme.layout.spacing.s500}px;`
      : `padding: ${theme.layout.spacing.s600}px ${theme.layout.spacing.s500}px
    ${theme.layout.spacing.s700}px;`}
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${isAuthenticated ? null : 'align-items: center;'};
    flex-shrink: 0;
  `
)

const TextWrapper = styled.div(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.layout.spacing.s600}px;
    > * + * {
      margin-top: ${theme.layout.spacing.s300}px;
    }
  `
)

export const MobileMenuUserSection = ({
  isAuthenticated,
  isLoading,
  onLogInClick,
  onSignUpClick,
  onShowAccountOverlay
}: PropsType) => {
  if (isLoading) {
    return <MobileMenuUserSectionSkeleton />
  }

  return (
    <UserSectionWrapper isAuthenticated={isAuthenticated}>
      {isAuthenticated ? (
        <MobileMenuUserInfo onShowAccountOverlay={onShowAccountOverlay} />
      ) : (
        <>
          <TextWrapper>
            <Text variant="titleL">
              <FormattedMessageWrapper
                id="userWidget.login"
                defaultMessage="Sign in or create account"
              />
            </Text>
            <Text variant="bodyM">
              <FormattedMessageWrapper
                id="userWidget.memberDisclaimer"
                defaultMessage="Find lower prices and member-only deals"
              />
            </Text>
          </TextWrapper>
          <AuthenticationButtons
            size="lg"
            onSignUpClick={onSignUpClick}
            onLogInClick={onLogInClick}
          />
        </>
      )}
    </UserSectionWrapper>
  )
}
