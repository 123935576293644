import React, {ReactNode} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {ThemeType} from 'types/Theme'

interface Props {
  /** Space between elements. This value will be used to set the margin-top of all child elements, apart from the first element **/
  space: keyof ThemeType['layout']['spacing']
  /** Content to be displayed inside the Stack */
  children: ReactNode
  /** Accepts a custom class name, also adds the ability to customize the component using styled(Stack) */
  className?: string
}

// Simple reusable stack https://every-layout.dev/layouts/stack/
interface StackStyleProps {
  space: keyof ThemeType['layout']['spacing']
}

const StackStyle = styled.div(
  ({theme, space}: StackStyleProps & {theme: ThemeType}) => css`
    & > * + * {
      margin-top: ${theme.layout.spacing[space]}px;
    }
  `
)

export const Stack = ({space, children, className}: Props) => (
  <StackStyle className={className} space={space}>
    {children}
  </StackStyle>
)
