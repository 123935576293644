import {v4 as uuid} from 'uuid'

import {getCookie, setCookie} from '@daedalus/core/src/utils/cookies'
import {
  parseQueryString,
  removeURLParameter
} from '@daedalus/core/src/utils/url'

import cookieConfig from './cookieConfig'

export const removeAnonymousIdFromUrl = () => {
  const newUrl = removeURLParameter(location.href, 'anonymousId')
  history.replaceState(null, '', newUrl)
}

const getFromQueryString = () => {
  const {anonymousId} = parseQueryString(location?.search || '')
  return anonymousId as string
}

const getFromCookie = () => getCookie(cookieConfig.name)

const generateAnonymousId = () => uuid()

export const getAnonymousId = () => getFromQueryString() || getFromCookie()

/**
 * AnonymousId logic steps:
 *
 * 1 - Query string parameter: check if anonymousId parameter is present in the URL and use its value.
 * 2 - Cookie: check if anonymousId is already stored in a cookie and use its value.
 * 3 - Generate: use UUID to generate a new anonymousId.
 **/
export const getOrGenerateAnonymousId = (): string =>
  getAnonymousId() || generateAnonymousId()

export const persistAnonymousId = (anonymousId: string) => {
  const {secure, sameSite, domain, maxAge: expires} = cookieConfig.options

  setCookie(cookieConfig.name, anonymousId, {
    expires,
    secure,
    sameSite,
    domain
  })
}
