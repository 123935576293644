import {Amenities, Offer as SapiOffer} from '@findhotel/sapi'

import {MealsType} from '../../offer/types/AugmentedOffer'
import {PostBookingBoardType} from '../../room/types/room'

export const MEAL_AMENITIES = new Set([
  'allInclusive',
  'breakfast',
  'partialBreakfast',
  'lunch',
  'dinner',
  'fullBoard',
  'halfBoard',
  'meals'
])

/**
 * A reusable function that returns all the available meals for any list of strigns
 * @param list - any list of strings
 * @returns Amenities[]
 */
export const getMealsAmenitiesFromList = (list: string[]): Amenities[] =>
  (list?.filter(amenity => MEAL_AMENITIES.has(amenity)) ?? []) as Amenities[]

/**
 * A factory function that returns all the available meals for each offer
 * @param offer - SapiOffer
 * @returns Amenities[]
 */
export const getMealsAmenities = (
  offer: Pick<SapiOffer, 'package'>
): Amenities[] => {
  return getMealsAmenitiesFromList(offer?.package?.amenities)
}

/**
 * A factory function that return ['allInclusive'] when the 'allInclusive' is presented inside meals list
 * @param meals - offer meals
 * @returns Amenities[]
 */
export const getPrioritizedMeals = (
  meals: Amenities[] | null | undefined
): Amenities[] => {
  if (meals?.some(meal => meal === 'allInclusive')) return ['allInclusive']
  return meals ?? []
}

const SERVICES_MEAL_MAPPING: Record<MealsType, PostBookingBoardType> = {
  breakfast: PostBookingBoardType.Breakfast,
  lunch: PostBookingBoardType.Lunch,
  dinner: PostBookingBoardType.Dinner,
  allInclusive: PostBookingBoardType.AllInclusive,
  fullBoard: PostBookingBoardType.FullBoard,
  halfBoard: PostBookingBoardType.HalfBoard,
  meals: PostBookingBoardType.FullBoard // this is depracted by RAA but we keep it for the sake of data integrity
}

/**
 * A factory function that returns boardType from a list of services
 * @param services - offer services
 * @returns boardType
 */
type getRoomOfferBoardTypeFn = (services: string[]) => PostBookingBoardType

export const getRoomOfferBoardType: getRoomOfferBoardTypeFn = services => {
  const mealServices = services.reduce((meals, service) => {
    if (service in SERVICES_MEAL_MAPPING)
      return [...meals, SERVICES_MEAL_MAPPING[service as MealsType]]
    return meals
  }, [] as PostBookingBoardType[])
  return mealServices[0] || PostBookingBoardType.RoomOnly
}

export enum ServiceType {
  breakfast,
  lunch,
  dinner,
  allInclusive,
  fullBoard,
  halfBoard,
  noService
}

const SERVICE_TYPE_MAPPING: Record<PostBookingBoardType, ServiceType> = {
  [PostBookingBoardType.Breakfast]: ServiceType.breakfast,
  [PostBookingBoardType.Lunch]: ServiceType.lunch,
  [PostBookingBoardType.Dinner]: ServiceType.dinner,
  [PostBookingBoardType.AllInclusive]: ServiceType.allInclusive,
  [PostBookingBoardType.FullBoard]: ServiceType.fullBoard,
  [PostBookingBoardType.HalfBoard]: ServiceType.halfBoard,
  [PostBookingBoardType.RoomOnly]: ServiceType.noService
}

export const getOfferService = (services: string[]) => {
  if (!services || services.length === 0) return ServiceType.noService

  const boardType = getRoomOfferBoardType(services)
  return SERVICE_TYPE_MAPPING[boardType]
}

export const getPostBookingOfferServices = (
  boardType: PostBookingBoardType
) => {
  return SERVICE_TYPE_MAPPING[boardType]
}
