import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {ThemePropType, ThemeType} from 'types/Theme'

import {InputButtonStyledContainer} from '@daedalus/atlas/InputButton'

export const DIVIDER_WIDTH = 1

export const IconWrapper = styled('div')``

export const formButtonFocusHoverStyles = ({
  theme,
  isFirstInput
}: {
  theme: ThemeType
  isFirstInput: boolean
}) => css`
  position: absolute;
  top: 0;
  left: -${DIVIDER_WIDTH}px;
  width: calc(100% + ${DIVIDER_WIDTH * 2}px);
  padding: 0 ${DIVIDER_WIDTH * 2}px !important;
  background: white;

  ${InputButtonStyledContainer} {
    background: initial;
  }

  ${IconWrapper} {
    padding-left: ${DIVIDER_WIDTH}px;
  }

  ${isFirstInput && `border-top-left-radius: ${theme.layout.radius.rounded}px;`}
  ${isFirstInput &&
  `border-bottom-left-radius: ${theme.layout.radius.rounded}px;`}
`

const formButtonHoverStyles = ({theme}: ThemePropType) => css`
  ${InputButtonStyledContainer} {
    border-color: ${theme.colors.border.neutral.c500};
  }
`

export const formButtonFocusBorderStyles = ({theme}: ThemePropType) => css`
  border-color: ${theme.colors.border.interactive.c500} !important;
  box-shadow: 0px 0px 0px 1px ${theme.colors.border.interactive.c500} !important;
`

export const formButtonFocusIconStyles = ({theme}: ThemePropType) => css`
  color: ${theme.colors.content.interactive.c500} !important;
`

const formButtonFocusStyles = ({theme}: ThemePropType) => css`
  ${InputButtonStyledContainer} {
    ${formButtonFocusBorderStyles({theme})}
  }
  ${IconWrapper} {
    ${formButtonFocusIconStyles({theme})}
  }
`

export const formButtonBaseStyles = ({
  theme,
  isFirstInput
}: {
  theme: ThemeType
  isFirstInput: boolean
}) => css`
  padding: 0 ${DIVIDER_WIDTH}px;

  ${InputButtonStyledContainer} {
    border: 1px solid ${theme.colors.border.neutral.c000};
  }
  ${IconWrapper} {
    color: ${theme.colors.content.neutral.c600};
  }

  :focus,
  :hover {
    ${formButtonFocusHoverStyles({theme, isFirstInput})}
  }
  :hover {
    ${formButtonHoverStyles({theme})}
  }
  :focus {
    ${formButtonFocusStyles({theme})}
  }
`

export const formButtonErrorStyles = ({
  theme,
  isFirstInput
}: {
  theme: ThemeType
  isFirstInput?: boolean
}) => css`
  padding: 0 ${DIVIDER_WIDTH}px;

  ${InputButtonStyledContainer} {
    border-color: ${theme.colors.border.danger.c700};
  }
  ${IconWrapper} {
    color: ${theme.colors.content.danger.c700};
  }
  ${formButtonFocusHoverStyles({theme, isFirstInput: Boolean(isFirstInput)})}

  :hover {
    ${InputButtonStyledContainer} {
      border-color: ${theme.colors.border.danger.c700};
    }
  }
  :focus {
    border-color: ${theme.colors.border.danger.c700};
  }
`

export const formButtonActiveStyles = ({
  theme,
  isFirstInput
}: {
  theme: ThemeType
  isFirstInput: boolean
}) => css`
  ${formButtonFocusHoverStyles({theme, isFirstInput})}
  ${formButtonFocusStyles({theme})}
`
