import React from 'react'

// import {getCurrencyCode} from 'modules/meta/selectors'
import {Avatar, AvatarType} from '@daedalus/atlas/Avatar'
import {getUserName} from '@daedalus/core/src/auth/business/authentication'
import {User} from '@daedalus/core/src/auth/types/Cognito'

import {GemsTextTag} from '../../../privateDeal/gems/GemsTextTag'

type Props = {
  user: User | null
  hasGemsTag?: boolean
}

export const UserAvatar = ({user, hasGemsTag = true}: Props) => {
  const picture = user?.attributes?.picture

  return (
    <Avatar
      picture={picture}
      type={picture ? AvatarType.Picture : AvatarType.Icon}
      name={getUserName(user || null)}
      bottomIcon={hasGemsTag && <GemsTextTag hasBorder />}
      borderColorPath="border.special.c300"
    />
  )
}
