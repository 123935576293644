import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {BookingError} from 'hooks/useBookingError'

import {ContentWrapper} from '@daedalus/atlas/helpers/ContentWrapper'
import {Grid} from '@daedalus/atlas/helpers/Grid'
import {Text} from '@daedalus/atlas/Text'
import {ErrorCategory} from '@daedalus/core/src/booking/types/ErrorCategory'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {getIsPayAtPropertyMoR} from '@daedalus/core/src/offer/business/getIsPayAtPropertyMoR'
import {
  GET_OFFER_CHECK_QUERY_KEY,
  offerCheckApi
} from '@daedalus/core/src/offer/services/offerCheckApi'
import {useScrollTo} from '@daedalus/core/src/utils/dom/hooks/useScrollTo'
import {ErrorBanner} from '@daedalus/shared/src/booking/ErrorBannerRedesigned'

export const getIsGenericFraudDeclined = (error?: BookingError) => {
  return error?.data?.details?.category === ErrorCategory.GenericFraudDeclined
}

const ErrorTitle = ({canPayLater}: {canPayLater: boolean}) => {
  if (canPayLater) {
    return (
      <FormattedMessageWrapper
        id="errors.genericFraudDeclinedTitlePayLater"
        defaultMessage="Failed to add payment method"
      />
    )
  }

  return (
    <FormattedMessageWrapper
      id="errors.paymentDeclined"
      defaultMessage="Payment declined"
    />
  )
}

export const GenericFraudDeclined = React.memo(() => {
  const {ref, scroll} = useScrollTo(500, 100)

  useEffect(() => {
    scroll()
  }, [])

  const {data: offerCheck} = useSelector(
    offerCheckApi.endpoints.getOfferCheck.select(GET_OFFER_CHECK_QUERY_KEY)
  )

  const canPayLater = getIsPayAtPropertyMoR(offerCheck)

  return (
    <ContentWrapper paddingBottom="s600">
      <div ref={ref}>
        <ErrorBanner title={<ErrorTitle canPayLater={Boolean(canPayLater)} />}>
          <Grid container spacing="s000" gap="s400">
            <Text variant="bodyS">
              <FormattedMessageWrapper
                id="errors.genericFraudDeclinedMessageV3"
                defaultMessage="Try a different payment method or contact your bank."
              />
            </Text>
          </Grid>
        </ErrorBanner>
      </div>
    </ContentWrapper>
  )
})

GenericFraudDeclined.displayName = 'GenericFraudDeclined'
