import {Offer} from '@daedalus/core/src/offer/types/offer'
import {UrlCheckoutParamsType} from '@daedalus/core/src/utils/url/types/UrlParams'

export const HISTORY_BACK = 'HISTORY_BACK'
export const REFRESH_PAGE = 'REFRESH_PAGE'
export const REFRESH_PAGE_ONCE = 'REFRESH_PAGE_ONCE'
export const SCROLL_TO = 'SCROLL_TO'
export const SCROLL_TO_WINDOW_TOP = 'SCROLL_TO_WINDOW_TOP'
export const NAVIGATE_TO = 'NAVIGATE_TO'
export const NAVIGATE_TO_OUTSIDE = 'NAVIGATE_TO_OUTSIDE'
export const NAVIGATE_TO_SEARCH = 'NAVIGATE_TO_SEARCH'
export const NAVIGATE_TO_ROOM_SELECTION = 'NAVIGATE_TO_ROOM_SELECTION'
export const NAVIGATE_TO_PAYMENT_PAGE = 'NAVIGATE_TO_PAYMENT_PAGE'
export const NAVIGATE_TO_CONFIRMATION_PAGE = 'NAVIGATE_TO_CONFIRMATION_PAGE'
export const UPDATE_SEARCH_PARAMS = 'UPDATE_SEARCH_PARAMS'
export const CLEAR_REFRESHED_STATUS = 'CLEAR_REFRESHED_STATUS'
export const RSP_LOADED = 'RSP_LOADED'
export const PP_LOADED = 'PP_LOADED'

export type HistoryBackType = {
  type: typeof HISTORY_BACK
}

export type RefreshPageType = {
  type: typeof REFRESH_PAGE
}

export type ScrollIntoViewType = {
  type: typeof SCROLL_TO
}

export const historyBack = () => ({
  type: HISTORY_BACK
})

export const refreshPage = () => ({
  type: REFRESH_PAGE
})

export const refreshPageOnce = () => ({
  type: REFRESH_PAGE_ONCE
})

export const clearRefreshedStatus = () => ({
  type: CLEAR_REFRESHED_STATUS
})

export const scrollTo = (elementRef: string) => ({
  type: SCROLL_TO,
  elementRef
})

export const scrollToWindowTop = () => ({
  type: SCROLL_TO_WINDOW_TOP
})

export interface NavigateTo {
  params: {
    replace?: boolean
    replaceCurrentSearchParams?: boolean
    replaceSearchParams?: boolean
    search?: Record<string, unknown> | null | undefined
    url: string | null | undefined
  }
  type: typeof NAVIGATE_TO
}

export interface NavigateToSearch {
  isAuthenticated?: boolean
  isAudience?: boolean
  isNewTab?: boolean
  checkIn?: string
  checkOut?: string
  rooms?: string
  optimizeRooms?: string
  utm_source?: string
  utm_campaign?: string
  placeID?: string
}

export const navigateTo = (
  url: string | null | undefined,
  search = {},
  replaceSearchParams = false,
  replace = false
): NavigateTo => {
  return {
    type: NAVIGATE_TO,
    params: {
      url,
      search,
      replaceSearchParams,
      replace
    }
  }
}

export type NavigateToOutsideType = {
  params: {
    search?: Record<string, unknown> | null | undefined
    url: string | null | undefined
  }
  type: typeof NAVIGATE_TO_OUTSIDE
}

export const navigateToOutside = (
  url: string,
  search?: Record<string, unknown>
): NavigateToOutsideType => ({
  type: NAVIGATE_TO_OUTSIDE,
  params: {
    url,
    search
  }
})

export const navigateToSearch = ({
  isAuthenticated,
  isAudience,
  isNewTab,
  checkIn,
  checkOut,
  rooms,
  optimizeRooms,
  utm_source,
  utm_campaign,
  placeID
}: NavigateToSearch) => ({
  type: NAVIGATE_TO_SEARCH,
  params: {
    isAuthenticated,
    isAudience,
    isNewTab,
    checkIn,
    checkOut,
    rooms,
    optimizeRooms,
    utm_source,
    utm_campaign,
    placeID
  }
})

export interface NavigateToRoomSelection {
  type: typeof NAVIGATE_TO_ROOM_SELECTION
  params?: Partial<UrlCheckoutParamsType>
}

export const navigateToRoomSelection = (
  params = {}
): NavigateToRoomSelection => ({
  type: NAVIGATE_TO_ROOM_SELECTION,
  params
})

export interface UpdateSearchParams {
  params: {
    replaceSearchParams?: boolean
    search?: Record<string, unknown> | null | undefined
  }
  type: typeof UPDATE_SEARCH_PARAMS
}

export const navigateToConfirmationPage = (
  bookingParams?: Record<string, unknown> | null | undefined
) => {
  return {
    type: NAVIGATE_TO_CONFIRMATION_PAGE,
    params: {
      bookingParams
    }
  }
}

export interface NavigateToPaymentPageParams {
  isAuthenticated?: boolean
  offerParams: {
    offer?: Offer
    roomId: string
  }
  urlParams: UrlCheckoutParamsType
  selectedRoomPosition?: number
  selectedOfferPosition?: number
}

export interface NavigateToPaymentPage {
  navigateParams: NavigateToPaymentPageParams
  type: typeof NAVIGATE_TO_PAYMENT_PAGE
  replaceHistory: boolean
}

export const navigateToPaymentPage = (
  navigateParams: NavigateToPaymentPageParams,
  replaceHistory = false
): NavigateToPaymentPage => ({
  type: NAVIGATE_TO_PAYMENT_PAGE,
  navigateParams,
  replaceHistory
})

export const updateSearchParams = (
  search = {},
  replaceSearchParams = false
): UpdateSearchParams => {
  return {
    type: UPDATE_SEARCH_PARAMS,
    params: {
      search,
      replaceSearchParams
    }
  }
}

export interface SetRspLoaded {
  data?: any
  type: typeof RSP_LOADED
}

export const setRspLoaded = (data?: any): SetRspLoaded => ({
  type: RSP_LOADED,
  data
})

export interface SetPpLoaded {
  data?: any
  type: typeof PP_LOADED
}

export const setPpLoaded = (data?: any): SetPpLoaded => ({
  type: PP_LOADED,
  data
})

export type ModuleActionType =
  | HistoryBackType
  | RefreshPageType
  | ScrollIntoViewType
  | NavigateToOutsideType
  | NavigateToPaymentPage
  | NavigateToRoomSelection
  | UpdateSearchParams
