import {RaaOffersResponse} from 'services/raaOffers'
import {RaaSearchParamsType} from 'types/Search'

export const RAA_OFFERS_FETCH = 'RAA_OFFERS_FETCH'
export const RAA_OFFERS_RECEIVED = 'RAA_OFFERS_RECEIVED'
export const RAA_OFFERS_DONE = 'RAA_OFFERS_DONE'
export const RAA_OFFERS_ERROR = 'RAA_OFFERS_ERROR'
export const RAA_OFFERS_FATAL_ERROR = 'RAA_OFFERS_FATAL_ERROR'

export type FetchRaaOffersType = {
  params: RaaSearchParamsType
  type: typeof RAA_OFFERS_FETCH
}

export type FetchRaaOffersPendingType = {
  type: typeof RAA_OFFERS_RECEIVED
}

export interface FetchRaaOffersReceived {
  type: typeof RAA_OFFERS_RECEIVED
  data: RaaOffersResponse
  params: RaaSearchParamsType
}

type FetchRaaOffersErrorType = {
  data: Error
  type: typeof RAA_OFFERS_ERROR
}

export type FetchRaaOffersDoneType = {
  type: typeof RAA_OFFERS_DONE
}

export type ActionType =
  | FetchRaaOffersType
  | FetchRaaOffersReceived
  | FetchRaaOffersPendingType
  | FetchRaaOffersErrorType
  | FetchRaaOffersDoneType

const fetchRaaOffers = (params: RaaSearchParamsType): FetchRaaOffersType => ({
  type: RAA_OFFERS_FETCH,
  params
})

export const fetchRaaOffersError = (error: Error) =>
  ({
    type: RAA_OFFERS_ERROR,
    data: error
  } as FetchRaaOffersErrorType)

export const fetchRaaOffersDone = (): FetchRaaOffersDoneType => ({
  type: RAA_OFFERS_DONE
})

export const fetchRaaOffersReceived = (
  response: {data: RaaOffersResponse},
  params: RaaSearchParamsType
): FetchRaaOffersReceived => ({
  type: RAA_OFFERS_RECEIVED,
  data: response.data,
  params
})

export default fetchRaaOffers
