import '../utils/scripts/smartscript'

import {Page} from '../analytics/types/Events'
import {generateSearchParams} from './generateSearchParams'
import {BannerName, DeepLinkSearchParams} from './types'

/**
 * Get the the Universal Deep Link to the mobile app
 * For more information, refer to the documentation at https://innovativetravel.atlassian.net/l/cp/zh7ALw62
 * @param page - The current page.
 * @param bannerName - Name to easily identify the source of installs and clicks. Please determine the value by discussing with the analytics team.
 * @param deepLinkSearchParams - The search parameters.
 */
export const generateAppDownloadUrl = (
  page: Page,
  bannerName: BannerName,
  deepLinkSearchParams?: DeepLinkSearchParams
) => {
  const {anonymousId, searchId, isReturningUser, ...customSearchParams} =
    deepLinkSearchParams || {}
  const oneLinkURL = 'https://app.vio.com/v0HW'
  const mediaSource = {
    keys: ['utm_source'],
    defaultValue: 'Direct'
  }
  const campaign = {keys: ['utm_campaign']}
  const channel = {keys: ['utm_medium']}
  const afSub1 = {keys: ['label']}
  const afSub2 = {
    keys: [],
    defaultValue: [isReturningUser]
  }
  const afSub3 = {
    keys: [],
    defaultValue: [`${anonymousId}_${searchId}`]
  }
  const afSub5 = {
    keys: [],
    defaultValue: [bannerName]
  }
  const deepLinkValue = {
    keys: [],
    defaultValue: [page]
  }

  const oneLinkUrl = window.AF_SMART_SCRIPT.generateOneLinkURL({
    oneLinkURL,
    afParameters: {
      mediaSource,
      deepLinkValue,
      campaign,
      channel,
      afSub1,
      afSub2,
      afSub3,
      afSub5,
      ...(customSearchParams && {
        afCustom: generateSearchParams(customSearchParams)
      })
    }
  }).clickURL

  const AF_PRIORITY_MODIFIER = 'af_pmod_priority=equal'

  /**
   * The final AppsFlyer link should not be bigger than 2k characters, be mindful about how many parameters you are adding.
   * If there are more than 2k characters, the link may be cut off and the landing link could loose some data.
   */
  return `${oneLinkUrl}&${AF_PRIORITY_MODIFIER}`
}
