import React from 'react'
import {css, ThemeProvider} from '@emotion/react'
import styled from '@emotion/styled'

import GridGlobalStyles from '@daedalus/atlas/foundation/GridGlobalStyles'
import {CssBaseline} from '@daedalus/atlas/helpers/CssBaseline'
import {getTheme, ThemeNames} from '@daedalus/atlas/themes'

import {CrashErrorMessageContainer} from '../CrashErrorMessage'

const HeightWrapper = styled('div')(
  () => css`
    min-height: 600px;
    height: 1px;
  `
)

export const ErrorBoundaryFallback: React.FC = () => {
  const theme = getTheme(ThemeNames.vio)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GridGlobalStyles />
      <HeightWrapper>
        <CrashErrorMessageContainer />
      </HeightWrapper>
    </ThemeProvider>
  )
}
