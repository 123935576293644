import {isNil} from 'ramda'

import {logAndCaptureErrorMessage} from '../../utils/logging/errorHandlers'
import loadScript from '../../utils/network/loadScript'
import {FullStory} from '../types/FullStory'

declare let FS: FullStory
declare global {
  interface Window {
    _fs_ready: () => void
    FS: typeof FS
  }
}

/**
 * Return FullStory object
 * Note: The FullStory is loaded through GTM and not all users sessions are been recorded in order
 * to not exceed the quota. If you want to make sure a users session will be recorded for a specific flow
 * you need to call loadFullStory()
 * @returns FS
 */
export const getFullStoryObject = (): FullStory | undefined =>
  typeof FS === 'undefined' ? undefined : FS

/**
 * Callback function that's executed when FullStory is ready
 * @returns FS
 */
export const isFullStoryReady = async (timeout?: number): Promise<unknown> =>
  Promise.race([
    new Promise(resolve => {
      window._fs_ready = () => resolve('')
    }),
    new Promise((resolve, reject) => {
      if (timeout) setTimeout(reject, timeout)
    })
  ])

/**
 * Force FullStory to be loaded and record a user session
 *
 * @returns FS
 */
export const loadFullStory = async (
  publicUrl: string,
  timeout?: number
): Promise<FullStory | undefined> => {
  if (isNil(window?.FS)) {
    try {
      await loadScript(`${publicUrl}js/fullstory/index.js`)
      await isFullStoryReady(timeout)
    } catch {
      logAndCaptureErrorMessage('Not able to load FullStory')
    }
  }

  return getFullStoryObject()
}
