import {LinkType} from '../../booking/types/Link'
import {ActionLink, ActionLinkTypes} from '../types/actionLink'

export const getLinkByType = (
  links: LinkType[] | ActionLink[] | undefined,
  type: string | ActionLinkTypes
) => {
  if (!links) return undefined
  return links ? links?.find(link => link.type === type)?.href : undefined
}
