import React, {useEffect} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {isNil} from 'ramda'
import {ThemeType} from 'types/Theme'

import {ImageProvider} from '@daedalus/core/src/staticContent/types/ImageProvider'
import {getDevicePixelRatio} from '@daedalus/core/src/utils/window/getDevicePixelRatio'

import useIsImageloaded from '../../../hooks/useIsImageloaded'
import {SkeletonLoader} from '../SkeletonLoader/SkeletonLoader'

export type SizeType =
  | 'lightbox'
  | 'extraLarge'
  | 'large'
  | 'main'
  | 'medium'
  | 'small'
  | 'mobileLarge'
  | 'mobileMedium'
  | 'mobileSmall'
  | 'gridPrimary'
  | 'gridSecondary'

export const SIZES: Record<SizeType, [number, number]> = {
  lightbox: [840, 635],
  extraLarge: [740, 393],
  large: [615, 340],
  main: [292, 284],
  medium: [59, 59],
  small: [38, 38],
  mobileLarge: [620, 176],
  mobileMedium: [620, 152],
  mobileSmall: [126, 152],
  gridPrimary: [456, 330],
  gridSecondary: [228, 162]
}

type BackgroundSize = 'cover' | 'contain'

export type BorderRadius = keyof ThemeType['layout']['radius']

interface PropsType {
  /** Apply specified border-radius styling. */
  borderRadius?: BorderRadius
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Whether to display the image as the full width of its parent */
  fullWidth?: boolean
  /** Whether to show a skeleton element while image is loading */
  hasSkeletonLoader?: boolean
  /** Override the height of the image. Does not work when "isResponsive" is "true" or when "size" is "main" */
  height?: number
  /** Provider used to manage resizing and serving of the images */
  imageProvider?: ImageProvider
  /** Whether to display the image as the full width and height of its parent */
  isResponsive?: boolean
  /** Size of the image */
  size?: SizeType
  /** URL of the image */
  url: string
  /** URL of the image place holder image incase of broken images */
  placeHolderImage?: string
  /** background-size css property of a container having image as background */
  backgroundSize?: BackgroundSize
  id?: string
  /**
   * Callback function to handle image loading errors.
   * This function will be invoked when the image fails to load.
   */
  onImageError?: () => void
}

export const SkeletonElement = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
`

interface StyleProps {
  borderRadius?: BorderRadius
  fullWidth?: boolean
  imageHeight: number
  imageWidth: number
  shouldBeResponsive: boolean
  imageSrc: string
  isGridPrimaryImage: boolean
  backgroundSize: BackgroundSize
}

const ImageElement = styled.div(
  ({
    theme,
    borderRadius,
    fullWidth,
    imageHeight,
    imageWidth,
    shouldBeResponsive,
    imageSrc,
    isGridPrimaryImage,
    backgroundSize = 'cover'
  }: StyleProps & {theme: ThemeType}) => css`
    position: relative;
    background-repeat: no-repeat;
    width: ${shouldBeResponsive || fullWidth ? '100%' : `${imageWidth}px`};
    height: ${shouldBeResponsive ? '100%' : `${imageHeight}px`};
    background-position: center;
    background-image: url(${imageSrc});
    background-size: ${backgroundSize};
    ${!!borderRadius &&
    `border-radius: ${theme.layout.radius[borderRadius]}px;`}

    ${isGridPrimaryImage &&
    css`
      grid-row: 1 / 3;
      grid-column: 1 / 3;
    `}
  `
)

const defaultImageProvider: ImageProvider = (url: string) => url

export const Image = ({
  borderRadius,
  imageProvider = defaultImageProvider,
  size = 'main',
  url,
  isResponsive = false,
  fullWidth,
  hasSkeletonLoader = false,
  height,
  backgroundSize = 'cover',
  placeHolderImage,
  className,
  id,
  onImageError
}: PropsType) => {
  const dpr = getDevicePixelRatio(window)
  const extraProcessingParams = {
    dpr
  }
  const imageSrc = imageProvider(url, SIZES[size], extraProcessingParams)

  const {isLoaded, isErrored} = useIsImageloaded(imageSrc)

  useEffect(() => {
    if (isErrored && onImageError) {
      onImageError()
    }
  }, [isErrored, onImageError])

  const shouldBeResponsive = isResponsive || size === 'main'
  const isGridPrimaryImage = size === 'gridPrimary'

  // eslint-disable-next-line prefer-const
  let [imageWidth, imageHeight] = SIZES[size]
  imageHeight = isNil(height) ? imageHeight : height

  return (
    <ImageElement
      borderRadius={borderRadius}
      fullWidth={fullWidth}
      imageHeight={imageHeight}
      imageWidth={imageWidth}
      shouldBeResponsive={shouldBeResponsive}
      imageSrc={isErrored && placeHolderImage ? placeHolderImage : imageSrc}
      isGridPrimaryImage={isGridPrimaryImage}
      backgroundSize={backgroundSize}
      className={className}
      id={id}
    >
      {hasSkeletonLoader && !isLoaded && !isErrored && (
        <SkeletonElement>
          <SkeletonLoader
            height={shouldBeResponsive ? undefined : imageHeight}
          />
        </SkeletonElement>
      )}
    </ImageElement>
  )
}
