import {error, toCommonErrorPayloadFormat} from 'modules/common/actions/error'
import {anyPass, propEq} from 'ramda'

// $flowTodo
export const isServerError = anyPass([
  propEq('status', 500),
  propEq('status', 0)
])

const fetchCycle = (
  dispatch,
  request,
  pending,
  done,
  moduleError,
  fatalError
) => {
  dispatch(pending())
  request
    .then(result => {
      dispatch(done(result))
    })
    .catch(error_ => {
      // If the catch, catches other errors than request ones, just passes along up the chain
      if (!error_.request) throw error_

      if (isServerError(error_.request) && fatalError) {
        // Axios decorates the error with a config proprty which may contain raw form data.
        // Remove config and pass the error to fatalError handler so that it can be logged with DD and Sentry
        delete error_.config
        return dispatch(fatalError(error_))
      }

      const action = moduleError(error_)

      dispatch(action)
      dispatch(error(toCommonErrorPayloadFormat(action, error_.response)))
    })
}

export default fetchCycle
