import React from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {format, parseISO} from 'date-fns'

import ContentWrapper from '@daedalus/atlas/helpers/ContentWrapper'
import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'

import {getError, getFreezeSelectedContext} from '../../modules/selector'
import {ErrorButton} from './ErrorButton'
import {useGetErrorMessageAndCase} from './useGetErrorMessageAndCase'
import {useTrackApiError} from './useTrackApiError'

const formatDate = (date: string) => {
  return format(parseISO(date), 'MMM dd')
}

const Container = styled(ContentWrapper)(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.layout.spacing.s200}px;
  `
)

const MessageWrapper = styled.div(
  ({theme}) => css`
    display: flex;
    align-items: center;
    gap: ${theme.layout.spacing.s200}px;
  `
)

const StyledIcon = styled(Icon)(
  ({theme}) => css`
    color: ${theme.colors.content.danger.c500};
  `
)

interface Props {
  hasErrorCTA: boolean
}
export const ErrorMessage: React.FC<Props> = ({hasErrorCTA}) => {
  const {formatMessage} = useIntl()

  const {searchDetail} = useSelector(getFreezeSelectedContext)
  const {checkIn, checkOut} = searchDetail || {}

  const error = useSelector(getError)
  const {errored} = error || {}

  const {title, message, ctaText, icon, errorCase} = useGetErrorMessageAndCase({
    error
  })

  // when errorMessage component is shown with all the required data, error should be tracked
  useTrackApiError({error, errorCase})

  if (!errored) return null

  const checkInDate = checkIn && formatDate(checkIn)
  const checkOutDate = checkOut && formatDate(checkOut)
  const formattedTitle = formatMessage(title)
  const formattedMessage = formatMessage(message, {
    checkIn: checkInDate,
    checkOut: checkOutDate
  })

  return (
    <Container padding="s500">
      <MessageWrapper>
        <StyledIcon name={icon} />
        <Text variant="titleM">{formattedTitle}</Text>
      </MessageWrapper>
      <Text>{formattedMessage}</Text>
      {hasErrorCTA && (
        <ContentWrapper paddingTop="s300">
          <ErrorButton ctaText={ctaText} errorCase={errorCase} />
        </ContentWrapper>
      )}
    </Container>
  )
}
