import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import getRoomConfig from 'components/data/URLParams/mappers/getRoomConfig'
import useParams from 'components/data/URLParams/useParams'
import {closeSearchBox} from 'modules/common/actions/searchBox'
import {getIsSearchBoxVisible} from 'modules/common/selectors'
import {
  changeDateSelectionVisibility,
  changeGuestSelectionVisibility
} from 'modules/overlay/actions'

import {SearchFormVertical} from '@findhotel/shared/src/search/SearchFormVertical'

const openDatePicker = changeDateSelectionVisibility(true)
const openGuestPicker = changeGuestSelectionVisibility(true)

const FakeSearchFormWithStore = () => {
  const dispatch = useDispatch()
  const urlParams = useParams()
  const {checkIn, checkOut} = urlParams
  const roomsConfig = getRoomConfig(urlParams)
  const {numberOfAdults, numberOfChildren, numberOfRooms} = roomsConfig
  const isSearchBoxVisible = useSelector(getIsSearchBoxVisible)

  const handleDatePickerClick = useCallback(
    type => {
      dispatch(openDatePicker({type}))
    },
    [dispatch]
  )

  const handleGuestPickerClick = useCallback(() => {
    dispatch(openGuestPicker())
  }, [dispatch])

  const handleClose = useCallback(() => {
    dispatch(closeSearchBox())
  }, [dispatch])

  if (!isSearchBoxVisible) return null
  return (
    <SearchFormVertical
      checkIn={checkIn}
      checkOut={checkOut}
      numberOfAdults={numberOfAdults}
      numberOfChildren={numberOfChildren}
      numberOfRooms={numberOfRooms}
      onDatePickerClick={handleDatePickerClick}
      onGuestPickerClick={handleGuestPickerClick}
      onSubmit={handleClose}
    />
  )
}

export default FakeSearchFormWithStore
