import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {ButtonStyledContainer} from '../Button'
import {InputButtonStyledContainer} from '../InputButton'

interface Props {
  /** Pass through classname to allow styles overrides */
  className?: string
  /** If the buttons are stacked vertically  */
  isVertical?: boolean
  /** The list of Buttons or InputButtons */
  children?: React.ReactNode
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
}

const ButtonGroupStyled = styled('div')(
  ({isVertical}: {isVertical: boolean}) => css`
    display: flex;
    > button {
      position: relative;
    }
    > button:hover {
      z-index: 1;
    }
    > button:active {
      z-index: 1;
    }
    > button:focus {
      z-index: 2;
    }
    > button ${InputButtonStyledContainer}, > button ${ButtonStyledContainer} {
      box-shadow: none;
    }
    ${isVertical
      ? css`
          flex-direction: column;
          align-items: flex-start;
          > button:not(:last-child)
            ${InputButtonStyledContainer},
            > button:not(:last-child)
            ${ButtonStyledContainer} {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
          }
          > button:not(:first-child)
            ${InputButtonStyledContainer},
            > button:not(:first-child)
            ${ButtonStyledContainer} {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
          > button:not(:first-child) {
            margin-top: -1px;
          }
        `
      : css`
          flex-direction: row;
          > button:not(:last-child)
            ${InputButtonStyledContainer},
            > button:not(:last-child)
            ${ButtonStyledContainer} {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
          > button:not(:first-child)
            ${InputButtonStyledContainer},
            > button:not(:first-child)
            ${ButtonStyledContainer} {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
          > button:not(:first-child) {
            margin-left: -1px;
          }
        `}
  `
)

export const ButtonGroup = ({
  isVertical = false,
  children,
  className,
  dataId
}: Props) => {
  return (
    <ButtonGroupStyled
      data-id={dataId}
      isVertical={isVertical}
      className={className}
    >
      {children}
    </ButtonGroupStyled>
  )
}
