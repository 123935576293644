export enum ErrorTypes {
  BookingAlreadyCancelled = 'booking_already_cancelled',
  BookingNotFound = 'booking_not_found',
  BookingUnauthorized = 'booking_unauthorized',
  HotelNotFound = 'hotel_not_found',
  InputValidation = 'input_validation',
  LinkNotFound = 'link_not_found',
  Payment = 'payment',
  Provider = 'provider',
  ProviderNotFound = 'provider_not_found',
  Data = 'data',
  Fatal = 'fatal',
  PCIProxy = 'pci_proxy',
  OfferExpired = 'offer_expired',
  ProviderBookingCreation = 'provider_booking_creation',
  ProviderPreBook = 'provider_pre_book'
}
