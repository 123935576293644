import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import ContentWrapper from '@daedalus/atlas/helpers/ContentWrapper'
import {SkeletonLoader} from '@daedalus/atlas/SkeletonLoader'

const HeaderWrapper = styled.div<{align: 'center' | 'left'}>(
  ({align}) => css`
    display: flex;
    flex-direction: column;
    align-items: ${align === 'center' ? 'center' : 'flex-start'};
    justify-content: center;
  `
)

const HotelCardOutline = styled.div(
  ({theme}) => css`
    border: 1px solid ${theme.colors.border.neutral.c100};
    border-radius: ${theme.layout.radius.lg}px;
    margin-top: ${theme.layout.spacing.s500}px;
  `
)

const HotelTopSection = styled.div(
  ({theme}) => css`
    padding: ${theme.layout.spacing.s500}px;
  `
)

const HotelDetailsSection = styled.div(
  ({theme}) => css`
    display: flex;
    flex-direction: row;

    & > div:first-of-type {
      margin-right: ${theme.layout.spacing.s400}px;
    }
  `
)

const ImageSkeleton = styled.div(
  ({theme}) => css`
    border-radius: ${theme.layout.radius.lg}px;
    overflow: hidden;
  `
)

const FreezeExpireAndPrice = styled.div(
  ({theme}) => css`
    display: flex;
    flex-direction: row;
    border-top: 1px solid ${theme.colors.border.neutral.c100};

    & > div {
      flex: 1;
      padding: ${theme.layout.spacing.s500}px;
      &:last-of-type {
        border-left: 1px solid ${theme.colors.border.neutral.c100};
        direction: rtl;
      }
    }
  `
)

const PayNowSection = styled.div(
  ({theme}) => css`
    padding: ${theme.layout.spacing.s500}px;
    border-top: 1px solid ${theme.colors.border.neutral.c100};

    & > div {
      display: flex;
      justify-content: space-between;
    }
  `
)
const HotelCardSkeleton = () => (
  <HotelCardOutline>
    <HotelTopSection>
      <HotelDetailsSection>
        <ImageSkeleton>
          <SkeletonLoader width={70} height={70} />
        </ImageSkeleton>
        <div>
          <SkeletonLoader width={68} height={12} />
          <SkeletonLoader width={178} height={24} marginTop={4} />
          <SkeletonLoader width={68} height={20} marginTop={4} />
        </div>
      </HotelDetailsSection>
      <SkeletonLoader
        width={188}
        height={24}
        marginTop={24}
        marginBottom={14}
      />
    </HotelTopSection>
    <FreezeExpireAndPrice>
      <div>
        <SkeletonLoader width={92} height={22} />
        <SkeletonLoader width={48} height={24} />
      </div>
      <div>
        <SkeletonLoader width={132} height={22} />
        <SkeletonLoader width={48} height={24} />
      </div>
    </FreezeExpireAndPrice>
    <PayNowSection>
      <div>
        <SkeletonLoader width={92} height={24} />
        <SkeletonLoader width={48} height={24} />
      </div>
      <SkeletonLoader width={188} height={22} />
    </PayNowSection>
  </HotelCardOutline>
)

const ButtonWrapper = styled.div(
  ({theme}) => css`
    border-radius: ${theme.layout.radius.md}px;
    overflow: hidden;
    margin-top: ${theme.layout.spacing.s500}px;
  `
)
const ButtonSkeleton = () => (
  <ButtonWrapper>
    <SkeletonLoader width={358} height={48} />
  </ButtonWrapper>
)

interface Props {
  isOverlayContent?: boolean
}
export const SkeletonLoading: React.FC<Props> = ({
  isOverlayContent = false
}) => {
  return (
    <ContentWrapper padding="s500">
      <HeaderWrapper align={isOverlayContent ? 'left' : 'center'}>
        <SkeletonLoader width={260} height={32}></SkeletonLoader>
        <SkeletonLoader width={328} height={24} marginTop={4}></SkeletonLoader>
        <SkeletonLoader width={232} height={24} marginTop={4}></SkeletonLoader>
      </HeaderWrapper>
      <HotelCardSkeleton />
      {!isOverlayContent && <ButtonSkeleton />}
    </ContentWrapper>
  )
}
