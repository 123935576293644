import {equals, head, includes, join, trim} from 'ramda'
import {SearchParamsType} from 'types/Search'

import {SearchOffer} from '../../offer/types/SearchOffer'
import {safelyGetStorageItem} from '../../utils/persistence'
import {UrlParamsType} from '../../utils/url/types/UrlParams'
import {Offer} from '../types/offer'

export const OFFLINE_CUG_TYPE = 'offline'
export const SENSITIVE_CUG_TYPE = 'sensitive'
export const SIGNED_IN_CUG_TYPE = 'signed_in'
export const PLUS_CUG_TYPE = 'prime'
export const FRIENDS_AND_FAMILY_CUG_TYPE = 'fsf'
export const WEB_2_APP_CUG_TYPE = 'web_2_app'
export const SIGN_UP_TOKEN = 'lkjs82'
export const PLUS_OFFERS_TOKEN = '7fe276'
export const GSITE_VALUE_REGEX = /\S*ific\S*/gi

export const APP_META_CUG_TYPE = 'app_meta'
export const APP_META_SIGNED_IN_CUG_TYPE = 'app_meta_signed_in'
export const APP_WEB_CUG_TYPE = 'app_web'

/**
 * @deprecated Do NOT use this cug anywhere else other than mobile app until further notice.
 * More context here: https://viodotcom.slack.com/archives/C05QGFFV8LU/p1705480593139919?thread_ts=1705410238.064569&cid=C05QGFFV8LU
 */
export const MOBILE_APP_CUG_TYPE = 'mobile_app'

export enum UserTier {
  Employee = 'employee',
  Plus = 'plus',
  FriendsAndFamily = 'fsf'
}

export const ENGINEERED_CUG_TAG = 'engineered_plus'
export const PLUS_CUG_TAG = 'plus'

export const PlusTiers = [
  UserTier.Employee,
  UserTier.Plus,
  UserTier.FriendsAndFamily
]

export const isWeekendPromoOffer = (
  offer: Partial<Pick<Offer | SearchOffer, 'metadata'>>
) => {
  return includes(offer?.metadata?.originalAccessTier, [
    OFFLINE_CUG_TYPE,
    SIGNED_IN_CUG_TYPE,
    SENSITIVE_CUG_TYPE,
    PLUS_CUG_TYPE,
    FRIENDS_AND_FAMILY_CUG_TYPE
  ])
}

/**
 * @deprecated
 * Use the `getIsPrivateDeal` selector (Keep all the changes made here in sync with getIsPrivateDeal)
 *
 * Receives a {@link SearchOffer} as parameter and determines if is a Closed User Group (CUG) offer
 *
 * @example
 *
 * ```
 * isPrivateDeal(offer)
 * ```
 *
 * @param offer - The offer to be checked
 * @param shouldSeeOffersUnlocked - Whether the user should see cug offers as unlocked, e.g authenticated, mobile, audience token.
 *
 * @returns Whether offer is Closed User Group (CUG) offer or not
 */
export const isPrivateDeal = (
  offer?: Partial<SearchOffer> | Partial<Offer>,
  shouldSeeOffersUnlocked?: boolean
) => {
  if (!offer) return false

  // If cug property is not present, check if the offer has `originalAccessTier` metadata
  // and mark it as a private deal only for users that should see offer as unlocked users.
  // It will also be marked as private for the new SUSI A/B test
  if (isWeekendPromoOffer(offer)) {
    return shouldSeeOffersUnlocked
  }

  const accessTier =
    (offer as SearchOffer)?.accessTier ?? head((offer as Offer)?.cug || [])

  return includes(accessTier, [
    OFFLINE_CUG_TYPE,
    SIGNED_IN_CUG_TYPE,
    SENSITIVE_CUG_TYPE,
    PLUS_CUG_TYPE,
    FRIENDS_AND_FAMILY_CUG_TYPE
  ])
}

/**
 * @deprecated
 * Use the `getIsLockedDeal` selector
 *
 * Receives an {@link Offer} or a {@link SearchOffer} and a `boolean` as parameters and determines if the offer should appear locked on the website
 *
 * @example
 *
 * ```
 * isLockedDeal(offer, shouldSeeOffersUnlocked)
 * ```
 *
 * @param offer - The offer to be checked
 * @param shouldSeeOffersUnlocked - Whether the user should see locked offers or not
 *
 * @returns Whether the offer should appear locked on the website or not
 */
export const isLockedDeal = (
  offer?: Partial<SearchOffer> | Partial<Offer>,
  shouldSeeOffersUnlocked?: boolean
) => !shouldSeeOffersUnlocked && isPrivateDeal(offer, shouldSeeOffersUnlocked)

/**
 *
 * Checks if the audience token is stored at local storage
 *
 * @returns When the `audienceToken` exists on the local storage
 */
export const hasAudienceTokenInBrowser = (): boolean =>
  Boolean(
    safelyGetStorageItem(localStorage, 'audienceToken', 'audienceToken failed')
  )

/**
 *
 * Receives the URL params as an object. The params may contain a token property. The function determines if the token is equals to the {@link SIGN_UP_TOKEN}
 *
 * @example
 *
 * ```
 * hasAudienceTokenInUrl({ token })
 * ```
 *
 * @param urlParams - An object that may contain a token property
 *
 * @returns Whether the token is equal to the {@link SIGN_UP_TOKEN}
 */
export const hasAudienceTokenInUrl = ({token}: {token?: string}): boolean =>
  equals(token, SIGN_UP_TOKEN)

/**
 *
 * Receives the URL params as an object. The params may contain a token property. The function determines if the token is equals to the {@link PLUS_OFFERS_TOKEN}
 *
 * @example
 *
 * ```
 * hasUnlockPlusTokenInUrl({ token })
 * ```
 *
 * @param urlParams - An object that may contain a token property
 *
 * @returns Whether the token is equal to the {@link PLUS_OFFERS_TOKEN}
 */
export const hasUnlockPlusTokenInUrl = ({token}: {token?: string}): boolean =>
  equals(token, PLUS_OFFERS_TOKEN)

/**
 * Receives the URL params as an object. The params may contain a `gsite` property. The function finds the `gsite` param
 * from anywhere in the params object and returns its value.
 *
 * @example
 *
 * ```
 * getGsiteValueFromUrl(urlParams)
 * ```
 *
 * @param urlParams - URL param object
 */
export const getGsiteValueFromUrl = (
  urlParams: SearchParamsType | UrlParamsType = {}
): string | undefined => {
  /**
   * The following regex matches the `gsite` until an `&` occurs. For example: roomId=123&gsite=testValue&placeId=456
   * It will match `gsite=testValue`, and the return value of the function would be `testValue`
   */
  const gsiteRegex = /gsite=([^&]*)/
  const decodedURLParams =
    urlParams &&
    (Object.fromEntries(
      Object.entries(urlParams).map(([key, value]) => [
        key,
        decodeURIComponent(value)
      ])
    ) as Record<string, string>)
  const url = new URLSearchParams(decodedURLParams).toString()
  return gsiteRegex.exec(decodeURIComponent(url))?.[1]
}

/**
 *
 * Receives the URL params as an object. The function use Regex to find the gsite param anywhere in the URL and
 * determines if the gsite is matches to the {@link GSITE_VALUE_REGEX}
 *
 * @example
 *
 * ```
 * hasExpectedGsiteValueInUrl(urlParam)
 * ```
 *
 * @param urlParams - URL params object
 *
 * @returns Whether the gsite param matches to the {@link GSITE_VALUE_REGEX}
 */
export const hasExpectedGsiteValueInUrl = (
  urlParams: SearchParamsType
): boolean => {
  const gsite = getGsiteValueFromUrl(urlParams)
  return Boolean(gsite) && Boolean(gsite?.match(GSITE_VALUE_REGEX))
}

/**
 * Checks if any offer has private deal
 * @param offers - the {@link SearchOffer} to be checked
 * @param shouldSeeOffersUnlocked - Whether the user has privileges or not
 * @returns whether the offers have or not private deals
 */
export const offersHasPrivateDeal = (
  offers: Pick<SearchOffer, 'accessTier' | 'tags' | 'metadata'>[],
  shouldSeeOffersUnlocked: boolean
) =>
  Boolean(offers?.some(offer => isPrivateDeal(offer, shouldSeeOffersUnlocked)))

/**
 * Get offer's cug and tags metadata as joined string
 *
 * @param offers - the {@link Offer} to be checked
 */
export const getCugAndTagsAsJoinedString = (
  offer: Pick<SearchOffer, 'accessTier' | 'tags'> | Pick<Offer, 'cug' | 'tags'>
) => {
  const sanitizedCug =
    'accessTier' in offer
      ? offer.accessTier && offer.accessTier?.split(',')
      : offer?.cug
  const tags = offer?.tags || []
  return tags.concat(sanitizedCug || []).join('-')
}

/**
 * Get offer's cug as joined string
 *
 * @param offers - the {@link Offer} to be checked
 */
export const getCugAsJoinedString = (
  offer: Pick<SearchOffer, 'accessTier'> | Pick<Offer, 'cug'>
) => {
  const sanitizedCug =
    'accessTier' in offer
      ? offer.accessTier && offer.accessTier?.split(',')
      : offer?.cug

  if (!sanitizedCug) return null

  return sanitizedCug.join('-')
}

/**
 * Get offer's tags as joined string
 *
 * @param offers - the {@link Offer} to be checked
 */
export const getTagsAsJoinedString = (
  offer: Pick<SearchOffer, 'tags'> | Pick<Offer, 'tags'>
) => {
  const tags = offer?.tags

  if (!tags) return null

  return tags.join('-')
}

/**
 * Get offer's originalAccessTier as joined string
 *
 * @param offers - the {@link Offer} to be checked
 */
export const getOriginalAccessTiersAsJoinedString = (
  offer: Pick<SearchOffer, 'metadata'> | Pick<Offer, 'metadata'>
) => offer?.metadata?.originalAccessTier || null

/**
 * Get offer's helper classes
 * e.g
 * fh-offer-tags--signed_in-offline
 * fh-offer-cug--signed_in-offline
 * fh-offer-originalAccessTier--offline
 *
 * @param offers - the {@link Offer} to be checked
 */
export const getOfferHelperClasses = (offer: SearchOffer | Offer) => {
  const helperClass = {
    cug:
      getCugAsJoinedString(offer) &&
      `vio-offer-cug--${getCugAsJoinedString(offer)}`,
    tags:
      getTagsAsJoinedString(offer) &&
      `vio-offer-tags--${getTagsAsJoinedString(offer)}`,
    originalAccessTier:
      getOriginalAccessTiersAsJoinedString(offer) &&
      `vio-offer-original-access-tier--${getOriginalAccessTiersAsJoinedString(
        offer
      )}`
  }

  return trim(
    join(' ', [
      helperClass['cug'],
      helperClass['tags'],
      helperClass['originalAccessTier']
    ])
  )
}
