import {MiddlewareType} from 'middlewares'
import {refreshPage, updateSearchParams} from 'modules/browser/actions'
import {CHANGE_CURRENCY} from 'modules/localization/actions/changeCurrency'
import {CHANGE_LANGUAGE} from 'modules/localization/actions/changeLanguage'
import {CHANGE_PREFERENCES} from 'modules/localization/actions/changePreferences'
import {
  COUNTRY_LIST_FETCH,
  countryListDone,
  countryListError,
  countryListPending
} from 'modules/localization/actions/fetchCountryList'
import fetchCountryList from 'services/localization/getCountryListNames'

const middleware: MiddlewareType = store => next => async action => {
  const {dispatch} = store

  const updateSearchParamsAndRefreshPage = (
    searchParams: Record<string, string>
  ) => {
    dispatch(updateSearchParams(searchParams))
    dispatch(refreshPage())
  }

  next(action)

  switch (action.type) {
    case CHANGE_LANGUAGE:
      const {language} = action
      updateSearchParamsAndRefreshPage({locale: language})
      break

    case CHANGE_CURRENCY:
      const {currency} = action
      updateSearchParamsAndRefreshPage({
        currency,
        price: undefined,
        baseRate: undefined,
        localTax: undefined,
        tax: undefined,
        originalBaseRate: undefined,
        originalTax: undefined,
        originalLocalTax: undefined,
        originalCurrency: undefined
      })
      break

    case CHANGE_PREFERENCES: {
      const {currency, language} = action

      if (currency) {
        const currencySearchParams = {
          currency,
          price: undefined,
          baseRate: undefined,
          localTax: undefined,
          tax: undefined,
          originalBaseRate: undefined,
          originalTax: undefined,
          originalLocalTax: undefined,
          originalCurrency: undefined
        }

        const searchParams = {
          ...currencySearchParams
        }
        updateSearchParamsAndRefreshPage(searchParams)
      } else if (language) {
        const languageSearchParams = {locale: language}
        const searchParams = {
          ...languageSearchParams
        }
        updateSearchParamsAndRefreshPage(searchParams)
      }

      break
    }

    case COUNTRY_LIST_FETCH:
      dispatch(countryListPending())
      return fetchCountryList(action.language)
        .then(data => {
          dispatch(countryListDone(data))
        })
        .catch(error => {
          dispatch(countryListError(error))
        })

    default:
      break
  }
}

export default middleware
