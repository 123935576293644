import React from 'react'

export interface ExternalActions {
  openSearchBox: (page?: string) => {type: string; payload?: unknown} | void
  setIsSearchBoxActive?: (
    isActive: boolean
  ) => {type: string; payload?: unknown} | void
}

export const defaultExternalActions = {
  openSearchBox: () => undefined,
  setIsSearchBoxActive: () => undefined
}
/**
 * ExternalActionsContext is a context specifically designed to handle Redux actions from other packages.
 * Since each package may handle its behavior differently using its own Redux store,
 * this context provides a way to reuse injected actions from each package separately and invoke
 * them in a consistent manner.
 */

export const ExternalActionsContext = React.createContext(
  {} as ExternalActions
) as React.Context<ExternalActions>

export const ExternalActionsProvider = ExternalActionsContext.Provider
