import {defineMessages, MessageDescriptor} from 'react-intl'
import {useSelector} from 'react-redux'

import {iconsList} from '@daedalus/atlas/Icon'

import {getFreezeSelectedContext} from '../../modules/selector'
import {DealFreezeEndpoints} from '../../services/dealFreezeApi'
import {DealFreezeError, ErrorCases, PayloadErrorType} from './types'

const ERROR_TITLES = defineMessages({
  [ErrorCases.SoldOut]: {
    id: 'df.soldOutErrorTitle',
    defaultMessage: 'Sold out on your dates!'
  },
  /**
   * For now, price mismatch error is same as sold out error. In the future, there will be a
   * different design for price mismatch error that does not prevent the user from freezing.
   */
  [ErrorCases.PriceMismatch]: {
    id: 'df.soldOutErrorTitle',
    defaultMessage: 'Sold out on your dates!'
  },
  [ErrorCases.Unavailability]: {
    id: 'df.unavailabilityErrorTitle',
    defaultMessage: 'No availability on your dates!'
  },
  [ErrorCases.LimitReached]: {
    id: 'df.limitReachedErrorTitle',
    defaultMessage: 'You have reached your freeze limit'
  },
  [ErrorCases.UnexpectedError]: {
    id: 'df.unexpectedErrorTitle',
    defaultMessage: 'We are experiencing some issues'
  }
}) as Record<ErrorCases, MessageDescriptor>

const ERROR_MESSAGES = defineMessages({
  [ErrorCases.SoldOut]: {
    id: 'df.soldOutError',
    defaultMessage: 'Sold out {checkIn} - {checkOut}'
  },
  [ErrorCases.PriceMismatch]: {
    id: 'df.soldOutError',
    defaultMessage: 'Sold out {checkIn} - {checkOut}'
  },
  [ErrorCases.Unavailability]: {
    id: 'df.unavailabilityError',
    defaultMessage: 'No availability {checkIn} - {checkOut}'
  },
  [ErrorCases.LimitReached]: {
    id: 'df.limitReachedError',
    defaultMessage: 'You have used all three available freezes.'
  },
  [ErrorCases.UnexpectedError]: {
    id: 'df.unexpectedError',
    defaultMessage:
      'Our servers are currently experiencing difficulties. Please try again later.'
  }
}) as Record<ErrorCases, MessageDescriptor>

const CTA_MESSAGES = defineMessages({
  [ErrorCases.SoldOut]: {
    id: 'df.changeRoomCta',
    defaultMessage: 'Change room'
  },
  [ErrorCases.PriceMismatch]: {
    id: 'df.changeRoomCta',
    defaultMessage: 'Change room'
  },
  [ErrorCases.Unavailability]: {
    id: 'df.unavailabilityCta',
    defaultMessage: 'Change dates'
  },
  [ErrorCases.LimitReached]: {
    id: 'df.goBackCta',
    defaultMessage: 'Go back'
  },
  [ErrorCases.UnexpectedError]: {
    id: 'df.goBackCta',
    defaultMessage: 'Go back'
  }
}) as Record<ErrorCases, MessageDescriptor>

const ERROR_ICONS = {
  [ErrorCases.SoldOut]: 'Forbid',
  [ErrorCases.PriceMismatch]: 'Forbid',
  [ErrorCases.Unavailability]: 'Forbid',
  [ErrorCases.LimitReached]: 'Lock',
  [ErrorCases.UnexpectedError]: 'FaceSad'
} as Record<ErrorCases, keyof typeof iconsList>

interface UseGetErrorMessagesProps {
  error: DealFreezeError
}

const getErrorMessagesByCase = (errorCase: ErrorCases) => {
  return {
    title: ERROR_TITLES[errorCase],
    message: ERROR_MESSAGES[errorCase],
    ctaText: CTA_MESSAGES[errorCase],
    icon: ERROR_ICONS[errorCase],
    errorCase
  }
}

export const useGetErrorMessageAndCase = ({
  error
}: UseGetErrorMessagesProps) => {
  const {rooms, sapiMatchedOffer} = useSelector(getFreezeSelectedContext)
  const allAvailableOffers = rooms?.flatMap(room => room.offers) || []

  const {payloadErrorType, endpoint} = error

  if (
    endpoint === DealFreezeEndpoints.OfferCheck ||
    endpoint === DealFreezeEndpoints.SapiRoom
  ) {
    if (payloadErrorType === PayloadErrorType.PriceMismatch) {
      return getErrorMessagesByCase(ErrorCases.PriceMismatch)
    }

    // user selected offer does not exist in SAPI rooms response, but there are other rooms available
    if (!sapiMatchedOffer && allAvailableOffers.length > 0) {
      return getErrorMessagesByCase(ErrorCases.SoldOut)
    }

    return getErrorMessagesByCase(ErrorCases.Unavailability)
  }

  if (payloadErrorType === PayloadErrorType.UserLimitReached) {
    return getErrorMessagesByCase(ErrorCases.LimitReached)
  }

  return getErrorMessagesByCase(ErrorCases.UnexpectedError)
}
