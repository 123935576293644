import React, {ReactNode, useCallback, useEffect, useState} from 'react'
import {usePopper} from 'react-popper'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {OffsetsFunction} from '@popperjs/core/lib/modifiers/offset'

import {useDeviceLayout} from '../../../context/deviceLayout'
import {Alert, AlertVariant} from '../Alert'

export enum TooltipPlacement {
  Auto = 'auto',
  AutoStart = 'auto-start',
  AutoEnd = 'auto-end',
  Top = 'top',
  TopStart = 'top-start',
  TopEnd = 'top-end',
  Bottom = 'bottom',
  BottomStart = 'bottom-start',
  BottomEnd = 'bottom-end',
  Right = 'right',
  RightStart = 'right-start',
  RightEnd = 'right-end',
  Left = 'left',
  LeftStart = 'left-start',
  LeftEnd = 'left-end'
}

const TOOLTIP_MAX_WIDTH = 448

interface Props {
  /** Open or closed state of the Tooltip */
  isOpen?: boolean
  /** Tooltip title */
  title?: string
  /** Tooltip content */
  content: ReactNode
  /** Tooltip placement relative to trigger component */
  placement?: TooltipPlacement
  /** Trigger component */
  children?: ReactNode
  /** Handler that fires when the tooltip is shown */
  onRender?: () => void
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Whether it should render inline */
  isInline?: boolean
  /** Anchor element to show Tooltip near it */
  anchor?: Element | null
  /** Variant of the inner Alert component */
  variant?: AlertVariant
}

const StyledTooltip = styled('div')(
  ({theme}) =>
    css`
      border-radius: ${theme.layout.radius.lg}px;
      box-shadow: ${theme.shadows.floating};
      max-width: ${TOOLTIP_MAX_WIDTH}px;
      z-index: 1;
    `
)

const StyledTriggerWrapper = styled('div')(
  ({isInline}: {isInline?: boolean}) =>
    css`
      display: ${isInline ? 'inline-flex' : 'flex'};
    `
)

export const StyledArrow = styled('div')<{variant: AlertVariant}>(
  ({theme, variant}) => css`
    width: 8px;
    height: 8px;
    background-color: ${theme.colors.alert[variant].background};
    visibility: hidden;

    &:before {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      background-color: inherit;
      visibility: visible;
      transform: rotate(45deg);
      border-radius: ${theme.layout.radius.xs}px 0;
    }

    [data-popper-placement^='top'] & {
      bottom: -4px;
    }

    [data-popper-placement^='bottom'] & {
      top: -4px;
    }

    [data-popper-placement^='left'] & {
      right: -4px;
      left: auto;
      &:before {
        border-radius: 0 ${theme.layout.radius.xs}px;
      }
      html[dir='rtl'] & {
        left: -4px;
        right: auto;
      }
    }

    [data-popper-placement^='right'] & {
      left: -4px;
      right: auto;
      &:before {
        border-radius: 0 ${theme.layout.radius.xs}px;
      }
      html[dir='rtl'] & {
        right: -4px;
        left: auto;
      }
    }
  `
)

const OFFSET_MAP: Record<TooltipPlacement, [number, number]> = {
  [TooltipPlacement.Auto]: [0, 8],
  [TooltipPlacement.AutoStart]: [-8, 8],
  [TooltipPlacement.AutoEnd]: [8, 8],
  [TooltipPlacement.Top]: [0, 8],
  [TooltipPlacement.TopStart]: [-8, 8],
  [TooltipPlacement.TopEnd]: [8, 8],
  [TooltipPlacement.Bottom]: [0, 8],
  [TooltipPlacement.BottomStart]: [-8, 8],
  [TooltipPlacement.BottomEnd]: [8, 8],
  [TooltipPlacement.Right]: [-8, 0],
  [TooltipPlacement.RightStart]: [-8, 8],
  [TooltipPlacement.RightEnd]: [-8, 8],
  [TooltipPlacement.Left]: [8, 0],
  [TooltipPlacement.LeftStart]: [8, 8],
  [TooltipPlacement.LeftEnd]: [8, 8]
}

export const Tooltip = ({
  children,
  title,
  content,
  placement = TooltipPlacement.Bottom,
  className,
  onRender,
  isInline = true,
  isOpen = false,
  anchor = undefined,
  variant = 'default'
}: Props) => {
  const [referenceElement, setReferenceElement] = useState<Element>()
  const [popperElement, setPopperElement] = useState(null)
  const [arrowElement, setArrowElement] = useState(null)
  const [visible, setVisible] = useState(isOpen)
  const {isMobile} = useDeviceLayout()
  // Needs to be memoized to avoid infinite loop.
  // See: https://popper.js.org/react-popper/v2/faq/#why-i-get-render-loop-whenever-i-put-a-function-inside-the-popper-configuration
  const getOffset = useCallback<OffsetsFunction>(
    ({placement}) => OFFSET_MAP[placement],
    []
  )

  const toggleTooltip = (to: boolean) => () => setVisible(to)

  useEffect(() => {
    setVisible(isOpen)
  }, [isOpen])

  useEffect(() => {
    if (anchor) {
      setReferenceElement(anchor)
    }
  }, [anchor])

  const {styles, attributes} = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'arrow',
        options: {element: arrowElement, padding: 6}
      },
      {
        name: 'offset',
        options: {
          offset: getOffset
        }
      }
    ]
  })

  useEffect(() => {
    if (visible) {
      onRender?.()
    }
  }, [visible, onRender])
  if (children)
    return (
      <>
        <StyledTriggerWrapper
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore-error somehow it works correctly when you pass set ref function instead of ref.
          // And it can't be hidden by @ts-expect-error directive because of typechecks from other packages
          ref={anchor ? undefined : setReferenceElement}
          onMouseEnter={isMobile ? toggleTooltip(false) : toggleTooltip(true)}
          onFocus={toggleTooltip(true)}
          onClick={() => setVisible(!visible)}
          onMouseLeave={toggleTooltip(false)}
          onBlur={toggleTooltip(false)}
          isInline={isInline}
        >
          {children}
        </StyledTriggerWrapper>
        {visible && (
          <StyledTooltip
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore-error somehow it works correctly when you pass set ref function instead of ref.
            // And it can't be hidden by @ts-expect-error directive because of typechecks from other packages
            ref={setPopperElement}
            style={styles.popper}
            className={className}
            {...attributes.popper}
          >
            <Alert
              title={title}
              onClose={isMobile ? toggleTooltip(false) : undefined}
              variant={variant}
            >
              {content}
            </Alert>
            <StyledArrow
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore-error somehow it works correctly when you pass set ref function instead of ref. And it can't be hidden by @ts-expect-error directive because of typechecks from other packages
              ref={setArrowElement}
              variant={variant}
              style={styles.arrow}
            />
          </StyledTooltip>
        )}
      </>
    )
  // If we don't pass the children, we can render the tooltip near the anchor element

  return visible ? (
    <StyledTooltip
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore-error somehow it works correctly when you pass set ref function instead of ref.
      // And it can't be hidden by @ts-expect-error directive because of typechecks from other packages
      ref={setPopperElement}
      style={styles.popper}
      className={className}
      {...attributes.popper}
    >
      <Alert
        title={title}
        onClose={isMobile ? toggleTooltip(false) : undefined}
        variant={variant}
      >
        {content}
      </Alert>
      <StyledArrow
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore-error somehow it works correctly when you pass set ref function instead of ref. And it can't be hidden by @ts-expect-error directive because of typechecks from other packages
        ref={setArrowElement}
        variant={variant}
        style={styles.arrow}
      />
    </StyledTooltip>
  ) : null
}
