import {Offer as SapiOffer} from '@findhotel/sapi'

import {BookingSources} from '../../booking/types/BookingData'
import {OfferTagLabel} from '../types/offer'
import {Offer} from '../types/offer'
import {SearchOffer} from '../types/SearchOffer'
import {isEligibleForChargeLater} from './chargeLater'

const PROVIDER_REDIRECT_ANONYMOUS_ID_PARAM_NAME = 'aid'

/**
 * Receives bookUri and anonymousId and merges them in valid URL string
 * @param anonymousId - string
 * @param bookUri - string
 */
export const augmentBookUriWithAnonymousId = (
  anonymousId: string,
  bookUri: string
) => `${bookUri}&${PROVIDER_REDIRECT_ANONYMOUS_ID_PARAM_NAME}=${anonymousId}`

/**
 * Get a lowercase roomName string.
 * Used together with a `text-transform: capitalize` style to show roomNames capitalized, instead of all caps.
 *
 * @param offer - SearchOffer
 */
export const getOfferRoomName = (offer: SearchOffer): string => {
  const roomName = (offer?.roomName || '').toLowerCase()
  return roomName.charAt(0).toUpperCase() + roomName.slice(1)
}

/**
 * A factory function that determines the different statuses of an offer based on a given set of labels
 * @param offer - SapiOffer
 * @param label - OfferTagLabel
 * @returns boolean
 */
export const hasOfferTag = (
  offer: Pick<SapiOffer, 'tags'> | Pick<Offer, 'tags'>,
  label: OfferTagLabel
): boolean => {
  if (offer?.tags?.includes(label)) return true
  return false
}

/**
 * Factory function that check if the offer has free cancellation
 * @param offer - SapiOffer
 * @returns boolean
 */
export const getHasFreeCancellation = (
  offer: Pick<SapiOffer, 'cancellationPenalties'>
): boolean =>
  offer?.cancellationPenalties?.some(penalty => penalty.amount === 0)

/**
 * Factory function that returns charge type for offer based on charge later eligibility or pay at property
 * @param offer - SapiOffer
 * @returns string
 */
export const getChargeType = (
  offer: Pick<SapiOffer, 'cancellationPenalties' | 'providerCode' | 'package'>
): BookingSources[] => {
  const isChargeLaterEligible = isEligibleForChargeLater(
    offer.providerCode,
    offer.cancellationPenalties
  )
  if (isChargeLaterEligible) return [BookingSources.ChargeLater]
  if (offer.package.canPayLater) return [BookingSources.PayAtProperty]
  return [BookingSources.Default]
}
