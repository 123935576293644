import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {toggle} from 'opticks'
import {SizesType} from 'types/Sizes'
import {ColorPath} from 'types/Theme'

import {Icon} from '../Icon'

export type StarRatingSize = Extract<SizesType, 'sm' | 'xs'>

interface Props {
  /** Pass through className to allow styles overrides */
  className?: string
  /** The number of stars to show */
  rating: number
  /** The size of the stars */
  size?: StarRatingSize
  /** Whether it should render inline StartRating */
  isInline?: boolean
  wrapperId?: string
}

const IconsWrapper = styled.div<{
  isInline: boolean
}>(
  ({theme, isInline}) => css`
    display: ${isInline ? 'inline-flex' : 'flex'};
    gap: ${theme.layout.spacing.s100}px;
  `
)

export const StarRating = ({
  rating,
  size = 'sm',
  isInline = false,
  className,
  wrapperId
}: Props) => {
  return (
    <IconsWrapper
      itemScope
      itemProp="starRating"
      itemType="http://schema.org/Rating"
      className={className}
      isInline={isInline}
      id={wrapperId}
    >
      {Boolean(rating) &&
        new Array(Math.floor(rating))
          .fill('x')
          .map((_, index) => (
            <Icon
              key={`star-${index}-${size}`}
              size={size}
              name="StarSolid"
              colorPath={toggle<ColorPath[]>(
                'b4f4f3cc-website-restyle-v3',
                'starRating.content',
                'content.neutral.c600'
              )}
            />
          ))}
      <meta itemProp="ratingValue" content={String(rating)} />
    </IconsWrapper>
  )
}
