import {useDispatch} from 'react-redux'

import {
  removeCognitoUserTierFromCookie,
  setLastLoginUrl
} from '@daedalus/core/src/auth/business/persistData'
import {logOut} from '@daedalus/core/src/auth/modules/slice'
import {signOutService} from '@daedalus/core/src/auth/services'
import {RNWebViewMessageTypes} from '@daedalus/core/src/native'
import {removeCookie} from '@daedalus/core/src/utils/cookies'
import {cookieSecuritySettings} from '@daedalus/core/src/utils/cookies/cookieSecuritySettings'
import {getDomainName} from '@daedalus/core/src/utils/url'

export const useLogOut = () => {
  const dispatch = useDispatch()

  return async () => {
    setLastLoginUrl(window.location.href)
    removeCognitoUserTierFromCookie()
    removeCookie('isPhoneVerified', {
      path: '/',
      domain: getDomainName(location.hostname),
      ...cookieSecuritySettings(true)
    })

    dispatch(logOut())

    await signOutService()

    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: RNWebViewMessageTypes.SIGN_OUT
      })
    )
  }
}
