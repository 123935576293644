import React, {lazy, useCallback, useState} from 'react'
import {toggle} from 'opticks'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {useDispatchTeamEvent} from '@daedalus/core/src/analytics/hooks/useDispatchTeamEvent'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import availableLanguages from '@daedalus/core/src/localization/services/locale'
import {CurrencyType} from '@daedalus/core/src/localization/types/CurrencyType'

import {LanguageCurrencySelector} from '../../../search/LanguageCurrencySelector'
import {PreferencesLink} from './PreferencesLink'
import {PreferencesFormValues} from './PreferencesPopup'

export type SelectedElement = 'language' | 'currency'

interface ChangedPreferences {
  hasCurrencyChanged: boolean
  hasLanguageChanged: boolean
  currency: string
  language: string
}

interface PreferencesWidgetProps {
  /** Currently selected currency */
  currentCurrency: string
  /** Currently selected language */
  currentLanguage: string
  /** List of currently enabled currencies */
  enabledCurrencies: CurrencyType[]
  /** Should it render the currency */
  hasCurrency?: boolean
  /** Should it render the language */
  hasLanguage?: boolean
  /** Whether the user is authenticated */
  isAuthenticated: boolean
  /** Callback action that should be called when preferences change */
  onPreferencesChange: (changedPreferences: ChangedPreferences) => void
  /** Callback action that should be called when preferences link is clicked */
  onPreferencesLinkClick: () => void
  /** Callback action that should be called when preferences select input is clicked */
  onSelectClick: (selectedElement: SelectedElement) => void
  /** Callback action that should be called when preferences select input is clicked */
  showPriceDisplaySetting?: boolean
  /** Callback action that should be called when preferences select input is clicked */
  showTotalPrices?: boolean
  /** Callback action that should be called when preferences select input is clicked */
  onChangePriceDisplay?: (showTotal: boolean) => void
  updateLocation?: (params: Record<string, unknown>) => void
}

const SapiMagicSortOverlay = lazy(
  () =>
    import(
      /* webpackChunkName: "SapiMagicSortOverlay" */
      /* webpackPrefetch: true */
      '../../../search/SapiMagicSortOverlay'
    )
)

export const PreferencesWidget = ({
  currentCurrency,
  currentLanguage,
  enabledCurrencies,
  hasCurrency = true,
  hasLanguage = true,
  isAuthenticated,
  showPriceDisplaySetting = false,
  showTotalPrices = false,
  onChangePriceDisplay,
  onPreferencesChange,
  onPreferencesLinkClick,
  onSelectClick,
  updateLocation
}: PreferencesWidgetProps) => {
  const dispatchTeamEvent = useDispatchTeamEvent()
  const {isMobile} = useDeviceLayout()

  const [initialSelector, setInitialSelector] = useState<SelectedElement>(
    hasLanguage ? 'language' : 'currency'
  )
  const [IsPreferencePopupOpen, setIsPreferencePopupFormOpen] = useState(false)

  const handlePreferencesChange = useCallback(
    async ({currency, language}: PreferencesFormValues) => {
      const hasCurrencyChanged = currency !== currentCurrency
      const hasLanguageChanged = language !== currentLanguage

      if (hasCurrencyChanged || hasLanguageChanged) {
        onSelectClick(hasCurrencyChanged ? 'currency' : 'language')
        onPreferencesChange({
          hasCurrencyChanged,
          hasLanguageChanged,
          currency,
          language
        })
        if (hasCurrencyChanged) {
          setIsPreferencePopupFormOpen(false)
        }
      } else {
        setIsPreferencePopupFormOpen(false)
      }
    },
    [
      currentCurrency,
      currentLanguage,
      onPreferencesChange,
      onSelectClick,
      isAuthenticated
    ]
  )

  const handlePreferencesLinkClick = useCallback(
    (element: SelectedElement) => {
      setIsPreferencePopupFormOpen(true)
      setInitialSelector(element)
      onPreferencesLinkClick()
    },
    [onPreferencesLinkClick]
  )

  const handleChangeLanguageCurrencyTab = useCallback(
    (selectedElement: SelectedElement) => {
      dispatchTeamEvent(
        trackEvent({
          category: Category.User,
          entity: Entity.LanguageCurrencyPreferencesTab,
          action: Action.Clicked,
          payload: {
            selectedElement
          }
        })
      )
    },
    [dispatchTeamEvent]
  )

  const handleChangePriceDisplay = useCallback(
    (showTotal: boolean) => {
      onChangePriceDisplay?.(showTotal)
      setIsPreferencePopupFormOpen(false)
    },
    [onChangePriceDisplay]
  )

  const showSapiMagicSortOverlay = toggle('sapi4eva-magicsort', false, true)

  const languages = hasLanguage ? availableLanguages : []

  return (
    <>
      {showSapiMagicSortOverlay && (
        <SapiMagicSortOverlay updateLocation={updateLocation!} />
      )}
      <PreferencesLink
        currency={currentCurrency}
        language={currentLanguage}
        hasCurrency={hasCurrency}
        hasLanguage={hasLanguage}
        onClick={handlePreferencesLinkClick}
      />
      <LanguageCurrencySelector
        isMobile={isMobile}
        isOpen={IsPreferencePopupOpen}
        onClose={() => setIsPreferencePopupFormOpen(false)}
        availableLanguages={languages}
        availableCurrencies={enabledCurrencies}
        selectedLanguage={currentLanguage}
        selectedCurrency={currentCurrency}
        initialSelector={initialSelector}
        hasCurrency={hasCurrency}
        onChangeCurrency={currency =>
          handlePreferencesChange({currency, language: currentLanguage})
        }
        onChangeLanguage={language =>
          handlePreferencesChange({currency: currentCurrency, language})
        }
        onChangeActiveTab={handleChangeLanguageCurrencyTab}
        showPriceDisplaySetting={showPriceDisplaySetting}
        showTotalPrices={showTotalPrices}
        onChangePriceDisplay={handleChangePriceDisplay}
      />
    </>
  )
}
