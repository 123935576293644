import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Button} from '../Button'

const HEADER_TOP_HEIGHT = 88
export const COMPACT_HEADER_TOP_HEIGHT = 61

type HeaderElementProps = {
  hasShadow?: boolean
  hasLeftPadding?: boolean
  isCompact?: boolean
}

export const HeaderElement = styled('header')<HeaderElementProps>(
  ({theme, hasShadow}) => css`
    z-index: 4;
    position: relative;
    background-color: ${theme.colors.background.neutral.c000};
    ${hasShadow &&
    `
      box-shadow: ${theme.shadows.fixed};
    `}
  `
)

export const HeaderTopContent = styled('div')<HeaderElementProps>(
  ({theme, hasLeftPadding, isCompact}) => {
    const headerHeight = isCompact
      ? COMPACT_HEADER_TOP_HEIGHT - 1
      : HEADER_TOP_HEIGHT

    return css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: ${headerHeight}px;
      ${hasLeftPadding && `padding-left: ${theme.layout.spacing.s600}px;`}
    `
  }
)

export const HeaderBottomContent = styled('div')(() => css``)

export const CenterComponentWrapper = styled('div')(
  () => css`
    display: flex;
    flex-grow: 1;
  `
)

export const RightComponentWrapper = styled('div')(
  () => css`
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `
)

export const LogoWrapper = styled('div')(
  ({theme}) => css`
    display: flex;
    align-items: center;
    gap: ${theme.layout.spacing.s300}px;
    padding-left: ${theme.layout.spacing.s200}px;
  `
)

export const BackButton = styled(Button)(
  ({theme}) => css`
    color: ${theme.colors.content.neutral.c950};
    /* @mirror */
  `
)
