import React from 'react'
import {useSelector} from 'react-redux'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Text} from '@daedalus/atlas/src/components/designSystem/Text'
import {getBrand} from '@daedalus/core/src/brand/modules/selectors'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

const Link = styled.a(
  ({theme}) => css`
    font-size: ${theme.fonts.size.xs};
    font-weight: normal;
    color: ${theme.colors.content.neutral.c600};
    text-decoration: underline;
    &:visited,
    &:hover,
    &:focus {
      color: ${theme.colors.content.neutral.c800};
    }
  `
)

const AgreementWrapper = styled.div(
  ({theme}) => css`
    margin-top: ${theme.layout.spacing.s500}px;
    text-align: center;
  `
)

export const Agreement = () => {
  const brand = useSelector(getBrand)
  return (
    <AgreementWrapper>
      <Text variant="bodyXS" colorPath="content.neutral.c600">
        <FormattedMessageWrapper
          id="magicLinkModal.agreementMessage"
          defaultMessage="By continuing, you agree with our <TermsOfUse></TermsOfUse> and <PrivacyPolicy></PrivacyPolicy>."
          values={{
            TermsOfUse: () => (
              <Link href={brand?.termsOfUseUrl} target="_blank">
                <FormattedMessageWrapper
                  id="termsOfUse"
                  defaultMessage="Terms of Use"
                />
              </Link>
            ),
            PrivacyPolicy: () => (
              <Link href={brand?.privacyPolicyUrl} target="_blank">
                <FormattedMessageWrapper
                  id="privacyPolicy"
                  defaultMessage="Privacy Policy"
                />
              </Link>
            )
          }}
        />
      </Text>
    </AgreementWrapper>
  )
}
