interface Params {
  hostUrl: string
  bookingId: string
  anonymousId?: string
}
export const getBookingManagementUrl = ({
  hostUrl,
  bookingId,
  anonymousId
}: Params): string => {
  const params = new URLSearchParams()
  params.append('bookingId', bookingId)
  if (anonymousId) params.append('anonymousId', anonymousId)

  return `${hostUrl}/bookings?${params.toString()}`
}
