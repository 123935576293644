import {urlParams} from 'utils/urlParams'

import {
  createDataDogLogger,
  dataDogLogEvent
} from '@daedalus/core/src/analytics/components/DataDogClient'
import {experimentVariationsStore} from '@daedalus/core/src/experiments/components/OpticksProvider'
import {getOrGenerateAnonymousId} from '@daedalus/core/src/utils/anonymousId'

import {getFullStorySessionUrl} from './fullstory'

export type LogLevel = 'debug' | 'info' | 'warn' | 'error'

const {outputToConsole = false} = urlParams()

let loggerInstance

const getBoFHLoggerInstance = () => {
  if (loggerInstance) return loggerInstance

  // eslint-disable-next-line fp/no-mutation
  loggerInstance = createDataDogLogger('bofh')
  return loggerInstance
}

export const logToDataDog = ({
  logName,
  metadata = undefined,
  level = 'info',
  outputToConsoleLocal = false
}: {
  logName: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata?: any
  level?: LogLevel
  outputToConsoleLocal?: boolean
}) => {
  const fsSessionUrl = getFullStorySessionUrl()
  const experiments = experimentVariationsStore.getAll(true)
  const anonymousId = getOrGenerateAnonymousId()
  const augmentedMetadata = {
    ...metadata,
    anonymousId,
    experiments,
    fsSessionUrl,
    request_id: metadata?.request_id
  }

  try {
    const logger = getBoFHLoggerInstance()
    logger.log(`Checkout.${logName}`, augmentedMetadata, level)

    if (outputToConsoleLocal || outputToConsole)
      console.log(`[DataDog] ${logName}`, metadata)
  } catch (error) {
    console.error('Failed to log on Datadog for the BoFH context')
    console.log(error)
  }
}

/**
 * @deprecated use logToDataDog instead and follow the pattern naming when logging
 */
export const logEvent = (
  metric: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata: any = undefined,
  level: 'info' | 'error' | 'debug' | 'warn' = 'info',
  outputToConsoleLocal = false
) => {
  const fsSessionUrl = getFullStorySessionUrl()
  const experiments = experimentVariationsStore.getAll(true)
  const anonymousId = getOrGenerateAnonymousId()
  const augmentedMetadata = {
    ...metadata,
    anonymousId,
    experiments,
    fsSessionUrl,
    request_id: metadata?.request_id
  }

  dataDogLogEvent(metric, augmentedMetadata, level)

  if (outputToConsoleLocal || outputToConsole)
    console.log(`[DataDog] ${metric}`, metadata)
}
