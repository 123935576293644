import React, {ReactNode, RefObject, useEffect, useRef, useState} from 'react'
import Sheet, {SheetRef} from 'react-modal-sheet'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {ThemeType} from 'types/Theme'

import {hexToRGBAString} from '@daedalus/core/src/utils/string'

import {Button} from '../Button'
import {BottomBar} from '../helpers/BottomBar'
import {Flex} from '../helpers/Flex'
import {
  TitleBar,
  TitleBarProps,
  TitleBarVariant,
  TitleBarWrapper
} from '../helpers/TitleBar'
import {Icon} from '../Icon'
import {TRANSITION_DURATION} from '../Overlay'
import useBodyScrollLock from '../Overlay/useBodyScrollLock'
import {Handle, OverlayContent} from './styles'

export const NEAR_FULL_SNAP_POINT = -24
export const TOP_SNAP_POINT = -64
export const BOTTOM_SHEET_HEADER_HEIGHT = 64
export const BOTTOM_SHEET_WITH_DRAG_HEADER_HEIGHT = 75

type SymbolicSnapPoints = 'top' | 'nearFull' | 'full'
type InitialSnapPoint = 'middle' | 'bottom'
type NumericSnapPoints = number

interface SnapPoints {
  top: SymbolicSnapPoints
  // from 0.4 to 0.7
  middle: NumericSnapPoints
  // pixel value
  bottom?: NumericSnapPoints
}

interface Props {
  /** Whether the BottomSheet is open */
  isOpen: boolean
  /** The content of the BottomSheet sub header */
  subHeaderContent?: ReactNode
  /** Append a custom footer to the BottomSheet */
  footerContent?: ReactNode
  /** Use sticky Bottom Bar component */
  bottomBarContent?: ReactNode
  /** The body of the BottomSheet */
  children: ReactNode
  /** The callback for closing the BottomSheet. Optionally pass in the element that was selected to close. */
  onClose?: (e?: React.SyntheticEvent, element?: string) => void
  /** Whether the bottom sheet should match the height of the content or the full height of the viewport */
  hasFixedHeight?: boolean
  /** How long the animation will last in milliseconds */
  transitionDuration?: number
  /** Pass through className to allow styles overrides */
  className?: string
  /** A prop to disable the drag functionality on closing the bottom sheet */
  disableDrag?: boolean
  /** Callback called when opening animation is ended */
  onOpenEnd?: () => void
  /** All the props from the TitleBar can be applied here, check [TitleBar](/?path=/docs/helpers-titlebar--default) docs  */
  titleBarProps: TitleBarProps
  /** Determine the variant for the Header (using TitleBar underneath)  */
  headerVariant?: TitleBarVariant
  /** Skip sheet animations (sheet instantly snaps to desired location)  */
  prefersReducedMotion?: boolean
  /** Define if header should have drag handler */
  withDragHandler?: boolean
  /** Define if the bottom sheet is modal */
  isModal?: boolean
  /** Define Bottom Sheet snap points */
  snapPoints?: SnapPoints
  /** Define the initial snap point */
  initialSnapPoint?: InitialSnapPoint
  /** Returns undefined to snapPoints and ignore viewport size (use it when you have inputs inside a bottomSheet) */
  ignoreSnapPoints?: boolean
}

interface StyleProps {
  snapPoints: SnapPoints
  currentSnapPoint?: number
  withDragHandler?: boolean
}

interface ContentProps {
  children: ReactNode
}

enum State {
  Unmounted = 'Unmounted',
  Hidden = 'Hidden',
  Visible = 'Visible'
}

const MapTopSnapPoints = {
  full: 0,
  nearFull: NEAR_FULL_SNAP_POINT,
  top: TOP_SNAP_POINT
}

const withBorderRadius = ({
  theme,
  currentSnapPoint,
  snapPoints
}: StyleProps & {theme: ThemeType}) => {
  if (currentSnapPoint === 0 && snapPoints.top === 'full') {
    return css`
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      ${TitleBarWrapper} {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    `
  }
  return css`
    border-top-left-radius: ${theme.layout.radius.lg}px !important;
    border-top-right-radius: ${theme.layout.radius.lg}px !important;
    ${TitleBarWrapper} {
      border-top-left-radius: ${theme.layout.radius.lg}px;
      border-top-right-radius: ${theme.layout.radius.lg}px;
    }
  `
}

const withMaxHeight = ({snapPoints}: StyleProps) => {
  if (snapPoints.top === 'full') {
    return css`
      max-height: calc(100% - env(safe-area-inset-top)) !important;
    `
  }
}

export const BottomSheetHeader = styled.div<StyleProps>(
  ({theme, withDragHandler}) => css`
    transition: border-radius 0.3s;
    position: relative;
    z-index: 1;
    ${withDragHandler &&
    css`
      padding-top: ${theme.layout.spacing.s250}px;
    `};
  `,
  withBorderRadius
)

export const BottomSheetHandle = styled(Handle)``

const BottomSheetContainer = styled(Sheet.Container)<StyleProps>(
  () =>
    css`
      transition: border-radius 0.3s;
    `,
  withMaxHeight,
  withBorderRadius
)

export const BottomSheetContentInner = React.forwardRef<
  HTMLDivElement,
  ContentProps
>(({children}, forwardedRef) => {
  const localRef = useRef<HTMLDivElement>()
  const ref = (forwardedRef || localRef) as RefObject<HTMLDivElement> // Ref is optional but required from now on

  useBodyScrollLock({ref})

  return <OverlayContent ref={ref}>{children}</OverlayContent>
})

// eslint-disable-next-line fp/no-mutation
BottomSheetContentInner.displayName = 'BottomSheetContentInner'

const BottomSheetContent = styled.div(
  ({theme}) => css`
    padding: ${theme.layout.spacing.s300}px ${theme.layout.spacing.s500}px
      ${theme.layout.spacing.s500}px ${theme.layout.spacing.s500}px;
  `
)

const FooterWrapper = styled.div`
  position: sticky;
  bottom: 0;
`

const SheetBackdrop = styled(Sheet.Backdrop)<{isModal: boolean}>(
  ({theme, isModal}) => css`
    border: none !important;
    pointer-events: ${isModal ? 'auto' : 'none'} !important;
    background-color: ${isModal
      ? hexToRGBAString(theme.colors.overlay, 0.6)
      : 'transparent'} !important;
  `
)

const SubHeaderWrapper = styled.div(
  ({theme}) => css`
    padding-bottom: ${theme.layout.spacing.s400}px;
  `
)

const getCalculatedSnapPoints = (
  snapPoints: SnapPoints,
  initialSnapPoint: InitialSnapPoint
) => {
  const topSnapPoint = MapTopSnapPoints[snapPoints.top] || 1
  let middleSnapPoint = snapPoints?.middle
  if (snapPoints?.middle < 0.4) {
    middleSnapPoint = 0.4
  }

  const calculatedSnapPoints = [topSnapPoint, middleSnapPoint]
  if (snapPoints?.bottom) {
    calculatedSnapPoints.push(snapPoints.bottom)
  }
  calculatedSnapPoints.push(0)
  return {
    calculatedSnapPoints,
    startSnapPoint:
      initialSnapPoint === 'middle' ? 1 : snapPoints?.bottom ? 2 : 1
  }
}

export const BottomSheet = ({
  isOpen,
  subHeaderContent,
  footerContent,
  bottomBarContent,
  children,
  onClose,
  onOpenEnd,
  hasFixedHeight = false,
  transitionDuration = TRANSITION_DURATION,
  className,
  titleBarProps,
  headerVariant,
  isModal = true,
  disableDrag = false,
  prefersReducedMotion = false,
  withDragHandler = false,
  snapPoints = {top: 'top', middle: 0.7},
  initialSnapPoint = 'middle',
  ignoreSnapPoints = false
}: Props) => {
  const {calculatedSnapPoints, startSnapPoint} = getCalculatedSnapPoints(
    snapPoints,
    initialSnapPoint
  )
  const ref = React.useRef<SheetRef>()
  const [state, setState] = useState<State>(State.Unmounted)
  const [currentSnapPoint, setCurrentSnapPoint] = useState(startSnapPoint)

  // react-modal-sheet mounts the portal element **on mount** of the component, this causes issues when combined with Overlays that mount their **on open** (this modal may render behind)
  // By mounting/unmounting on open, modals are rendered in a predictable order (last opened on top) because they create portals on the fly and in-order.
  useEffect(() => {
    // First mount the component
    if (isOpen && state === State.Unmounted) setState(State.Hidden)

    // Then open it in the next render
    if (isOpen && state === State.Hidden) setState(State.Visible)

    // Hide immediately (and unmount via onCloseEnd)
    if (!isOpen && state === State.Visible) setState(State.Hidden)
  }, [isOpen, state])

  const handleCloseAnimation = () => {
    setState(State.Hidden)
    if (onClose) {
      onClose()
    }
  }

  const unMount = () => {
    setState(State.Unmounted)
  }

  const transitionDurationInSeconds = transitionDuration / 1000
  const springConfig = {
    duration: transitionDurationInSeconds,
    bounce: 0,
    ease: 'linear'
  }

  const closeButton = (
    <Button
      dataId="OverlayDrawerCloseButton"
      variant={headerVariant === 'inverse' ? 'transparent' : 'quiet'}
      size="lg"
      iconEnd={
        <Icon
          name="Close"
          colorPath={
            headerVariant === 'inverse'
              ? 'content.neutral.c000'
              : 'content.neutral.c950'
          }
        />
      }
      onClick={handleCloseAnimation}
    />
  )

  if (state === State.Unmounted) return null
  return (
    <Sheet
      className={className}
      ref={ref}
      isOpen={state === State.Visible}
      style={{zIndex: 100}}
      detent={!hasFixedHeight ? 'content-height' : 'full-height'}
      onClose={handleCloseAnimation}
      onCloseEnd={unMount}
      onSnap={setCurrentSnapPoint}
      tweenConfig={springConfig}
      disableDrag={disableDrag}
      onOpenEnd={onOpenEnd}
      prefersReducedMotion={prefersReducedMotion}
      initialSnap={startSnapPoint}
      snapPoints={ignoreSnapPoints ? undefined : calculatedSnapPoints}
    >
      <BottomSheetContainer
        currentSnapPoint={currentSnapPoint}
        snapPoints={snapPoints}
      >
        <Sheet.Header>
          <BottomSheetHeader
            snapPoints={snapPoints}
            currentSnapPoint={currentSnapPoint}
            withDragHandler={withDragHandler}
          >
            {withDragHandler && <BottomSheetHandle />}
            <TitleBar
              bottomContent={
                subHeaderContent && (
                  <SubHeaderWrapper>{subHeaderContent}</SubHeaderWrapper>
                )
              }
              variant={headerVariant}
              hasDivider={headerVariant !== 'floating'}
              {...titleBarProps}
              endContent={
                <Flex.Row justifyContent="center" alignItems="center">
                  {titleBarProps.endContent}
                  {closeButton}
                </Flex.Row>
              }
            />
          </BottomSheetHeader>
        </Sheet.Header>
        <Sheet.Content disableDrag style={{paddingBottom: ref.current?.y}}>
          <BottomSheetContentInner>
            <BottomSheetContent>{children}</BottomSheetContent>
          </BottomSheetContentInner>
          {footerContent && <FooterWrapper>{footerContent}</FooterWrapper>}
          {bottomBarContent && (
            <FooterWrapper>
              <BottomBar hasDivider>{bottomBarContent}</BottomBar>
            </FooterWrapper>
          )}
        </Sheet.Content>
      </BottomSheetContainer>
      <SheetBackdrop
        /* @ts-expect-error: there is no onClick handler in Backdrop types, but we can pass it and it works. */
        onClick={handleCloseAnimation}
        isModal={isModal}
      />
    </Sheet>
  )
}
