import React, {useContext} from 'react'
import {FormattedDate} from 'react-intl'
import {useSelector} from 'react-redux'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {differenceInDays, parseISO} from 'date-fns'

import {Image} from '@daedalus/atlas/Image'
import placeHolderImage from '@daedalus/atlas/images/placeholder-hotel-image.svg'
import {StarRating} from '@daedalus/atlas/StarRating'
import {Text} from '@daedalus/atlas/Text'
import {dateStringToMiddayDate} from '@daedalus/core/src/utils/date'
import {Room, RoomOffer} from '@findhotel/sapi'

import {ImageContext} from '../../context/ImageProviderContext'
import {getError} from '../../modules/selector'
import {ErrorMessage} from '../ErrorMessage'
import {Line} from './Line'
import {PriceSection} from './PriceSection'
import {RoomDetail} from './RoomDetail'

export interface Props {
  imageUrl: string
  rating?: number
  hotelName: string
  checkIn: string
  checkOut: string
  roomsSplit: string
  shouldShowPayNow: boolean
  hasErrorCTA?: boolean
  room?: Room | null
  offer?: RoomOffer | null
}

const MainWrapper = styled.div(
  ({theme}) => css`
    border: 1px solid ${theme.colors.border.neutral.c100};
    border-radius: ${theme.layout.radius.lg}px;
    margin: ${theme.layout.spacing.s500}px auto;
    position: relative;
    background: ${theme.colors.background.neutral.c000};
  `
)

const HotelAndRoomWrapper = styled.div(
  ({theme}) => css`
    padding: ${theme.layout.spacing.s500}px;
    padding-bottom: ${theme.layout.spacing.s400}px;
  `
)

const ImageContainer = styled.div`
  height: 70px;
  min-width: 70px;
`
const NameAndRatingContainer = styled.div(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.layout.spacing.s100}px;
    overflow: hidden;
  `
)

const HotelInfo = styled.div(
  ({theme}) => css`
    display: flex;
    gap: ${theme.layout.spacing.s300}px;
  `
)

const CheckInOutWrapper = styled.div(
  ({theme}) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.layout.spacing.s300}px;
  `
)

const DateText = styled(Text)(
  ({theme}) => css`
    font-weight: ${theme.fonts.weight.lg};
  `
)

const HotelTitle = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ErrorWrapper = styled.div(
  ({theme}) => css`
    border-top: 1px solid ${theme.colors.border.neutral.c100};
  `
)

export const HotelCard: React.FC<Props> = ({
  imageUrl,
  rating,
  hotelName,
  checkIn,
  checkOut,
  roomsSplit,
  room,
  offer,
  shouldShowPayNow,
  hasErrorCTA = true
}) => {
  const imageProvider = useContext(ImageContext)
  const numberOfNights = differenceInDays(parseISO(checkOut), parseISO(checkIn))
  const {errored} = useSelector(getError)
  const shouldShowDetails = Boolean(!errored && room && offer)
  return (
    <MainWrapper>
      <HotelAndRoomWrapper>
        <HotelInfo>
          <ImageContainer>
            <Image
              url={imageUrl}
              imageProvider={imageProvider}
              borderRadius="lg"
              placeHolderImage={placeHolderImage}
            />
          </ImageContainer>
          <NameAndRatingContainer>
            {rating && <StarRating rating={rating} size="xs" />}
            <HotelTitle variant="titleL">{hotelName}</HotelTitle>

            <CheckInOutWrapper>
              <DateText variant="bodyS">
                <FormattedDate
                  value={dateStringToMiddayDate(checkIn)}
                  month="short"
                  day="2-digit"
                  weekday="short"
                />
              </DateText>
              <Line width={38} />
              <DateText variant="bodyS">
                <FormattedDate
                  value={dateStringToMiddayDate(checkOut)}
                  month="short"
                  day="2-digit"
                  weekday="short"
                />
              </DateText>
            </CheckInOutWrapper>
          </NameAndRatingContainer>
        </HotelInfo>

        {shouldShowDetails && (
          <RoomDetail
            room={room}
            offer={offer}
            roomsSplit={roomsSplit}
            numberOfNights={numberOfNights}
          />
        )}
      </HotelAndRoomWrapper>

      {shouldShowDetails && (
        <PriceSection shouldShowPayNow={shouldShowPayNow} />
      )}

      {errored && (
        <ErrorWrapper>
          <ErrorMessage hasErrorCTA={hasErrorCTA} />
        </ErrorWrapper>
      )}
    </MainWrapper>
  )
}
