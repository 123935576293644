import PriceTypes from '@daedalus/core/src/offer/types/PriceTypes'
import {getPriceByType} from '@daedalus/core/src/price/business/price'
import {RoomOffer} from '@findhotel/sapi'

import {DealFreezeEndpoints} from '../../services/dealFreezeApi'
import {OfferCheckResponse} from '../../types/offer'
import {calculatePriceMismatchDifference} from '../../utils/offer'
import {DealFreezeError, PayloadErrorType} from './types'

export interface GetErrorPayloadProps {
  sapiOffer?: RoomOffer | null
  offerCheckResponse?: OfferCheckResponse | null
  error: DealFreezeError
}
export const getErrorPayload = ({
  sapiOffer,
  offerCheckResponse,
  error
}: GetErrorPayloadProps) => {
  const preBookEndpoints = [
    DealFreezeEndpoints.OfferCheck,
    DealFreezeEndpoints.SapiRoom
  ]

  const {endpoint} = error

  const defaultPayload = {
    preBookURL: preBookEndpoints.includes(endpoint as DealFreezeEndpoints)
  }

  if (
    error.payloadErrorType === PayloadErrorType.PriceMismatch &&
    offerCheckResponse?.prices &&
    sapiOffer?.prices
  ) {
    const sapiOfferPrice = sapiOffer.prices
    const offerCheckPrice = offerCheckResponse.prices
    const chargeableSapiPrice = sapiOfferPrice.find(
      price => price.type === PriceTypes.CHARGEABLE_PRICE
    )

    const chargeableOfferCheckPrice = getPriceByType(
      PriceTypes.CHARGEABLE_PRICE,
      offerCheckPrice
    )

    const shouldCalculatePriceMismatch =
      !!chargeableSapiPrice && !!chargeableOfferCheckPrice

    const priceMismatchValue = shouldCalculatePriceMismatch
      ? calculatePriceMismatchDifference(
          chargeableSapiPrice,
          chargeableOfferCheckPrice
        )
      : null

    return {
      ...defaultPayload,
      priceMismatchValue
    }
  }
  return defaultPayload
}
