import React from 'react'
import {FormattedDate} from 'react-intl'

interface Props {
  expiresAt?: string
}
export const FormattedExpirationDate = ({expiresAt}: Props) => {
  if (!expiresAt) return null

  return (
    <FormattedDate value={new Date(expiresAt)} month="long" day="2-digit" />
  )
}
