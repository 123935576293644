import React, {ReactNode} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {SizesType} from 'types/Sizes'

import {Divider} from '../../Divider'

type BottomBarSize = Extract<SizesType, 'md' | 'lg'>
export interface BottomBarProps {
  /** Content of the BottomBar */
  children: ReactNode
  /** Determine if a divider is rendered in the top of the BottomBar */
  hasDivider?: boolean
  /** Pass through className to allow styles overrides */
  className?: string
  /** Whether the BottomBar should be sticky or not */
  sticky?: boolean
  /** The size of the BottomBar (spacings) */
  size?: BottomBarSize
}

const HEADER_HEIGHT = 56

export const BottomBarWrapper = styled.div<{
  sticky?: boolean
  size?: BottomBarSize
}>(
  ({theme, sticky, size}) => css`
    position: relative;
    display: flex;
    flex: auto;
    justify-content: center;
    flex-direction: column;
    background: ${theme.colors.background.neutral.c000};
    padding: ${size === 'lg'
      ? `${theme.layout.spacing.s500}px ${
          theme.layout.spacing.s500 + theme.layout.spacing.s200
        }px`
      : `${theme.layout.spacing.s400}px ${theme.layout.spacing.s500}px`};
    min-height: ${HEADER_HEIGHT}px;

    ${sticky &&
    `
      border-top: solid 1px ${theme.colors.border.neutral.c100};
    `}
  `
)

export const BottomBar = ({
  hasDivider = false,
  children,
  className,
  size = 'md',
  sticky = false
}: BottomBarProps) => {
  return (
    <>
      {hasDivider && <Divider />}
      <BottomBarWrapper className={className} sticky={sticky} size={size}>
        {children}
      </BottomBarWrapper>
    </>
  )
}
