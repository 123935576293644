import * as React from 'react'
import {mergeDeepRight} from 'ramda'
import checkoutTheme from 'themes/findhotel'
import {ThemeType} from 'types/Theme'

import {getTheme} from '@daedalus/atlas/src/themes'

type MergedThemeType = ThemeType & ReturnType<typeof checkoutTheme>

type GetThemePropsType = {
  children(data: MergedThemeType): React.ReactElement
}

const GetTheme = React.memo<GetThemePropsType>(({children}) => {
  const websiteTheme: ThemeType = getTheme()
  return children(mergeDeepRight(websiteTheme, checkoutTheme()))
})

GetTheme.displayName = 'GetTheme'

export default GetTheme
