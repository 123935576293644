import {MiddlewareType} from 'middlewares'
import {LOG_EVENT} from 'modules/analytics/actions/logEvent'
import {TRACK_EVENT_V1} from 'modules/analytics/actions/trackEventV1'
import {
  TRACK_CIO_PAGE_VIEW,
  TRACK_PAGE_VIEW
} from 'modules/analytics/actions/trackPageView'
import {TRACK_RAA_OFFER_CLICKED} from 'modules/analytics/actions/trackRAAOfferClicked'
import {getEventProperties} from 'modules/analytics/trackEventV1Helpers'
import {getAnonymousId, getSearchId} from 'modules/meta/selectors'
import {logEvent, logToDataDog} from 'services/datadogLogger'
import {getFullStoryObject, trackFullStoryEvent} from 'services/fullstory'
import Settings from 'Settings'
import {urlParams} from 'utils/urlParams'

import {TRACK_ERROR_TRIGGERED} from '@daedalus/core/src/analytics/modules/actions'
import {customIdentify} from '@daedalus/core/src/analytics/modules/middleware/customIdentify'
import {customerIo} from '@daedalus/core/src/analytics/services/CustomerIo'
import {
  ExperimentContext,
  TrackEventProperties
} from '@daedalus/core/src/analytics/types/Events'
import AnalyticsUtils from '@daedalus/core/src/analytics/utils'
import {
  getCognitoIsMemberPlus,
  getUser,
  getUserId,
  getUserIdentifiableTraits
} from '@daedalus/core/src/auth/modules/selectors'
import {identifyAuthenticatedUser} from '@daedalus/core/src/auth/modules/slice'
import {getBrand} from '@daedalus/core/src/brand/modules/selectors'
import {getTimezone} from '@daedalus/core/src/utils/date'

const HOTEL_PAGES_NAMESPACE = 'HotelPages'

const trackPageView = (pageName: string, meta: Record<string, unknown>) => {
  const trackPageViewFunc = AnalyticsUtils.trackPageView as (
    pageName: string,
    meta: Record<string, unknown>
  ) => void
  trackPageViewFunc(pageName, meta)
}

export const trackAll = (
  eventName: string,
  eventProperties?: TrackEventProperties | ExperimentContext
) => {
  // In order to save FS subscription credits send generic top-level event name: 'HotelPages'
  // and add more descriptive name to the payload. See details
  // https://app.shortcut.com/findhotel/story/48096/change-event-naming-in-fullstory-for-hotelpages-events
  if (eventName.startsWith(HOTEL_PAGES_NAMESPACE)) {
    trackFullStoryEvent(HOTEL_PAGES_NAMESPACE, {
      ...eventProperties,
      name: eventName
    })
  } else {
    trackFullStoryEvent(eventName, eventProperties)
  }

  AnalyticsUtils.trackEvent(eventName, eventProperties)
  logEvent(eventName, eventProperties)
}

const middleware: MiddlewareType = store => next => async action => {
  if (!AnalyticsUtils.analyticsPlatformEnabled()) return next(action)

  const state = store.getState()
  const anonymousId = getAnonymousId(state)
  const searchId = getSearchId(state)
  const timezone = getTimezone()
  const brand = getBrand(state)
  const cio = customerIo(brand)

  switch (action.type) {
    case TRACK_EVENT_V1: {
      const {event} = action
      const {name} = event
      const urlParamsValue = urlParams()

      const eventProperties = getEventProperties(
        {
          ...event,
          urlParams: urlParamsValue
        },
        {searchId: searchId || undefined}
      )

      trackAll(name, eventProperties)
      break
    }

    case TRACK_PAGE_VIEW: {
      trackPageView(action.pageName, action.meta)
      getFullStoryObject()?.identify?.(anonymousId || '', {
        pageName: action?.pageName
      })

      break
    }

    case TRACK_CIO_PAGE_VIEW: {
      const {id, ...params} = action.params

      cio.trackPageView({
        pageName: action.pageName,
        userId: id,
        anonymousId: anonymousId || '',
        ...params,
        timezone
      })

      break
    }

    // Currently triggered in Checkout only when Optimizely datafile fails to load
    case TRACK_ERROR_TRIGGERED: {
      const {event} = action

      trackAll('ErrorTriggered', event)
      break
    }

    case TRACK_RAA_OFFER_CLICKED: {
      const {event} = action
      trackAll('OfferClicked', event)
      break
    }

    case identifyAuthenticatedUser.type: {
      const user = getUser(state)
      const userId = getUserId(user)

      if (!userId) return

      const anonymousId = getAnonymousId(state)
      const cognitoTraits = getUserIdentifiableTraits(state)
      const memberPlus = getCognitoIsMemberPlus(user)
      const brand = getBrand(state)

      AnalyticsUtils.identify(userId, {
        memberPlus,
        user: cognitoTraits
      })

      try {
        await customIdentify({
          anonymousId: anonymousId || '',
          userId,
          customerDataEndpoint: Settings.get(
            'REACT_APP_ANALYTICS_CUSTOMER_DATA_ENDPOINT'
          ),
          customerDataApiKey: Settings.get(
            'REACT_APP_ANALYTICS_CUSTOMER_DATA_API_KEY'
          ),
          brandName: brand.name,
          cognitoTraits
        })
      } catch (error) {
        logToDataDog({
          logName: 'CustomIdentifyEventFailed',
          metadata: {
            errorType: (error as Error)?.message
          },
          level: 'info'
        })
      }

      break
    }

    case LOG_EVENT: {
      const {metric, meta, level} = action
      logEvent(metric, meta, level)
      break
    }

    default: {
      break
    }
  }

  return next(action)
}

export default middleware
