/* eslint-disable fp/no-mutating-methods */
import {createContext, useContext} from 'react'
import {History, LocationDescriptor, Path} from 'history'
import {ParsedUrlQuery} from 'querystring'
import {UrlObject} from 'url'

export type ReactRouterUrl = Path | LocationDescriptor<History.LocationState>
export type Url = ReactRouterUrl | UrlObject

export type QueryParams = ParsedUrlQuery | {[key: string]: unknown}

interface RouterContextProps {
  push: (url: Url) => void
  replace: (url: Url) => void
  goBack: () => void
  getQueryParams: () => QueryParams
}

export const RouterContext = createContext<RouterContextProps>(
  {} as RouterContextProps
)

export const useRouter = () => useContext(RouterContext)
