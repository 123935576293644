import React, {ReactElement, useContext} from 'react'
import {useSelector} from 'react-redux'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Card} from '@daedalus/atlas/Card'
import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {Divider} from '@daedalus/atlas/Divider'
import {Grid} from '@daedalus/atlas/helpers/Grid'
import {Text} from '@daedalus/atlas/Text'
import {mq} from '@daedalus/atlas/utils/breakpoints'
import {selectIsAuthenticated} from '@daedalus/core/src/auth/modules/selectors'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {ReferralShareButton} from '../../../../user/ReferralShareButton'
import {ReferralLinkContext} from '../../ReferralLinkProvider'
import {GemsBoxSvg} from '../GemsBoxImage'
import {CopyLinkButton} from './CopyLinkButton'

const CONTENT_MAX_WIDTH = 340
const ILLUSTRATION_DESKTOP_HEIGHT = 138
const ILLUSTRATION_MOBILE_HEIGHT = 102

const CardContent = styled.div<{isMobile: boolean}>(({theme, isMobile}) => {
  if (isMobile) {
    return css`
      padding: ${theme.layout.spacing.s700}px ${theme.layout.spacing.s600}px;
    `
  }
  return css`
    padding: ${theme.layout.spacing.s900}px ${theme.layout.spacing.s700}px;
  `
})

const IllustrationWrapper = styled.div(
  ({theme}) => css`
    height: ${ILLUSTRATION_MOBILE_HEIGHT}px;
    ${mq.desktopSm(css`
      padding: ${theme.layout.spacing.s700}px;
      height: ${ILLUSTRATION_DESKTOP_HEIGHT}px;
    `)}
  `
)

const TextWrapper = styled.div(
  ({theme}) => css`
    margin-bottom: ${theme.layout.spacing.s800}px;
    margin-top: ${theme.layout.spacing.s500}px;
    text-align: center;
    ${mq.desktopSm(css`
      margin-top: 0;
      text-align: initial;
    `)}
  `
)

const Title = styled(Text)(
  ({theme}) => css`
    margin-bottom: ${theme.layout.spacing.s500}px;
  `
)

const StyledText = styled(Text)`
  ${mq.desktopSm(css`
    max-width: ${CONTENT_MAX_WIDTH}px;
  `)}
`

const ButtonsWrapper = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    max-width: ${CONTENT_MAX_WIDTH}px;
    margin-left: auto;
    margin-right: auto;
    ${mq.desktopSm(css`
      margin-left: 0;
    `)}
  `
)

const DividerWrapper = styled.div(
  ({theme}) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.content.neutral.c500};
    margin-top: ${theme.layout.spacing.s600}px;
    margin-bottom: ${theme.layout.spacing.s600}px;
    > p {
      margin-left: ${theme.layout.spacing.s300}px;
      margin-right: ${theme.layout.spacing.s300}px;
    }
  `
)

const DividerContainer = styled.div`
  flex-grow: 1;
`

const DividerWithText = ({children}: {children: ReactElement}) => (
  <DividerWrapper>
    <DividerContainer>
      <Divider />
    </DividerContainer>
    <Text variant={'bodyS'}>{children}</Text>
    <DividerContainer>
      <Divider />
    </DividerContainer>
  </DividerWrapper>
)

const useContainerFlexDirection = () => {
  const {isDesktopXs, isMobile} = useDeviceLayout()
  if (isMobile) return 'column-reverse'
  return isDesktopXs ? 'column-reverse' : 'row'
}

const useCanRefer = () => {
  const {referralShortLink} = useContext(ReferralLinkContext)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  return isAuthenticated && referralShortLink
}

export const ReferralProgram = () => {
  const {isMobile} = useDeviceLayout()
  const showLinkCopyButton = useCanRefer()
  const {referralShortLink} = useContext(ReferralLinkContext)
  const containerFlexDirection = useContainerFlexDirection()
  const cardBorderRadius = isMobile ? undefined : 'md'
  const cardShadow = isMobile ? undefined : 'action'

  // Displaying it also in dev mode to make sure it's always visible in mobile-emulation for easier testing
  const canShare =
    Boolean(navigator.share) || process.env.NODE_ENV === 'development'

  return (
    <div>
      <Card borderRadius={cardBorderRadius} shadow={cardShadow}>
        <CardContent isMobile={isMobile}>
          <Grid
            container
            direction={containerFlexDirection}
            justify="space-between"
            alignItems="center"
          >
            <Grid mobileSm={12} desktopSm={8}>
              <TextWrapper>
                <Title variant="title2XL">
                  <FormattedMessageWrapper
                    id="membership.LetYourFriendsInOnTheSecret"
                    defaultMessage="Let your friends in on the secret..."
                  />
                </Title>
                <StyledText variant="bodyM">
                  <FormattedMessageWrapper
                    id="membership.ShareThisLinkToGive"
                    defaultMessage="Share this link to give your friends instant access to Gems deals and savings of up to 50%."
                  />
                </StyledText>
              </TextWrapper>
              <ButtonsWrapper>
                {canShare && (
                  <>
                    {isMobile && (
                      <ReferralShareButton
                        isLoading={!showLinkCopyButton}
                        referralShortLink={referralShortLink}
                      />
                    )}
                    {isMobile && (
                      <DividerWithText>
                        <FormattedMessageWrapper
                          id="referralProgram.orCopy"
                          defaultMessage="or copy the link"
                        />
                      </DividerWithText>
                    )}
                  </>
                )}
                <CopyLinkButton
                  isLoading={!showLinkCopyButton}
                  referralShortLink={referralShortLink}
                />
              </ButtonsWrapper>
            </Grid>
            <IllustrationWrapper>
              <GemsBoxSvg />
            </IllustrationWrapper>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}
