import React from 'react'
import {FormattedDate, FormattedTime} from 'react-intl'
import {useSelector} from 'react-redux'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'
import {selectUserEmail} from '@daedalus/core/src/auth/modules/selectors'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

interface Props {
  expiresAt?: string
}

const List = styled.div(
  ({theme}) => css`
    margin-top: ${theme.layout.spacing.s500}px;
  `
)

const ListItem = styled.div(
  ({theme}) => css`
    display: flex;
    align-items: center;
    gap: ${theme.layout.spacing.s200}px;
    margin-bottom: ${theme.layout.spacing.s400}px;
  `
)

const CustomIcon = styled(Icon)(
  ({theme}) => css`
    width: 36px;
    height: 36px;
    color: ${theme.colors.content.special.c800};
    background: ${theme.colors.background.special.c050};
    border-radius: ${theme.layout.radius.sm}px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  `
)

export const NextSteps = ({expiresAt}: Props) => {
  const userEmail = useSelector(selectUserEmail)
  const expireAt = expiresAt && new Date(expiresAt)
  return (
    <div>
      <Text variant="titleL">
        <FormattedMessageWrapper
          id="df.nextSteps"
          defaultMessage="Next steps:"
        />
      </Text>

      <List>
        <ListItem>
          <CustomIcon name={'Mail'} />
          <Text variant="bodyS">
            <FormattedMessageWrapper
              id="df.checkYourEmail"
              defaultMessage="Check {email} for confirmation."
              values={{
                email: <span className="fs-mask">{userEmail}</span>
              }}
            />
          </Text>
        </ListItem>

        <ListItem>
          <CustomIcon name={'CalendarEnd'} />
          <Text variant="bodyS">
            <FormattedMessageWrapper
              id="df.bookBeforeExpiration"
              defaultMessage="
                Book at the frozen price until {expirationDate}, {time}."
              values={{
                expirationDate: (
                  <FormattedDate
                    value={expireAt}
                    month="long"
                    day="2-digit"
                    year="numeric"
                  />
                ),
                time: (
                  <FormattedTime
                    value={expireAt}
                    hour="numeric"
                    minute="2-digit"
                  />
                )
              }}
            />
          </Text>
        </ListItem>
      </List>
    </div>
  )
}
