import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {ThemeType} from 'types/Theme'

import SvgCircle from '../../../foundation/illustrations/Circle'
import SvgDiamond from '../../../foundation/illustrations/Diamond'
import SvgTriangle from '../../../foundation/illustrations/Triangle'
import {AlertVariant} from './Alert'

type ShapedBackgroundProps = {
  /** Pass through classname to allow styles overrides */
  className?: string
  theme?: ThemeType
  variant?: AlertVariant
}

type ShapeProps = {
  left?: number
  top?: number
  right?: number
  bottom?: number
}

export const ShapedBackgroundWrapper = styled('div')<{variant: AlertVariant}>(
  ({theme, variant}) => {
    const styleProps = theme.colors.alert[variant]
    return css`
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
      background-color: ${styleProps.background};
      color: ${
        // @ts-ignore FH theme and vio diverge on the type of colors
        styleProps.shapes
      };
    `
  }
)

export const Shape = styled('div')(
  ({left, right, top, bottom}: ShapeProps) => css`
    position: absolute;
    left: ${left ? `${left}px` : 'auto'};
    right: ${right ? `${right}px` : 'auto'};
    top: ${top ? `${top}px` : 'auto'};
    bottom: ${bottom ? `${bottom}px` : 'auto'};

    > svg {
      display: block;
    }
  `
)

export const ShapedBackground = ({
  variant = 'default',
  className
}: ShapedBackgroundProps) => {
  return (
    <ShapedBackgroundWrapper variant={variant} className={className}>
      <Shape left={-56} top={32}>
        <SvgTriangle />
      </Shape>
      <Shape right={-86} top={-8}>
        <SvgDiamond />
      </Shape>
      <Shape right={88} bottom={-60}>
        <SvgCircle />
      </Shape>
    </ShapedBackgroundWrapper>
  )
}
