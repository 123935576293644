import {useSelector} from 'react-redux'
import {getAnonymousId} from 'modules/meta/selectors'
import Settings from 'Settings'
import {urlParams} from 'utils/urlParams'

import {getBrandCode} from '@daedalus/core/src/brand/modules/selectors'
import {SapiEffectParams} from '@daedalus/core/src/sapi/services/useInitializeSapi'
import {mapLatinNumbersSystemToArabic} from '@daedalus/core/src/utils/string'

export const useGetSapiInitializationParams = () => {
  const profileKey = Settings.get('REACT_APP_SAPI_PROFILE_KEY')
  const params = urlParams()
  const {locale, currency} = params
  const anonymousId = useSelector(getAnonymousId)
  const brandCode = useSelector(getBrandCode)
  const language = mapLatinNumbersSystemToArabic(locale)

  return {
    language,
    anonymousId,
    currency,
    profileKey: profileKey,
    brand: brandCode
  } as SapiEffectParams
}
