/**
 *
 * @param window The global window object
 * @returns a number that tells the density of pixels from the device
 */
export const getDevicePixelRatio = (window: Window) => {
  if (window.devicePixelRatio) return window.devicePixelRatio

  const mediaQuery =
    '(-webkit-min-device-pixel-ratio: 1.5),\
            (min--moz-device-pixel-ratio: 1.5),\
            (-o-min-device-pixel-ratio: 3/2),\
            (min-resolution: 1.5dppx)'

  if (window.matchMedia && window.matchMedia(mediaQuery).matches) return 2

  return 1
}
