import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Button} from '@daedalus/atlas/Button'
import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {Divider} from '@daedalus/atlas/Divider'
import ContentWrapper from '@daedalus/atlas/helpers/ContentWrapper'
import {Flex} from '@daedalus/atlas/helpers/Flex'
import {Icon} from '@daedalus/atlas/Icon'
import {ANIMATION_TYPES, Overlay} from '@daedalus/atlas/Overlay'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {getIsRtlLanguage} from '@daedalus/core/src/auth/modules/selectors'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {getLinkByType} from '@daedalus/core/src/offer/business/getLinkByType'
import {ActionLinkTypes} from '@daedalus/core/src/offer/types/actionLink'

import {useGetSapiRoom} from '../../hooks/useGetSapiRoom'
import {
  getAnalyticsContext,
  getApplication,
  getComponent,
  getError,
  getIsFreezeOverlayOpen,
  getOfferEligibility
} from '../../modules/selector'
import {closeFreezeOverlay} from '../../modules/slice'
import {BookingCreation} from '../BookingCreation'
import {BrandBottomSheet} from '../BrandBottomSheet'
import {ErrorButton} from '../ErrorMessage/ErrorButton'
import {useGetErrorMessageAndCase} from '../ErrorMessage/useGetErrorMessageAndCase'
import {HotelCardWithHeaders} from '../HotelCardWithHeaders'
import {Content} from './Content'
import {OfferCheck} from './OfferCheck'
import {SkeletonLoading} from './SkeletonLoading'
import {Snowflake} from './Snowflake'
import {useTrackFreezeOverlayViewed} from './useTrackFreezeOverlayViewed'

const DESKTOP_OVERLAY_WIDTH = 480

const Wrapper = styled.div`
  min-height: 60vh;
`

const HeaderIcon = styled.div(
  ({theme}) => css`
    margin-top: ${theme.layout.spacing.s400}px;
    width: 64px;
    height: 64px;
    border: ${theme.layout.spacing.s100}px solid
      ${theme.colors.background.neutral.c000};
    border-radius: ${theme.layout.radius.rounded}px;
    background: ${theme.colors.background.brand.c100};
    display: flex;
    align-items: center;
    justify-content: center;
  `
)

const ScrollableWrapper = styled.div`
  height: calc(100vh - 88px);
  overflow: auto;
`

const CtaWrapper = styled.div(
  ({theme}) => css`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 2;
    background-color: ${theme.colors.background.neutral.c000};
    overflow: hidden;
  `
)

const CloseButtonWrapper = styled(Flex.Row)(
  ({theme}) => css`
    padding: ${theme.layout.spacing.s400}px ${theme.layout.spacing.s200}px 0;
    z-index: 2;
    flex-direction: row-reverse;
  `
)

const CloseButton = styled(Button)`
  opacity: 0.6;
`

export const FreezeDetails = () => {
  const isRtlLanguage = useSelector(getIsRtlLanguage)
  const dispatch = useDispatch()
  const {isMobile} = useDeviceLayout()

  const {page} = useSelector(getApplication)
  const component = useSelector(getComponent)
  const isOpen = useSelector(getIsFreezeOverlayOpen)
  const {isLoading: isOfferCheckLoading, isOfferEligible} =
    useSelector(getOfferEligibility)
  const {sapiRoomLoading, sapiMatchedOffer} = useGetSapiRoom({
    shouldSkip: !isOpen
  })
  const {leadContext, offerContext, priceContext} =
    useSelector(getAnalyticsContext)
  const error = useSelector(getError)
  const {errored} = error || {}
  const {ctaText, errorCase} = useGetErrorMessageAndCase({
    error
  })

  const [isLoading, setIsLoading] = useState(true)
  const [isCreateBookingLoading, setCreateBookingLoading] = useState(false)

  useTrackFreezeOverlayViewed({isLoading, isOfferEligible})

  const offerLink = getLinkByType(
    sapiMatchedOffer?.links,
    ActionLinkTypes.CHECKOUT
  )

  const hideOverlay = () => dispatch(closeFreezeOverlay())

  const handleCreateFreezeBooking = useCallback(() => {
    // render BookingCreation component that initiates the booking creation request
    setCreateBookingLoading(true)

    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.DealFreezeButton,
        action: Action.Clicked,
        analyticsContext: {
          ...(offerContext && {[AnalyticsContext.OfferContext]: offerContext}),
          ...(leadContext && {[AnalyticsContext.LeadContext]: leadContext}),
          ...(priceContext && {[AnalyticsContext.PriceContext]: priceContext})
        },
        page,
        ...(component && {component}),
        team: Team.Select
      })
    )
  }, [dispatch, page, leadContext, offerContext, priceContext])

  const DealFreezeDesktopButton = useMemo(() => {
    if (errored) return <ErrorButton ctaText={ctaText} errorCase={errorCase} />

    if (isCreateBookingLoading) return null

    if (!errored && isOfferEligible)
      return (
        <Button
          dataId="FreezeNowForFree"
          variant="special"
          fullWidth
          iconEnd={<Icon name="Ice" />}
          onClick={handleCreateFreezeBooking}
          size="xl"
        >
          <FormattedMessageWrapper
            id="df.freezeOverlayButton"
            defaultMessage="Freeze price for free"
          />
        </Button>
      )

    return null
  }, [
    isCreateBookingLoading,
    ctaText,
    errorCase,
    errored,
    isOfferEligible,
    handleCreateFreezeBooking
  ])

  useEffect(() => {
    if (sapiRoomLoading || isOfferCheckLoading) {
      // If loading, set state immediately
      setIsLoading(true)
    } else {
      // If not loading, debounce loading state to avoid flash of unloaded content
      const timeout = setTimeout(() => setIsLoading(false), 50)
      return () => clearTimeout(timeout)
    }
  }, [sapiRoomLoading, isOfferCheckLoading])

  if (isMobile)
    return (
      <BrandBottomSheet
        isOpen={isOpen}
        iconComponent={
          <HeaderIcon>
            <Snowflake />
          </HeaderIcon>
        }
        snapPoints={{top: 'nearFull', middle: 0.85}}
        onClose={hideOverlay}
      >
        <Wrapper>
          {isOpen && (
            <>
              {offerLink && <OfferCheck offerLink={offerLink} />}
              <Content
                isLoading={isLoading}
                isFreezable={Boolean(!errored && isOfferEligible)}
                isCreateBookingLoading={isCreateBookingLoading}
                onCreateFreezeBooking={handleCreateFreezeBooking}
              />
            </>
          )}
        </Wrapper>
      </BrandBottomSheet>
    )

  return (
    <Overlay
      closeOnEsc
      animatePresenceInitial
      transitionDuration={300}
      isOpen={isOpen}
      containerStyles={{
        width: DESKTOP_OVERLAY_WIDTH,
        marginLeft: `calc(100vw - ${DESKTOP_OVERLAY_WIDTH}px)`
      }}
      animationType={
        isRtlLanguage ? ANIMATION_TYPES.SLIDE_LEFT : ANIMATION_TYPES.SLIDE_RIGHT
      }
      onShadeBackgroundClick={hideOverlay}
    >
      <ScrollableWrapper>
        <CloseButtonWrapper>
          <CloseButton
            variant="quiet"
            iconStart={<Icon name={'Close'} />}
            onClick={hideOverlay}
          />
        </CloseButtonWrapper>
        <ContentWrapper paddingX="s700">
          <ContentWrapper paddingTop="s300">
            {offerLink && <OfferCheck offerLink={offerLink} />}
            {isLoading ? (
              <SkeletonLoading isOverlayContent />
            ) : isCreateBookingLoading ? (
              <BookingCreation hasErrorCTA={false} />
            ) : (
              <ContentWrapper paddingTop="s500">
                <HotelCardWithHeaders headerAlign="left" hasErrorCTA={false} />
              </ContentWrapper>
            )}
          </ContentWrapper>
        </ContentWrapper>
      </ScrollableWrapper>

      {Boolean(DealFreezeDesktopButton) && (
        <CtaWrapper>
          <Divider />
          <ContentWrapper
            paddingX="s700"
            paddingTop="s500"
            paddingBottom="s600"
          >
            {DealFreezeDesktopButton}
          </ContentWrapper>
        </CtaWrapper>
      )}
    </Overlay>
  )
}
