import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {PreferencesOverlay} from 'components/PreferencesOverlay'
import {getCurrentBrand} from 'configs/brand'
import {getAnonymousId} from 'modules/meta/selectors'
import {
  changeAccountVisibility,
  changeMembershipVisibility,
  changeReferralVisibility
} from 'modules/overlay/actions'
import {
  getAccountVisibility,
  getMembershipVisibility,
  getReferralVisibility
} from 'modules/overlay/selectors'
import Settings from 'Settings'
import {ThemeType} from 'types/Theme'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {getDataDogRumInstance} from '@daedalus/core/src/analytics/components/DataDogClient'
import {Page} from '@daedalus/core/src/analytics/types/Events'
import {MembershipOverlay} from '@findhotel/shared/src/privateDeal/gems/MembershipOverlay'
import {AccountOverlay} from '@findhotel/shared/src/user/account/AccountOverlay'
import {ReferralOverlay} from '@findhotel/shared/src/user/referral/ReferralOverlay'

import {DesktopHeader} from './DesktopHeader'
import {MobileHeader} from './MobileHeader'

const REFERRAL_SHORT_LINK_URL = `${Settings.get(
  'REACT_APP_MEMBERSHIP_API_ENDPOINT'
)}referral/short-link`

interface HeaderWithStoreProps {
  currentCurrency: string
  currentLanguage: string
  ddPagePrefix?: 'pp' | 'cp'
  disableLogoClick?: boolean
  disablePreferences?: boolean
  hasHeaderMenu?: boolean
  hasCurrencySelector?: boolean
  hasLanguageSelector?: boolean
  hasSearchForm?: boolean
  hasShadow?: boolean
  forceHideSearchBox?: boolean
  hasUserWidget?: boolean
  mobileCenterContent?: React.ReactElement
  onBackButtonClick?: () => void
  hideMyBooking?: boolean
  hideGemsMembership?: boolean
  hideDebugPanel?: boolean
  hideDebugScreen?: boolean
  hidePrivacyPolicy?: boolean
  hideNotification?: boolean
  hideWatchlist?: boolean
  hideReferral?: boolean
  preferenceTitle?: JSX.Element
  pageName?: Page
  hideManageAccount?: boolean
  onCurrencyChange?: (currency: string) => void
  showStartLogo?: boolean
}

// TODO (@select): the margin-bottom should be moved to parent layout
const DesktopWrapper = styled('div')(
  ({theme}: {theme: ThemeType}) =>
    css`
      margin-bottom: ${theme.layout.spacing.s500}px;
    `
)

export const HeaderWithStore = ({
  // Mobile
  hasCurrencySelector,
  hasLanguageSelector,
  hasShadow = false,
  forceHideSearchBox = false,
  mobileCenterContent,
  onBackButtonClick,

  // Desktop
  hasUserWidget = true,
  hideManageAccount = false,

  //Common
  disableLogoClick = false,
  disablePreferences = false,
  currentCurrency,
  currentLanguage,
  ddPagePrefix,
  hasSearchForm = false,
  hideMyBooking,
  hideGemsMembership,
  hideDebugPanel,
  hideDebugScreen,
  hidePrivacyPolicy,
  hideNotification,
  hideWatchlist,
  hideReferral,
  preferenceTitle,
  pageName,
  onCurrencyChange,
  showStartLogo
}: HeaderWithStoreProps) => {
  const dispatch = useDispatch()
  const brand = getCurrentBrand()
  const {isMobile} = useDeviceLayout()
  const anonymousId = useSelector(getAnonymousId)
  const isMembershipOpen = Boolean(useSelector(getMembershipVisibility))
  const isAccountOverlayOpen = Boolean(useSelector(getAccountVisibility))
  const isReferralOverlayOpen = Boolean(useSelector(getReferralVisibility))

  useEffect(() => {
    if (ddPagePrefix) {
      getDataDogRumInstance().addTiming(`${ddPagePrefix}HeaderRendered`)
    }
  }, [ddPagePrefix])

  const handleHideMembershipOverlay = () => {
    dispatch(changeMembershipVisibility(false)())
  }

  const handleHideAccountOverlay = () => {
    dispatch(changeAccountVisibility(false)())
  }

  const handleHideReferralOverlay = () => {
    dispatch(changeReferralVisibility(false)())
  }

  useEffect(() => {
    if (ddPagePrefix) {
      getDataDogRumInstance().addTiming(`${ddPagePrefix}HeaderRendered`)
    }
  }, [ddPagePrefix])

  return isMobile ? (
    <>
      <MobileHeader
        centerContent={mobileCenterContent}
        hasShadow={hasShadow}
        hasSearchForm={hasSearchForm}
        hasHeaderMenu={!disablePreferences}
        disableLogoClick={disableLogoClick}
        forceHideSearchBox={forceHideSearchBox}
        onBackButtonClick={onBackButtonClick}
        pageName={pageName}
        showStartLogo={showStartLogo}
      />
      {!disablePreferences && (
        <>
          <PreferencesOverlay
            onCurrencyChange={onCurrencyChange}
            currentCurrency={currentCurrency}
            currentLanguage={currentLanguage}
            hasLanguageSelector={hasLanguageSelector}
            hasCurrencySelector={hasCurrencySelector}
            preferenceTitle={preferenceTitle}
            hideMyBooking={hideMyBooking}
            hideGemsMembership={hideGemsMembership}
            hideDebugPanel={hideDebugPanel}
            hideDebugScreen={hideDebugScreen}
            hidePrivacyPolicy={hidePrivacyPolicy}
            hideNotification={hideNotification}
            hideWatchlist={hideWatchlist}
            hideReferral={hideReferral}
            pageName={pageName}
          />
          {anonymousId && (
            <MembershipOverlay
              onClose={handleHideMembershipOverlay}
              isOpen={isMembershipOpen}
              brand={brand}
              anonymousId={anonymousId}
              onExploreButtonClick={() =>
                (window.location.href = brand.address)
              }
            />
          )}
          <AccountOverlay
            isOpen={isAccountOverlayOpen}
            onClose={handleHideAccountOverlay}
            brand={brand}
          />
          {anonymousId && (
            <ReferralOverlay
              isOpen={isReferralOverlayOpen}
              onClose={handleHideReferralOverlay}
              brand={brand}
              anonymousId={anonymousId}
              referralShortLinkUrl={REFERRAL_SHORT_LINK_URL}
            />
          )}
        </>
      )}
    </>
  ) : (
    <DesktopWrapper>
      <DesktopHeader
        page={pageName}
        selectedCurrency={currentCurrency}
        hideMyBookings={hideMyBooking}
        disableLogoClick={disableLogoClick}
        disablePreferences={disablePreferences}
        hasCurrencySelector={hasCurrencySelector}
        hasSearchForm={hasSearchForm}
        hasUserWidget={hasUserWidget}
        hideManageAccount={hideManageAccount}
        onCurrencyChange={onCurrencyChange}
      />
    </DesktopWrapper>
  )
}
