import {cookieSecuritySettings} from '../cookies/cookieSecuritySettings'
import {getDomainName} from '../url'

const maxAge = 30 // 30 days
const name = 'sapiLabel'

export const sapiLabelCookieConfig = {
  name,
  options: {
    maxAge,
    domain: getDomainName(window.location.hostname),
    ...cookieSecuritySettings(true)
  }
}
