import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Badge} from '@daedalus/atlas/Badge'
import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'
import {mq} from '@daedalus/atlas/utils/breakpoints'
import {Brand} from '@daedalus/core/src/brand/types'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {BrandLogo} from '../../../../../whiteLabel/BrandLogo'
import {PrivateDealTag} from '../../../../PrivateDealTag'
import {DiscoverGemsButton} from '../DiscoverGemsButton'
import gemsBoxImage from './images/gemsBox.svg'
import whatIsGemsImage from './images/whatIsGemsMobile.svg'

const VioLogo = styled(BrandLogo)(
  ({theme}) => css`
    height: ${theme.layout.spacing.s700}px;
    margin-top: ${theme.layout.spacing.s600}px;
  `
)

const GemsIcon = styled(Icon)(
  ({theme}) => css`
    margin-top: ${theme.layout.spacing.s400}px;
  `
)

const GemsBox = styled.div(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: ${theme.colors.background.neutral.c000} url(${gemsBoxImage})
      right bottom no-repeat;
    border: 1px solid ${theme.colors.border.neutral.c100};
    border-radius: ${theme.layout.spacing.s300}px;
    padding: ${theme.layout.spacing.s600}px ${theme.layout.spacing.s500}px;
    margin-top: ${theme.layout.spacing.s600}px;
    width: 100%;
    ${GemsBoxRow} {
      margin-bottom: ${theme.layout.spacing.s600}px;
    }
    ${mq.desktopXs(css`
      margin-top: ${theme.layout.spacing.s800}px;
    `)}
  `
)

const Title = styled(Text)(
  ({theme}) =>
    css`
      margin: ${theme.layout.spacing.s600}px;
    `
)

const Description = styled(Text)`
  text-align: center;
  ${mq.desktopXs(css`
    text-align: left;
  `)}
`

const ContentWrapper = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const GemsBoxRow = styled.div(
  ({theme}) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    span {
      display: flex;
      align-items: center;
      button {
        margin-left: ${theme.layout.spacing.s200}px;
        margin-right: ${theme.layout.spacing.s200}px;
      }
      svg {
        display: flex;
      }
    }
  `
)

const GemsBadge = styled(Badge)(
  ({theme}) => css`
    color: ${theme.colors.content.special.c600};
    background-color: ${theme.colors.background.special.c100};
    margin-right: ${theme.layout.spacing.s300}px;
  `
)

const WhatIsGemsContainer = styled.div(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${theme.colors.background.neutral.c000} url(${whatIsGemsImage})
      0 0 / 100% auto no-repeat;
    padding: ${theme.layout.spacing.s500}px ${theme.layout.spacing.s400}px;
    ${mq.desktopXs(css`
      padding: ${theme.layout.spacing.s800}px ${theme.layout.spacing.s400}px;
      border: 1px solid ${theme.colors.border.neutral.c100};
      border-radius: ${theme.layout.radius.lg}px;
    `)}
  `
)

interface Props {
  brand: Brand
  anonymousId: string
}

export const WhatIsGems = ({brand, anonymousId}: Props) => (
  <WhatIsGemsContainer>
    <ContentWrapper>
      <VioLogo brand={brand} />
      <GemsIcon name="GemsLogo" size="xl" colorPath="content.special.c600" />
      <Title variant="titleXL">
        <FormattedMessageWrapper
          id="membership.sameStayEvenBetterPrice"
          defaultMessage="Same stay, even better price."
        />
      </Title>
      <Description variant="bodyM">
        <FormattedMessageWrapper
          id="membership.AsAGemsMemberYouHaveAccess"
          defaultMessage="As a {gems} member, you have access to deals you won’t find anywhere else."
          values={{gems: 'Gems'}}
        />
      </Description>
      <GemsBox>
        <GemsBoxRow>
          <GemsBadge size="md" variant="special">
            1
          </GemsBadge>
          <Text variant="bodyM">
            <FormattedMessageWrapper
              id="membership.CompareTheMarketsBestRates"
              defaultMessage="Compare the market’s best rates"
            />
          </Text>
        </GemsBoxRow>
        <GemsBoxRow>
          <GemsBadge size="md" variant="special">
            2
          </GemsBadge>
          <Text variant="bodyM">
            <FormattedMessageWrapper
              id="membership.AccessPricesThatOtherSitesKeepSecret"
              defaultMessage="Access prices that other sites keep secret"
            />
          </Text>
        </GemsBoxRow>
        <GemsBoxRow>
          <GemsBadge size="md" variant="special">
            3
          </GemsBadge>
          <Text variant="bodyM">
            <FormattedMessageWrapper
              id="membership.LookForGemsToSaveUpTo50"
              defaultMessage="Look for {gems} to save up to 50%"
              values={{
                gems: (
                  <PrivateDealTag
                    brandCode="vio"
                    message={{
                      id: 'membership.membershipLabel',
                      defaultMessage: 'Gems Member'
                    }}
                  />
                )
              }}
            />
          </Text>
        </GemsBoxRow>
        <DiscoverGemsButton anonymousId={anonymousId} />
      </GemsBox>
    </ContentWrapper>
  </WhatIsGemsContainer>
)
