export const getSearchUrl = (
  domain: string,
  hotelId: string,
  checkIn: string,
  checkOut: string,
  rooms: string
) => {
  const baseUrl = `${domain}/Hotel/Search`
  const params = new URLSearchParams()
  params.append('hotelId', hotelId)
  params.append('checkIn', checkIn)
  params.append('checkOut', checkOut)
  params.append('rooms', rooms)
  return `${baseUrl}?${params.toString()}`
}
