import {BOFH_PROFILE_DEFAULT} from 'utils/determineApiKeys'

const setProfileKey = (axiosInstance, experimentApiKey) => {
  const profileKey = experimentApiKey ? experimentApiKey : BOFH_PROFILE_DEFAULT

  if (profileKey) {
    axiosInstance.defaults.headers.common['X-Api-Key'] = profileKey
  }
}

export default setProfileKey
