import {getCookie} from '@daedalus/core/src/utils/cookies'

// The PPN token is no longer being read from the `X-FP-Token` header.
// It now overrides the MOR Forter token in the `payment.fraudProtectionToken` field of the booking payload.
// This function will be removed in sc-126349.
const setRezServerFraudProtectionHeader = axiosInstance => {
  const token = getCookie('RS-CLIENT')

  /* eslint-disable */
  axiosInstance.defaults.headers.common['X-FP-Token'] = token
}

export default setRezServerFraudProtectionHeader