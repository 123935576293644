import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'

import {
  getAnalyticsLeadContext,
  getAnalyticsOfferContext,
  getAnalyticsPriceContext,
  getApplication,
  getComponent,
  getError,
  getIsFreezeOverlayOpen
} from '../../modules/selector'

interface Props {
  isLoading: boolean
  isOfferEligible: boolean | null
}
export const useTrackFreezeOverlayViewed = ({
  isLoading,
  isOfferEligible
}: Props) => {
  const dispatch = useDispatch()
  const isOpen = useSelector(getIsFreezeOverlayOpen)
  const {page} = useSelector(getApplication)
  const component = useSelector(getComponent)

  const {errored} = useSelector(getError)
  const leadContext = useSelector(getAnalyticsLeadContext)
  const offerContext = useSelector(getAnalyticsOfferContext)
  const priceContext = useSelector(getAnalyticsPriceContext)

  const [hasOverlayDisplayEventFired, setHasOverlayDisplayEventFired] =
    useState(false)

  useEffect(() => {
    if (
      hasOverlayDisplayEventFired ||
      !isOpen ||
      !leadContext ||
      errored ||
      isLoading ||
      !isOfferEligible
    )
      return

    setHasOverlayDisplayEventFired(true)
    const availableAnalyticsContexts = {
      ...(offerContext && {[AnalyticsContext.OfferContext]: offerContext}),
      ...(priceContext && {[AnalyticsContext.PriceContext]: priceContext}),
      ...(leadContext && {[AnalyticsContext.LeadContext]: leadContext})
    }
    dispatch(
      trackEvent({
        category: Category.System,
        action: Action.Displayed,
        entity: Entity.DealFreezeOverlay,
        page,
        ...(component && {component}),
        analyticsContext: {
          ...availableAnalyticsContexts
        },
        team: Team.Select
      })
    )
  }, [
    isLoading,
    dispatch,
    offerContext,
    priceContext,
    leadContext,
    page,
    isOpen,
    hasOverlayDisplayEventFired,
    errored,
    isOfferEligible,
    component
  ])

  useEffect(() => {
    // Upon closing, reset the following state to avoid unexpected behavior during subsequent openings.
    if (!isOpen) {
      setHasOverlayDisplayEventFired(false)
    }
  }, [isOpen])
}
