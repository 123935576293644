import {
  buttonsMessage,
  genericMessages,
  getErrorCategory,
  getSupportUrl,
  providerErrorMessagesFallback
} from 'components/errorMonitors/utils'
import {CommonErrorPayloadFormatType} from 'types/errorResponse'
import {
  ErrorOverlayConfigurationType,
  OverlayPropsType
} from 'types/OverlayProps'

import hotelImage from '@daedalus/atlas/src/images/hotel-illustration--full.svg'

interface ButtonActionsType {
  backToRoomSelection: () => void
  backToSearch: () => void
  historyBack: () => void
  refreshPage: () => void
  handleClick?: () => void
}

const supplyFallbackMessages = (error: CommonErrorPayloadFormatType) => {
  switch (getErrorCategory(error)) {
    case 'price_mismatch':
      return {
        messages: {
          title: providerErrorMessagesFallback.genericError,
          firstLine: providerErrorMessagesFallback.priceMismatch,
          buttonLabel: buttonsMessage.tryAgain
        }
      }

    case 'booking_incomplete':
      return {
        messages: {
          title: genericMessages.somethingWrong,
          firstLine: genericMessages.bookingIncompleteMessage,
          buttonLabel: buttonsMessage.chooseAnotherRoom
        }
      }

    default:
      return {
        messages: {
          title: genericMessages.somethingWrong,
          firstLine: genericMessages.unknownErrorFirstLine,
          buttonLabel: genericMessages.buttonCallToAction
        }
      }
  }
}

const errorFactory = (
  error: CommonErrorPayloadFormatType,
  buttonActions: ButtonActionsType
): ErrorOverlayConfigurationType => {
  const handleClick = error.errorResponse.details.recoverable
    ? buttonActions.refreshPage
    : buttonActions.backToSearch

  switch (error.originalErrorAction) {
    // Fallback for uncategorized/unknown errors that is not mapped
    // @Todo - Start to send a event for this cases
    default:
      return {
        buttonAction: handleClick,
        showWasNotChargedMessage: false,
        supportButton: {
          url: getSupportUrl(),
          label: genericMessages.customerSupport
        },
        imageURL: hotelImage
      }
  }
}

export const overlayConfigurationFactory = (
  error: CommonErrorPayloadFormatType,
  buttonActions: ButtonActionsType
): OverlayPropsType | null => {
  if (!error) return null

  return {
    ...errorFactory(error, buttonActions),
    ...supplyFallbackMessages(error)
  }
}
