import {createSelector} from '@reduxjs/toolkit'
import {getHttpRequestId} from 'modules/common/selectors'
import {existingBookingOfferCheckDataTransform} from 'modules/offerCheck/utils'
import {pathOr} from 'ramda'
import bofhDefaultHeaders from 'services/bofhDefaultHeaders'
import Settings from 'Settings'
import {RootState} from 'store'
import buildUrl from 'utils/buildUrl'
import {determinePreBookingSubmitApiKey} from 'utils/determineApiKeys'
import {urlParams} from 'utils/urlParams'
import {getBookingManagementUrl} from 'utils/urls'

import {bookingRetrieveApi} from '@daedalus/core/src/booking/services/bookingRetrieveApi'
import {defaultLanguage} from '@daedalus/core/src/localization/services/locale'
import {getCookie} from '@daedalus/core/src/utils/cookies'
import {getUserDeviceDetails} from '@daedalus/core/src/utils/userAgent'

export const buildBookingManagementLink = (
  searchParams: Record<string, unknown>
) => {
  return buildUrl(getBookingManagementUrl(), searchParams)
}

export const bookingResponse = (state: RootState) =>
  state?.book?.booking?.response

export const getBookingModuleStatus = (state: RootState): string =>
  state?.book?.offerBookState || ''

export const getExistingBookingQueryParams = createSelector(
  (state: RootState) => getHttpRequestId(state),
  httpRequestId => {
    const {bookingId = '', locale: urlLocale} = urlParams()
    const locale = urlLocale || getCookie('locale') || defaultLanguage
    const {deviceCategory: deviceType} = getUserDeviceDetails()
    const profile = determinePreBookingSubmitApiKey()
    const headers = {
      ...bofhDefaultHeaders,
      'X-Request-Id': httpRequestId,
      'X-Api-Key': profile
    }

    return {
      url: Settings.get('REACT_APP_BOFH_ENDPOINT'),
      bookingId,
      deviceType,
      locale,
      headers
    }
  }
)

export const getBundleBookings = (state: RootState) => {
  const {bookingId} = urlParams()
  const [bookingId1, bookingId2] = bookingId?.split(',') || []
  const params = getExistingBookingQueryParams(state)

  const {data: booking1} = bookingRetrieveApi.endpoints.getBookingById.select({
    ...params,
    bookingId: bookingId1
  })(state)

  const {data: booking2} = bookingRetrieveApi.endpoints.getBookingById.select({
    ...params,
    bookingId: bookingId2
  })(state)

  if (booking1 && booking2) {
    const sortedBookings = [booking1, booking2].sort((a, b) => {
      const date1 = new Date(a.checkIn)
      const date2 = new Date(b.checkIn)

      return date1.getTime() - date2.getTime()
    })
    return existingBookingOfferCheckDataTransform(sortedBookings)
  }

  return undefined
}

export const getCountryCode = (state: RootState) => {
  return pathOr(
    '',
    [
      'book',
      'booking',
      'request',
      'payment',
      'billing',
      'address',
      'countryCode'
    ],
    state
  )
}
