import React, {useEffect, useState} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {toggle} from 'opticks'
import {CurrencyType} from 'types/Currency'

import {BottomSheet} from '@daedalus/atlas/BottomSheet'
import {PopUp} from '@daedalus/atlas/PopUp'
import {Text} from '@daedalus/atlas/Text'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {LanguageType} from '@daedalus/core/src/localization/types/LanguageType'

import {Tab, Tabs} from '../Tabs'
import {CurrencySelector} from './CurrencySelector'
import {LanguageSelector} from './LanguageSelector'

export type LanguageCurrencySelectorType = 'language' | 'currency'
interface Props {
  isOpen: boolean
  onClose: () => void
  selectedLanguage?: string
  onChangeLanguage?: (v: string) => void
  selectedCurrency?: string
  onChangeCurrency?: (v: string) => void
  availableLanguages?: LanguageType[]
  availableCurrencies?: CurrencyType[]
  isMobile: boolean
  initialSelector: LanguageCurrencySelectorType
  hasCurrency?: boolean
  onSearchInputFocus?: (type: LanguageCurrencySelectorType) => void
  onChangeActiveTab?: (type: LanguageCurrencySelectorType) => void
  showPriceDisplaySetting?: boolean
  showTotalPrices?: boolean
  onChangePriceDisplay?: (showTotal: boolean) => void
}

const TabsWrapper = styled.div(
  ({theme}) => css`
    position: sticky;
    // This padding and negative margin is to avoid elements overflowing the tabs when
    // scrolling up.
    padding-top: ${theme.layout.spacing.s600}px;
    margin-top: -${theme.layout.spacing.s600}px;
    top: -${theme.layout.spacing.s600}px;
    border-bottom: 1px solid;
    border-color: ${theme.colors.border.neutral.c100};
    margin-bottom: ${theme.layout.spacing.s600}px;
    background-color: ${theme.colors.background.neutral.c000};
    z-index: 1;
  `
)

const titleMessages = {
  language: {
    id: 'selectLanguage',
    defaultMessage: 'Select language'
  },
  currency: {
    id: 'selectCurrency',
    defaultMessage: 'Select currency'
  }
}

const NEW_LOCALES = [
  'bn-BD',
  'fil-PH',
  'fr-CA',
  'el-GR',
  'hi-IN',
  'mr-IN',
  'vi-VN',
  'es-419'
]
const isNotNewLanguage = (lang: LanguageType) =>
  !NEW_LOCALES.includes(lang.locale)

export const LanguageCurrencySelector: React.FC<Props> = ({
  isOpen,
  onClose,
  isMobile,
  availableCurrencies = [],
  availableLanguages = [],
  onChangeCurrency,
  onChangeLanguage,
  selectedCurrency,
  selectedLanguage,
  initialSelector,
  hasCurrency,
  onSearchInputFocus,
  onChangeActiveTab,
  showPriceDisplaySetting,
  showTotalPrices,
  onChangePriceDisplay
}) => {
  const [activeTab, setActiveTab] = useState<string>(initialSelector)

  useEffect(() => {
    setActiveTab(initialSelector)
  }, [initialSelector])

  const handleChangeActiveTab = (value: string) => {
    setActiveTab(value)
    onChangeActiveTab?.(value as LanguageCurrencySelectorType)
  }

  const renderSelector = () => {
    if (
      activeTab === 'language' &&
      availableLanguages.length > 0 &&
      selectedLanguage &&
      onChangeLanguage
    ) {
      return (
        <LanguageSelector
          isMobile={isMobile}
          languages={toggle(
            '2a98dd4d-add-new-languages',
            availableLanguages.filter(isNotNewLanguage),
            availableLanguages
          )}
          onChange={onChangeLanguage}
          value={selectedLanguage}
          onSearchInputFocus={() => onSearchInputFocus?.('language')}
        />
      )
    }

    if (
      hasCurrency &&
      activeTab === 'currency' &&
      onChangeCurrency &&
      selectedCurrency
    )
      return (
        <CurrencySelector
          isMobile={isMobile}
          currencies={availableCurrencies}
          onChange={onChangeCurrency}
          value={selectedCurrency}
          onSearchInputFocus={() => onSearchInputFocus?.('currency')}
          showPriceDisplaySetting={showPriceDisplaySetting}
          showTotalPrices={showTotalPrices}
          onChangePriceDisplay={onChangePriceDisplay}
        />
      )
  }

  if (isMobile) {
    return (
      <BottomSheet
        isOpen={isOpen}
        snapPoints={{top: 'nearFull', middle: 0.95}}
        hasFixedHeight
        titleBarProps={{
          centerContent: (
            <Text variant="titleM">
              <FormattedMessageWrapper
                {...titleMessages[activeTab as Props['initialSelector']]}
              />
            </Text>
          )
        }}
        onClose={onClose}
      >
        {renderSelector()}
      </BottomSheet>
    )
  }

  return (
    <PopUp isOpen={isOpen} width={984} height={513} closeAction={onClose}>
      <TabsWrapper>
        <Tabs onChange={handleChangeActiveTab} value={activeTab}>
          {availableLanguages.length > 0 && (
            <Tab value="language">
              <FormattedMessageWrapper
                id="languageLabel"
                defaultMessage="Language"
              />
            </Tab>
          )}
          {hasCurrency && availableCurrencies.length > 0 && (
            <Tab value="currency">
              <FormattedMessageWrapper
                id="currencyLabel"
                defaultMessage="Currency"
              />
            </Tab>
          )}
        </Tabs>
      </TabsWrapper>
      {renderSelector()}
    </PopUp>
  )
}
