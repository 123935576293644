import * as React from 'react'
import {defineMessages, useIntl} from 'react-intl'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {StartIconsWrapper} from '@daedalus/atlas/helpers/InputBase'
import {Icon} from '@daedalus/atlas/Icon'
import {
  InputButton,
  InputButtonStyledContainer
} from '@daedalus/atlas/InputButton'

interface Props {
  /** Date to be displayed in the FakeInput */
  value: string
  /** The click handler */
  onClick: () => void
  /** Error flag */
  hasError?: boolean
}

const Wrapper = styled('div')`
  position: relative;
`

const DestinationValue = styled('div')(
  ({theme}) => css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: ${theme.layout.spacing.s200}px;
  `
)

export const destinationPickerMessages = defineMessages({
  destinationPlaceholder: {
    id: 'whereAreYouGoing',
    defaultMessage: 'Where are you going?'
  }
})

const FormButton = styled(InputButton)<{hasError: boolean}>(
  ({hasError, theme}) =>
    hasError &&
    css`
      ${InputButtonStyledContainer} {
        border-color: ${theme.colors.border.danger.c700};
      }

      ${StartIconsWrapper} svg {
        fill: ${theme.colors.background.danger.c700};
      }
    `
)

export const FakeDestinationInput = ({
  value,
  onClick,
  hasError = false
}: Props) => {
  const {formatMessage} = useIntl()

  return (
    <Wrapper>
      <FormButton
        size="lg"
        hasError={hasError}
        dataId="DestinationPickerTrigger"
        id="DestinationPickerTrigger"
        placeholder={formatMessage(
          destinationPickerMessages.destinationPlaceholder
        )}
        icon={<Icon colorPath="content.neutral.c600" name="Pin" />}
        onClick={onClick}
      >
        {value && (
          <DestinationValue data-id="DestinationPickerTriggerValue">
            {value}
          </DestinationValue>
        )}
      </FormButton>
    </Wrapper>
  )
}
