import React from 'react'
import {FormattedDate, FormattedNumber, FormattedTime} from 'react-intl'
import {useSelector} from 'react-redux'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Text} from '@daedalus/atlas/Text'
import {Tooltip, TooltipPlacement} from '@daedalus/atlas/Tooltip'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'
import {calculateNightlyRate} from '@daedalus/core/src/offer/business/offersMapping'
import {
  getDisplayPrice,
  getTotalPrice
} from '@daedalus/core/src/price/business/price'

import {
  getFreezeSelectedContext,
  getOfferEligibility
} from '../../modules/selector'
import {getDayDistance} from '../../utils/date'

interface Props {
  shouldShowPayNow: boolean
}

const FirstRow = styled.div(
  ({theme}) => css`
    display: flex;
    flex-direction: row;
    border-top: 1px solid ${theme.colors.border.neutral.c100};
  `
)

const FirstRowCell = styled.div(
  ({theme}) => css`
    flex: 1;
    padding: ${theme.layout.spacing.s400}px ${theme.layout.spacing.s500}px;

    &:last-child {
      border-left: 1px solid ${theme.colors.border.neutral.c100};
      display: flex;
      flex-direction: column;
      align-items: end;
    }
  `
)

const TotalPriceRow = styled.div(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.layout.spacing.s400}px ${theme.layout.spacing.s500}px;
    border-top: 1px solid ${theme.colors.border.neutral.c100};

    > div {
      display: flex;
    }
  `
)

const FlexText = styled(Text)(
  ({theme}) => css`
    display: flex;
    font-weight: ${theme.fonts.weight.sm};
  `
)

const SecondRow = styled.div(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.layout.spacing.s400}px ${theme.layout.spacing.s500}px;
    border-top: 1px solid ${theme.colors.border.neutral.c100};
  `
)

const PayNow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const ExpireDate = styled(Text)(
  ({theme}) => css`
    border-bottom: ${theme.layout.spacing.s100}px dotted
      ${theme.colors.border.neutral.c600};
    padding-bottom: 1px;
    &:hover,
    &:focus {
      text-decoration: none;
      border-bottom: ${theme.layout.spacing.s100}px solid
        ${theme.colors.border.brand.c500};
      color: ${theme.colors.content.brand.c500};
    }
  `
)

const StyledTooltip = styled(Tooltip)(
  ({theme}) => css`
    width: calc(100% - ${theme.layout.spacing.s500}px);
    margin-left: -${theme.layout.spacing.s200}px;
  `
)

const NightlyPriceText = styled(Text)(
  ({theme}) => css`
    font-weight: ${theme.fonts.weight.lg};
  `
)

export const PriceSection = ({shouldShowPayNow}: Props) => {
  const {response: offerCheckResponse, isLoading} =
    useSelector(getOfferEligibility)
  const {searchDetail} = useSelector(getFreezeSelectedContext)

  if (isLoading || !offerCheckResponse?.prices) return null

  const {checkIn, checkOut} = searchDetail
  const expireAt =
    offerCheckResponse?.expiresAt && new Date(offerCheckResponse?.expiresAt)

  const displayPrice = getDisplayPrice(offerCheckResponse?.prices)

  const numberOfNights = getDayDistance(checkIn, checkOut)

  const freezeTotalPrice = getTotalPrice(displayPrice)
  const freezePricePerNight =
    freezeTotalPrice &&
    calculateNightlyRate(freezeTotalPrice, checkIn, checkOut)

  const displayPriceCurrency = displayPrice?.chargeable?.currency as string

  const formattedNightlyPrice = (
    <FormattedNumber
      value={freezePricePerNight}
      currency={displayPriceCurrency}
      style="currency"
    />
  )

  const freezeExpires = (
    <FormattedDate
      value={expireAt}
      month="short"
      weekday="short"
      day="2-digit"
    />
  )

  const toolTipContent = (
    <FormattedMessageWrapper
      id="df.tooltipContent"
      defaultMessage="You have until {date} at {time} to complete your booking at the frozen price."
      values={{
        date: (
          <FormattedDate
            value={expireAt}
            weekday={'long'}
            year={'numeric'}
            month="long"
            day="2-digit"
          />
        ),
        time: <FormattedTime value={expireAt} hour="numeric" minute="2-digit" />
      }}
    />
  )

  return (
    <>
      <FirstRow>
        <FirstRowCell>
          <Text variant="bodyS" colorPath="content.neutral.c600">
            <FormattedMessageWrapper
              id="df.freezeExpires"
              defaultMessage="Freeze expires"
            />
          </Text>
          <StyledTooltip
            content={toolTipContent}
            placement={TooltipPlacement.TopStart}
          >
            <ExpireDate variant="titleM">{freezeExpires}</ExpireDate>
          </StyledTooltip>
        </FirstRowCell>
        <FirstRowCell>
          <Text variant="bodyS" colorPath="content.neutral.c600">
            <FormattedMessageWrapper
              id="df.freezeNightlyPrice"
              defaultMessage="Freeze price (nightly)"
            />
          </Text>
          {freezePricePerNight && (
            <NightlyPriceText variant="bodyM">
              {formattedNightlyPrice}
            </NightlyPriceText>
          )}
        </FirstRowCell>
      </FirstRow>
      {freezeTotalPrice && (
        <TotalPriceRow>
          <Text variant="titleM">
            <FormattedMessageWrapper
              id="df.totalPriceToComplete"
              defaultMessage="Total price to complete this booking"
            />
          </Text>
          <div>
            <Text variant="bodyS">
              <FormattedNumber
                value={freezeTotalPrice}
                currency={displayPriceCurrency}
                style="currency"
              />
            </Text>
            <span>&nbsp;</span>
            {freezePricePerNight && numberOfNights > 1 && (
              <FlexText variant="bodyS" colorPath="content.neutral.c600">
                <FormattedMessageWrapper
                  id="df.nights"
                  defaultMessage="({price} x {numberOfNights} nights)"
                  values={{
                    numberOfNights,
                    price: formattedNightlyPrice
                  }}
                />
              </FlexText>
            )}
          </div>
        </TotalPriceRow>
      )}
      {shouldShowPayNow && (
        <SecondRow>
          <PayNow>
            <Text variant="titleM">
              <FormattedMessageWrapper
                id="df.payNow"
                defaultMessage="Pay now:"
              />
            </Text>
            <Text variant="titleL" colorPath="content.special.c600">
              <FormattedNumber
                value={0}
                currency={displayPriceCurrency}
                style="currency"
              />
            </Text>
          </PayNow>
          <Text variant="bodyS" colorPath="content.neutral.c600">
            <FormattedMessageWrapper
              id="df.noPaymentRequired"
              defaultMessage="No payment required to freeze"
            />
          </Text>
        </SecondRow>
      )}
    </>
  )
}
