import React, {FC} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {SpacingPropsType} from 'types/Layout'

interface FlexProps {
  alignItems?: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline'
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
  gap?: SpacingPropsType
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse'
  isReversed?: boolean
}

const baseStyles = ({
  alignItems = 'stretch',
  justifyContent = 'flex-start',
  wrap = 'nowrap'
}: FlexProps) => css`
  display: flex;
  align-items: ${alignItems};
  justify-content: ${justifyContent};
  flex-wrap: ${wrap};
`

const FlexColumn = styled.div<FlexProps>(
  baseStyles,
  ({theme, gap = 's000', isReversed = false}) => css`
    flex-direction: ${isReversed ? 'column-reverse' : 'column'};
    row-gap: ${theme.layout.spacing[gap]}px;
  `
)
const FlexRow = styled.div<FlexProps>(
  baseStyles,
  ({theme, gap = 's000', isReversed = false}) => css`
    flex-direction: ${isReversed ? 'row-reverse' : 'row'};
    column-gap: ${theme.layout.spacing[gap]}px;
  `
)

export const Flex = {
  Row: FlexRow,
  Column: FlexColumn
}
export const FlexComponent: FC<FlexProps> = ({children}) => <>{children}</>
