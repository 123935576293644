import {urlParams} from 'utils/urlParams'

import Settings from '../Settings'

export const BOFH_PROFILE_DEFAULT: string = Settings.get(
  'REACT_APP_BOFH_PROFILE_DEFAULT'
)

export const determinePreBookingSubmitApiKey = () => {
  // Allows you to force a profile key for test
  const {profile = null} = urlParams()

  if (profile) return profile

  return BOFH_PROFILE_DEFAULT
}
