/**
 * Utility used to load js scripts programmatically, returns a promise that is resolved
 * when the script finishes loading.
 *
 * @param src - The URL of the script you want to load
 * @param async - Whether to load the script asynchronously or not
 * @param attributes - You can set an object with additional attributes to be
 * added to the generated script tag.
 */
const loadScript = async (
  src: string,
  async = false,
  attributes: Record<string, any> = {}
): Promise<HTMLScriptElement> => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.async = async
    script.src = src
    script.addEventListener('load', () => {
      resolve(script)
    })

    Object.keys(attributes).forEach(attrKey => {
      script.setAttribute(attrKey, attributes[attrKey])
    })

    script.addEventListener('error', () => {
      // This handler has no information about why this request failed.
      // It could be our servers or the user's connection, so return a generic message
      reject(new Error('Script load error'))
    })

    document.body.appendChild(script)
  })
}

export default loadScript
