import React, {useEffect} from 'react'
import {BookingError} from 'hooks/useBookingError'

import {ContentWrapper} from '@daedalus/atlas/helpers/ContentWrapper'
import {Text} from '@daedalus/atlas/Text'
import {ErrorCategory} from '@daedalus/core/src/booking/types/ErrorCategory'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {useScrollTo} from '@daedalus/core/src/utils/dom/hooks/useScrollTo'
import {ErrorBanner} from '@findhotel/shared/src/booking/ErrorBannerRedesigned'

export const getIsInsufficientFunds = (error?: BookingError) => {
  return error?.data?.details?.category === ErrorCategory.InsufficientFunds
}

export const InsufficientFunds = React.memo(() => {
  const {ref, scroll} = useScrollTo(500, 100)

  useEffect(() => {
    scroll()
  }, [])

  return (
    <ContentWrapper paddingBottom="s600">
      <div ref={ref}>
        <ErrorBanner
          title={
            <FormattedMessageWrapper
              id="errors.insufficientFundsTitle"
              defaultMessage="Insufficient funds"
            />
          }
        >
          <Text variant="bodyS">
            <FormattedMessageWrapper
              id="errors.insufficientFundsDescription"
              defaultMessage="Try a different payment method or contact your bank"
            />
          </Text>
        </ErrorBanner>
      </div>
    </ContentWrapper>
  )
})

InsufficientFunds.displayName = 'InsufficientFunds'
