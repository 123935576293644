// Page paths
export const SRPPath = '/Hotel/Search'
export const HPPath = ''
export const RSPPath = '/room-selection'
export const PPPath = '/checkout'
export const CPPath = '/confirmation'
export const BMPath = 'bookings'
export const MBPath = '/my-bookings'
export const NPSPath = '/post-stay-nps'

// URL params
export const SIMULATE_PARAM = 'simulate'

//APP ENV
export const APP_ENV_PRODUCTION_LIST = ['prd', 'production']
