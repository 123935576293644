/**
 * Enumerable that holds the possible message types that can be sent to the React Native WebView
 */
export enum RNWebViewMessageTypes {
  SOCIAL_AUTH = 'SOCIAL_AUTH',
  /**
   * @deprecated in favor of USER_AUTHENTICATED. This should be kept here to support older versions of the app.
   */
  USER_ID = 'USER_ID',
  SIGN_OUT = 'SIGN_OUT',
  NAVIGATE = 'NAVIGATE',
  SET_CURRENCY = 'SET_CURRENCY',
  USER_AUTHENTICATED = 'USER_AUTHENTICATED',
  SHARE = 'SHARE',
  SET_USER_SEARCH_HISTORY = 'SET_USER_SEARCH_HISTORY',
  SHOW_IN_APP_REVIEW = 'SHOW_IN_APP_REVIEW',
  SET_USER_SEARCH_PARAMS = 'SET_USER_SEARCH_PARAMS',
  REQUEST_PRICE_WATCH_NOTIFICATION_PERMISSION = 'REQUEST_PRICE_WATCH_NOTIFICATION_PERMISSION',
  GET_PRICE_WATCH_PUSH_NOTIFICATION_STATUS = 'GET_PRICE_WATCH_PUSH_NOTIFICATION_STATUS'
}
