interface Params {
  hostUrl: string
  bookingId: string
  redirectId?: string
}
export const getDealFreezePaymentUrl = ({
  hostUrl,
  bookingId
}: Params): string => {
  const params = new URLSearchParams()
  params.append('bookingId', bookingId)
  return `${hostUrl}/checkout?${params.toString()}`
}
