import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {inverseGradient} from '@daedalus/atlas/src/utils/colors'

const OverlayRightShape = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 188 144">
      <path
        fill="#3E008A"
        d="M164.969 0c17.703 0 28.767 19.188 19.916 34.539l-53.309 92.443A33.989 33.989 0 0 1 102.135 144H20.027c-15.394 0-25.015-16.686-17.318-30.034l55.906-96.948A33.988 33.988 0 0 1 88.057 0h76.912Z"
      />
    </svg>
  )
}

const OverlayLeftShape = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 164 146">
      <path
        fill="#4E00AD"
        d="M144.368 0c15.09 0 24.522 16.2 16.976 29.16L98.98 136.277c-7.544 12.961-26.41 12.963-33.955.003L2.655 29.159C-4.89 16.2 4.543 0 19.633 0h124.736Z"
      />
    </svg>
  )
}

const Background = styled.div(
  ({theme}) => css`
    background: ${inverseGradient(theme)};
    height: 268px;
    position: absolute;
    width: 100%;
    overflow: hidden;
  `
)

const RightShapeWrapper = styled.div`
  position: absolute;
  top: 0;
  right: -90px;
  width: 146px;
`

const LeftShapeWrapper = styled.div`
  position: absolute;
  top: 96px;
  left: -108px;
  width: 150px;
`
export const GradientBackground = () => {
  return (
    <Background>
      <RightShapeWrapper>
        <OverlayRightShape />
      </RightShapeWrapper>
      <LeftShapeWrapper>
        <OverlayLeftShape />
      </LeftShapeWrapper>
    </Background>
  )
}
