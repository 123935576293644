import {useMemo} from 'react'

import {Page} from '@daedalus/core/src/analytics/types/Events'

import {ApplicationNames} from '../modules/slice'

const roomSelectionPath = '/room-selection/'
const paymentPagePath = '/checkout/'
export const useAnalyticsPage = (
  application: ApplicationNames,
  pathname: string,
  isAccommodationPage?: boolean
) => {
  return useMemo(() => {
    if (application === ApplicationNames.Search) {
      return isAccommodationPage ? Page.Accommodation : Page.Search
    }

    if (pathname.includes(roomSelectionPath)) return Page.RoomSelection
    if (pathname.includes(paymentPagePath)) return Page.Payment
  }, [application, isAccommodationPage, pathname])
}
