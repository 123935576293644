import {
  getChargeableCurrencyPrice,
  getHotelFeesOther,
  getHotelFeesResortFee,
  getHotelFeesTax,
  getNightlyDisplayTotalPrice,
  getTotalAtProperty,
  getTotalSaving,
  shouldAnchorPriceBeVisible,
  shouldComparisonOfferAnchorPriceBeVisible
} from 'business/price'
import {
  EventSpace,
  trackEventV1Type
} from 'modules/analytics/actions/trackEventV1'
import {getAnchorPriceNightlyTotal} from 'modules/raaOffers/selectors'
import {getHotelNightlyFees} from 'modules/rooms/selectors'
import {flatten, mergeAll, pickAll, pipe} from 'ramda'
import {
  CONFIRMATION_PAGE_PATH,
  PAYMENT_PAGE_PATH,
  ROOM_SELECTION_PAGE_PATH
} from 'routes'
import {
  ComparisonOfferContextType,
  OfferContextType,
  OffersListContextType,
  PriceContextType
} from 'types/trackingContext'
import renamePropName from 'utils/renamePropName'
import {getLastVisitedPage} from 'utils/updateHistoryLocation'

import {TrackEventProperties} from '@daedalus/core/src/analytics/types/Events'
import {BookingSources} from '@daedalus/core/src/booking/types/BookingData'
import {getCancellationDeadline} from '@daedalus/core/src/offer/business/cancellationPenalties'
import {
  calculateChargeDate,
  isEligibleForChargeLater
} from '@daedalus/core/src/offer/business/chargeLater'
import {getSavingPercentage} from '@daedalus/core/src/offer/business/price'
import {isPrivateDeal} from '@daedalus/core/src/offer/business/privateDeals'
import {Offer, OfferPrice} from '@daedalus/core/src/offer/types/offer'
import {
  RaaAnchorPriceBreakdownType,
  RaaOfferWithPrices
} from '@daedalus/core/src/offer/types/Raa'
import {
  getDisplayPrice,
  getTotalPrice,
  getTotalUpFrontPrice,
  isThereACurrencyConversion
} from '@daedalus/core/src/price/business/price'
import {Room} from '@daedalus/core/src/room/types/room'
import {RoomConfigurationType} from '@daedalus/core/src/room/types/RoomConfiguration'
import isOfferRefundable from '@daedalus/core/src/utils/refundableOffer'
import {UrlCheckoutParamsType} from '@daedalus/core/src/utils/url/types/UrlParams'

export interface GetOfferContextPropsType {
  roomName?: string
  offer?: Offer | RaaOfferWithPrices
  roomPosition?: number
  offerPosition?: number
  raaAnchorPriceBreakdown?: RaaAnchorPriceBreakdownType | OfferPrice | null
  roomId?: string
  cheapestOffer?: Offer | RaaOfferWithPrices
  numberOfImages?: number
  roomDescription?: string
  roomAmenities?: string[]
  squashedIds?: string[]
  shouldSeeOffersUnlocked?: boolean
  providerCode?: string
  isSplitBooking?: boolean
}

interface GetOffersListContextPropsType {
  rooms: Room[]
  raaAnchorPriceBreakdown?: RaaAnchorPriceBreakdownType
  shouldSeeOffersUnlocked: boolean
  splitBookingOffer?: Offer | null
}

interface GetComparisonOfferContextPropsType {
  offer: RaaOfferWithPrices
  offerPosition: number
  raaAnchorPriceBreakdown?: RaaAnchorPriceBreakdownType | null
}

interface GetComparisonOfferListContextPropsType {
  offers: RaaOfferWithPrices[]
  raaAnchorPriceBreakdown?: RaaAnchorPriceBreakdownType
}

interface GetPriceContextPropsType {
  roomId?: string
  offer?: Offer | RaaOfferWithPrices
  raaAnchorPriceBreakdown?: RaaAnchorPriceBreakdownType | OfferPrice | null
  roomsConfig: RoomConfigurationType
  cheapestOffer?: Offer | RaaOfferWithPrices
}

export interface TrackEventMetaParams {
  searchId?: string
}

export const getMetadata = (
  urlParams: UrlCheckoutParamsType | undefined,
  {searchId}: TrackEventMetaParams = {}
) => {
  // TODO: this should be id of the signed in user
  const userId = ''
  const viewPortHeight = window.innerHeight
  const viewPortWidth = window.innerWidth
  const lastPage = getLastVisitedPage()

  const urlParamsMetadata: Partial<UrlCheckoutParamsType> = pickAll(
    [
      'checkIn',
      'checkOut',
      'currency',
      'userIp',
      'anonymousId',
      'deviceType',
      'locale',
      'providerCode',
      'rooms',
      'hotelId',
      'userCountry',
      'providerRateType',
      'redirectId',
      'userAgent'
    ],
    urlParams
  )

  return {
    ...urlParamsMetadata,
    searchId: searchId || '',
    userId,
    viewPortHeight,
    viewPortWidth,
    lastPage
  }
}

export const getEventProperties = (
  event: trackEventV1Type,
  metaParams: TrackEventMetaParams = {}
) => {
  const {urlParams} = event

  const meta = getMetadata(
    urlParams,
    metaParams
  ) as unknown as TrackEventProperties
  const eventProps: trackEventV1Type = pickAll(
    ['category', 'space', 'action', 'label', 'value', 'context', 'payload'],
    event
  )

  return {
    ...eventProps,
    meta
  } as unknown as TrackEventProperties
}

export const getPageSpace = (pathname: string) => {
  // eslint-disable-next-line default-case
  switch (pathname) {
    case ROOM_SELECTION_PAGE_PATH: {
      return EventSpace.ROOM_SELECTION_PAGE
    }

    case PAYMENT_PAGE_PATH: {
      return EventSpace.PAYMENT_PAGE
    }

    case CONFIRMATION_PAGE_PATH: {
      return EventSpace.BOOKING_CONFIRMATION_PAGE
    }
  }
}

export const getOfferContext = ({
  roomName,
  offer,
  roomPosition,
  roomId,
  offerPosition,
  raaAnchorPriceBreakdown,
  cheapestOffer,
  numberOfImages,
  roomAmenities,
  roomDescription,
  squashedIds,
  shouldSeeOffersUnlocked
}: GetOfferContextPropsType): OfferContextType | null => {
  if (!offer) return null

  const {id, prices, providerCode, cancellationPenalties} = offer

  const {canPayLater} = offer

  const originalAccessTier = (offer as Offer)?.metadata?.originalAccessTier

  const {services, providerRateType} = offer as Offer

  const refundable = isOfferRefundable(cancellationPenalties)
  const isChargeLaterEligible = isEligibleForChargeLater(
    providerCode,
    cancellationPenalties
  )

  const chargeDate = calculateChargeDate(offer.cancellationPenalties)
  const chargeType = isChargeLaterEligible
    ? [BookingSources.ChargeLater]
    : [BookingSources.Default]

  const paymentExpiryAt = {
    ...(isChargeLaterEligible && {chargeExpiryAt: chargeDate?.toISOString()})
  }

  const offerParams = {
    offerId: id,
    refundable,
    services,
    payAtProperty: canPayLater,
    providerRateType,
    providerCode,
    chargeType,
    ...(isChargeLaterEligible && {paymentExpiryAt})
  }

  const privateDealStatus = isPrivateDeal(
    offer,
    Boolean(shouldSeeOffersUnlocked)
  )
  const displayPricePerRoomPerNight = getNightlyDisplayTotalPrice(prices)
  const price = getDisplayPrice(prices)

  const anchorPrice = raaAnchorPriceBreakdown
    ? getAnchorPriceNightlyTotal(raaAnchorPriceBreakdown)
    : null

  const cheapestOfferNightlyDisplayTotalPrice =
    cheapestOffer && getNightlyDisplayTotalPrice(cheapestOffer.prices)

  const isAnchorPriceShown = shouldAnchorPriceBeVisible(
    anchorPrice,
    displayPricePerRoomPerNight,
    cheapestOfferNightlyDisplayTotalPrice
  )

  const cancellationDeadline = getCancellationDeadline(
    offer?.cancellationPenalties
  )

  const otherParams = {
    roomName,
    isPrivateDeal: privateDealStatus,
    displayPricePerRoomPerNight,
    roomPosition,
    offerPosition,
    anchorPrice: isAnchorPriceShown ? anchorPrice : null,
    isAnchorPriceShown,
    cancellationDeadline,
    roomId,
    totalStayPrice: getTotalPrice(price),
    nightlyTaxes: price?.chargeable.nightlyTaxes,
    nightlyBasePrice: price?.chargeable.nightlyBase,
    nightlyLocalTaxes: getHotelNightlyFees(price),
    numberOfImages,
    roomAmenities,
    roomDescription,
    originalAccessTier,
    ...(squashedIds ? {squashedIds} : {})
  }

  return {
    ...offerParams,
    ...otherParams
  }
}

// This context should only be added to page impression events
// the data pipeline will assume that this list is the sorted list shown in the Room Selection Page
// and take the top most offer and treat it as the offer that was selected by the user in the Search Results Page
export const getOffersListContext = ({
  rooms,
  raaAnchorPriceBreakdown,
  shouldSeeOffersUnlocked
}: GetOffersListContextPropsType): OffersListContextType => {
  const nestedOffers = rooms.map((room, roomIndex) => {
    const roomName = room.name
    const roomId = room.id
    const roomMasterId = room.masterId

    const {offers} = room

    return offers?.map((offer, offerIndex) => {
      const {isClicked, matchType, matchedDim, matchedOfferPriceDiff} = offer

      return {
        // Apply roomMasterId specifically to OffersListContext so that analytics team can test room squashing experiment
        roomMasterId,
        isClicked,
        matchType,
        matchedDim,
        matchedOfferPriceDiff,
        ...getOfferContext({
          roomName,
          roomId,
          offer,
          roomPosition: roomIndex + 1,
          offerPosition: offerIndex + 1,
          raaAnchorPriceBreakdown,
          cheapestOffer: rooms[0].offers[0],
          numberOfImages: room.images?.length,
          roomDescription: room.description,
          roomAmenities: room.amenities,
          squashedIds: room.squashedIds,
          shouldSeeOffersUnlocked
        })
      }
    })
  })

  return flatten(nestedOffers)
}

export const getComparisonOfferContext = ({
  offer,
  offerPosition,
  raaAnchorPriceBreakdown
}: GetComparisonOfferContextPropsType): ComparisonOfferContextType => {
  const {id, roomName, prices} = offer
  const {canPayLater, hasFreeCancellation, meals, proxyProviderCode} = offer

  const offerParams = {
    offerId: id,
    refundable: hasFreeCancellation,
    services: meals,
    providerCode: proxyProviderCode,
    roomName,
    canPayLater
  }

  const displayPricePerRoomPerNight = getNightlyDisplayTotalPrice(prices)

  const price = getDisplayPrice(prices)
  const totalPrice = getTotalPrice(price)

  const anchorPrice = raaAnchorPriceBreakdown
    ? getAnchorPriceNightlyTotal(raaAnchorPriceBreakdown)
    : null

  const isAnchorPriceShown = shouldComparisonOfferAnchorPriceBeVisible(
    anchorPrice,
    displayPricePerRoomPerNight
  )

  const otherParams = {
    offerPosition,
    displayPricePerRoomPerNight,
    anchorPrice: isAnchorPriceShown ? anchorPrice : undefined,
    isAnchorPriceShown,
    totalStayPrice: totalPrice,
    nightlyTaxes: price?.chargeable.nightlyTaxes,
    nightlyBasePrice: price?.chargeable.nightlyBase,
    nightlyLocalTaxes: getHotelNightlyFees(price)
  }

  return {
    ...offerParams,
    ...otherParams
  }
}

export const getComparisonOffersListContext = ({
  offers,
  raaAnchorPriceBreakdown
}: GetComparisonOfferListContextPropsType) =>
  offers.map((offer, offerIndex) =>
    getComparisonOfferContext({
      offer,
      offerPosition: offerIndex + 1,
      raaAnchorPriceBreakdown
    })
  )

export const getPriceContext = ({
  offer,
  raaAnchorPriceBreakdown,
  roomsConfig,
  cheapestOffer
}: GetPriceContextPropsType): PriceContextType | null => {
  if (!offer) return null

  const offerParams = pipe<
    [Offer | RaaOfferWithPrices],
    Pick<Offer, 'id'>,
    {offerId: Offer['id']}
  >(
    pickAll(['id']),
    renamePropName<{id: string}, 'id', 'offerId'>('id', 'offerId')
  )(offer)

  const {numberOfRooms} = roomsConfig

  const price = getDisplayPrice(offer.prices)
  if (!price) return null

  const {canPayLater} = offer

  const currency = price.currencyCode
  const basePricePerRoomPerNight = price.chargeable.base
  const taxesPerRoomPerNight = price.chargeable.taxes
  const displayPricePerRoomPerNight = getNightlyDisplayTotalPrice(offer.prices)
  const totalUpFront = getTotalUpFrontPrice(price, canPayLater)
  const totalPrice = getTotalPrice(price)

  const resortFeeDueAtProperty = getHotelFeesResortFee(price)
  const taxesDueAtProperty = getHotelFeesTax(price)
  const otherDueAtProperty = getHotelFeesOther(price)
  const totalDueAtProperty = getTotalAtProperty(price, canPayLater)

  const hasCurrencyConversion = isThereACurrencyConversion(offer.prices)
  const chargeableCurrencyPrice = getChargeableCurrencyPrice(offer.prices)
  const chargeableCurrency = hasCurrencyConversion
    ? chargeableCurrencyPrice.currencyCode
    : null
  const chargeableTotalPrice = hasCurrencyConversion
    ? getTotalPrice(chargeableCurrencyPrice)
    : null

  const priceParams = {
    currency,
    basePricePerRoomPerNight,
    taxesPerRoomPerNight,
    displayPricePerRoomPerNight,
    totalUpFront,
    totalPrice,
    resortFeeDueAtProperty,
    taxesDueAtProperty,
    otherDueAtProperty,
    totalDueAtProperty,
    chargeableCurrency,
    chargeableTotalPrice
  }

  const anchorPrice = raaAnchorPriceBreakdown
    ? getAnchorPriceNightlyTotal(raaAnchorPriceBreakdown)
    : null

  const cheapestOfferNightlyDisplayTotalPrice =
    cheapestOffer && getNightlyDisplayTotalPrice(cheapestOffer.prices)

  const isAnchorPriceShown = shouldAnchorPriceBeVisible(
    anchorPrice,
    displayPricePerRoomPerNight,
    cheapestOfferNightlyDisplayTotalPrice
  )

  const savingPercentage = isAnchorPriceShown
    ? getSavingPercentage(Number(anchorPrice), displayPricePerRoomPerNight)
    : null

  const savingTotal = isAnchorPriceShown
    ? getTotalSaving(raaAnchorPriceBreakdown, price, numberOfRooms)
    : null

  const anchorPriceParams = {
    anchorPrice: isAnchorPriceShown ? anchorPrice : null,
    isAnchorPriceShown,
    savingPercentage,
    savingTotal
  }

  return mergeAll([
    offerParams,
    priceParams,
    anchorPriceParams
  ]) as PriceContextType
}
