import {useLocation} from 'react-router'
import {assocPath, Path} from 'ramda'

import {useRouter} from '../RouterContext'

export const useUpdateLocationState = () => {
  const location = useLocation<object>()
  const router = useRouter()

  return (path: Path, newState: Record<string, any>) => {
    const newLocation = assocPath(path, newState, location)
    router.push(newLocation)
  }
}
