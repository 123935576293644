import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {matchPath, useLocation} from 'react-router-dom'

import {getUrlParams} from '@daedalus/core/src/utils/url'
import {ProfileKey} from '@findhotel/sapi'

import {ExternalActions} from '../../context/ExternalActionsContext'
import {useAnalyticsPage} from '../../hooks/useAnalyticsPage'
import {ApplicationNames, setApplication} from '../../modules/slice'

export interface OverlayStateType {
  type: string
  extraState?: Record<string, unknown>
}
interface LocationState {
  overlay?: OverlayStateType[]
}
interface Props {
  appCurrency: string | undefined
  sapiProfileKey: ProfileKey
  application: ApplicationNames
  appVersion: string
  boVioEndpoint: string
  boVioProfile: string
  checkoutUrl: string
  bookingManagementUrl: string
  languageCode: string
  actions: ExternalActions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getSearchId: (state: any) => string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getCountryCode: (state: any) => string | null
}

/**
 * DealFreeze can run on different applications (Checkout, Search), and it is located
 * outside of those packages. Therefore, we needed a straightforward way to access
 * certain properties of the application that DealFreeze is running in.
 * These properties include which page it is (Search, Accommodation, RoomSelection, etc.), the language code, the version of the app, and more.
 *
 * By storing these properties in Redux, we can access them without being coupled to each application's internal functionalities, and can also improve the debugging process.
 * This component is separated from dealFreeze/index to avoid re-rendering caused by the use of useLocation.
 */
export const UpdateApplication = ({
  appCurrency,
  application,
  appVersion,
  checkoutUrl,
  bookingManagementUrl,
  languageCode,
  getSearchId,
  getCountryCode
}: Props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const {pathname, search} = location
  const locationOverlayState = location.state as LocationState
  const isAccommodationPageMobile = locationOverlayState?.overlay?.some(
    (overlay: OverlayStateType) => overlay.type === 'hotelDetails'
  )
  const isAccommodationPageDesktop = matchPath(location.pathname, {
    path: '/Hotel/:hotelSlug(\\d+)'
  })
  const isAccommodationPage =
    isAccommodationPageMobile || Boolean(isAccommodationPageDesktop)

  const [currency, setCurrency] = useState<string | undefined>(appCurrency)

  useEffect(() => {
    if (currency) return
    const {currency: urlCurrency} = getUrlParams()
    setCurrency(urlCurrency as string)
  }, [currency, search])

  const countryCode = useSelector(getCountryCode)
  const searchId = useSelector(getSearchId)
  const page = useAnalyticsPage(application, pathname, isAccommodationPage)

  useEffect(() => {
    dispatch(
      setApplication({
        name: application,
        version: appVersion,
        countryCode,
        searchId,
        page,
        currency,
        languageCode,
        checkoutUrl,
        bookingManagementUrl
      })
    )
  }, [
    dispatch,
    searchId,
    countryCode,
    application,
    appVersion,
    currency,
    page,
    languageCode,
    checkoutUrl,
    bookingManagementUrl
  ])

  return null
}
