import React, {ComponentProps, ReactElement} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {
  BottomSheet as AtlasBottomSheet,
  BottomSheetHeader
} from '@daedalus/atlas/BottomSheet'
import {HeaderWrapper, SubHeader} from '@daedalus/atlas/BottomSheet/styles'

type AtlasBottomSheetProps = ComponentProps<typeof AtlasBottomSheet>

interface Props extends Omit<AtlasBottomSheetProps, 'titleBarProps'> {
  iconComponent?: ReactElement | null
}

const HeaderIcon = styled.div(
  ({theme}) => css`
    position: absolute;
    width: 64px;
    height: 64px;
    left: 50%;
    bottom: -${theme.layout.spacing.s700}px;
    transform: translateX(-50%);
  `
)

const StyledBottomSheet = styled(AtlasBottomSheet)(
  ({theme}) => css`
    z-index: 101 !important;
    .react-modal-sheet-container {
      overflow: hidden;
    }
    ${BottomSheetHeader} {
      position: relative;
      margin-bottom: ${theme.layout.spacing.s500}px;
    }
    ${HeaderWrapper} {
      height: 100%;
      padding: 0;
    }
    ${SubHeader} {
      padding: 0;
    }
  `
)

export const BrandBottomSheet = ({
  iconComponent,
  children,
  ...props
}: Props) => {
  return (
    <StyledBottomSheet
      {...props}
      titleBarProps={{
        centerContent: <HeaderIcon>{iconComponent}</HeaderIcon>,
        hasDivider: false
      }}
    >
      {children}
    </StyledBottomSheet>
  )
}
