import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {getDataFile} from '@daedalus/core/src/experiments/modules/selectors'
import {collectSapiVariationsParam} from '@daedalus/core/src/experiments/sapi'
import {getCountryCode} from '@daedalus/core/src/localization/services/country'
import {getLanguageCode} from '@daedalus/core/src/localization/services/locale'
import {getAnonymousId} from '@daedalus/core/src/utils/anonymousId'
import {ProfileKey, sapi, SapiClient} from '@findhotel/sapi'

interface SapiClientOptions {
  sapiProfileKey: ProfileKey
  currency?: string | null
}

export const useSapiClient = ({
  sapiProfileKey,
  currency
}: SapiClientOptions) => {
  const [sapiClient, setSapiClient] = useState<SapiClient | null>(null)
  const language = useSelector(getLanguageCode)
  const countryCode = useSelector(getCountryCode)
  const dataFile = useSelector(getDataFile)
  const {isMobile} = useDeviceLayout()

  useEffect(() => {
    if (sapiClient || !currency) return

    async function createSapiClient() {
      try {
        const anonymousId = getAnonymousId() as string
        const variations = collectSapiVariationsParam(
          dataFile?.featureFlags.map(({key}) => key)
        )

        const client = await sapi(sapiProfileKey, {
          language,
          currency: currency as string,
          countryCode: countryCode,
          deviceType: isMobile ? 'mobile' : 'desktop',
          anonymousId,
          variations
        })
        setSapiClient(client)
      } catch (e) {
        console.log('Error initializing SAPI client', e)
        setSapiClient(null)
      }
    }

    createSapiClient()
  }, [
    countryCode,
    language,
    currency,
    sapiClient,
    sapiProfileKey,
    dataFile?.featureFlags,
    isMobile
  ])

  return sapiClient
}
