import React, {CSSProperties, ReactNode} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {ThemeType} from 'types/Theme'

import {
  ControlType,
  ControlWithCheckmark
} from '../helpers/ControlWithCheckmark'
import {Grid} from '../helpers/Grid'
import {Tag} from '../Tag'
import {Text} from '../Text'

export type Size = 'md' | 'lg'

const BOX_SHADOW_WIDTH = 1

interface Props {
  selected: boolean
  dataId?: string
  name: string
  onChange: () => void
  value?: string
  size?: Size
  children: ReactNode
  labelText?: string
  withControlButton?: boolean
  withBorder?: boolean
  alignItems?: CSSProperties['alignItems']
  disabled?: boolean
  className?: string
  type: ControlType
}

const Wrapper = styled('label')<{
  selected: boolean
  disabled: boolean
  size: Size
  withBorder: boolean
}>(({theme, selected, size, disabled, withBorder}) => {
  const activeStyles = css`
    border: 1px solid transparent;
    ${withBorder &&
    css`
      border-color: ${theme.colors.selectionCard.selected.border};
      box-shadow: 0px 0px 0px ${BOX_SHADOW_WIDTH}px
        ${theme.colors.border.interactive.c500};
      background-color: ${theme.colors.selectionCard.selected.background};
    `}
  `

  const disabledStyles = css`
    border: 1px solid transparent;
    ${withBorder &&
    css`
      border-color: ${theme.colors.selectionCard.disabled.border};
      box-shadow: 0px 0px 0px ${BOX_SHADOW_WIDTH}px
        ${theme.colors.selectionCard.disabled.shadow};
    `}
    background-color: ${theme.colors.selectionCard.disabled.background};
    color: ${theme.colors.selectionCard.disabled.description};
  `

  const defaultStyles = css`
    border: 1px solid transparent;
    ${withBorder &&
    css`
      border-color: ${theme.colors.selectionCard.default.border};
      box-shadow: 0px 0px 0px ${BOX_SHADOW_WIDTH}px
        ${theme.colors.border.neutral.c050};
    `}
    background-color: ${theme.colors.selectionCard.default.shadow};
    color: ${theme.colors.selectionCard.default.description};
  `

  const hoverStyles = css`
    border: 1px solid transparent;
    ${withBorder &&
    css`
      border-color: ${theme.colors.selectionCard.hover.border};
      box-shadow: 0px 0px 0px ${BOX_SHADOW_WIDTH}px
        ${theme.colors.selectionCard.hover.shadow};
    `}
    background-color: ${theme.colors.selectionCard.hover.background};
  `

  return css`
    width: 100%;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
    margin: ${BOX_SHADOW_WIDTH}px; // prevents the box-shadow from being cut off
    gap: ${theme.layout.spacing.s300}px;
    ${disabled ? disabledStyles : defaultStyles}

    ${size === 'md'
      ? `padding: ${theme.layout.spacing.s300}px ${theme.layout.spacing.s400}px;`
      : `padding: ${theme.layout.spacing.s500}px;`}

    border-radius: ${theme.layout.radius.lg}px;
    cursor: pointer;

    &:hover {
      ${selected ? activeStyles : hoverStyles}
      ${disabled && disabledStyles}
    }

    &:active,
    &:focus:not[${disabled}] {
      ${activeStyles}
    }

    ${selected && activeStyles}

    ${selected && disabled && disabledStyles}
  `
})

const getLabelColor = (
  theme: ThemeType,
  selected: boolean,
  disabled: boolean
) => {
  if (selected && !disabled) {
    return css`
      background-color: ${theme.colors.background.interactive.c500};
      color: ${theme.colors.content.neutral.c000};
    `
  } else if (disabled) {
    return css`
      background-color: ${theme.colors.background.neutral.c100};
      color: ${theme.colors.content.neutral.c800};
    `
  }
  return css`
    background-color: ${theme.colors.background.success.c100};
    color: ${theme.colors.content.success.c900};
  `
}

const LabelText = styled(Tag)<{
  selected: boolean
  disabled: boolean
}>(
  ({theme, selected, disabled}) => css`
    position: absolute;
    top: -${theme.layout.spacing.s400}px;
    left: ${theme.layout.spacing.s400}px;
    border-radius: ${theme.layout.radius.lg}px;
    ${getLabelColor(theme, selected, disabled)}
  `
)

const ControlButtonWrapper = styled('div')<{
  selected: boolean
  type: ControlType
}>(
  ({selected, type}) => css`
    visibility: ${selected || type === 'checkbox' ? 'visible' : 'hidden'};
  `
)

export const SelectionCard = ({
  selected,
  dataId = 'SelectionCard',
  name,
  onChange,
  value,
  size = 'lg',
  children,
  labelText,
  withControlButton = true,
  withBorder = true,
  disabled = false,
  className,
  alignItems = 'flex-start',
  type = 'radio'
}: Props) => {
  return (
    <Wrapper
      withBorder={withBorder}
      selected={selected}
      size={size}
      disabled={disabled}
      className={className}
      onClick={withControlButton ? undefined : onChange}
      data-id={dataId}
    >
      {labelText && (
        <LabelText selected={selected} disabled={disabled}>
          <Text variant="labelXS">{labelText}</Text>
        </LabelText>
      )}

      <Grid
        container
        spacing="s000"
        direction="row"
        justify="space-between"
        alignItems={alignItems}
        gap="s300"
        wrap="nowrap"
      >
        {children}
        {withControlButton && (
          <ControlButtonWrapper selected={selected} type={type}>
            <ControlWithCheckmark
              type={type}
              name={name}
              checked={selected}
              onChange={onChange}
              value={value}
              disabled={disabled}
            />
          </ControlButtonWrapper>
        )}
      </Grid>
    </Wrapper>
  )
}
