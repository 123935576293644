import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {GenericErrorImage} from '../../../foundation/illustrations/GenericErrorImage'
import {mq} from '../../../utils/breakpoints'
import {Grid} from '../helpers/Grid'

const APPROXIMATE_HEADER_FOOTER_HEIGHT = 300
const IMAGE_CONTAINER_HEIGHT_MOBILE = 234
const IMAGE_CONTAINER_HEIGHT_DESKTOP = 351

interface Props {
  /** The body of the content */
  children: React.ReactChild | React.ReactChild[]
  /** Pass through classname to allow styles overrides */
  className?: string
  /** The combined height in pixels of the header and footer components. This is used to calculate the min-height of the error message. */
  headerFooterHeight?: number
  /** Whether the component should show the Illustration or not  */
  withIllustration?: boolean
}

interface StyleProps {
  headerFooterHeight?: number
}

const FlexItemsContainer = styled(Grid)<StyleProps>(
  ({headerFooterHeight}) => css`
    min-height: calc(100vh - ${headerFooterHeight}px);
    text-align: center;
  `
)

export const ImageContainer = styled('div')`
  height: ${IMAGE_CONTAINER_HEIGHT_MOBILE}px;
  ${mq.desktopXs(`
    height: ${IMAGE_CONTAINER_HEIGHT_DESKTOP}px;
  `)}
`

export const GenericError = ({
  children,
  headerFooterHeight = APPROXIMATE_HEADER_FOOTER_HEIGHT,
  withIllustration = false,
  className
}: Props) => (
  <FlexItemsContainer
    container
    direction="column"
    alignItems="center"
    spacing="s000"
    headerFooterHeight={headerFooterHeight}
    className={className}
  >
    {children}
    {withIllustration && (
      <ImageContainer>
        <GenericErrorImage />
      </ImageContainer>
    )}
  </FlexItemsContainer>
)
