import {last} from 'ramda'

import {UserTier} from '../../../offer/business/privateDeals'
import {getCookie, removeCookie, setCookie} from '../../../utils/cookies'
import {getDomainName} from '../../../utils/url'
import {getCognitoUserTier} from '../../modules/helpers'
import {StateType} from '../../modules/slice'

const SCOPE = 'auth_user_'

/**
 * Generate user cookie config
 * @returns object containing necessary cookie options
 */
export const makeCookieConfiguration = () => ({
  domain: getDomainName(window.location.hostname),
  secure: true
})

/**
 * Cookies config specific for the user
 */
export const USER_COOKIES = {
  tier: `${SCOPE}tier`,
  loginPath: `${SCOPE}login_path`,
  cognitoTier: `${SCOPE}cognito_tier`,
  googleOneTap: `${SCOPE}used_one_tap`
}

/**
 * Set the Cognito user tier to cookie
 * @param user - CognitoUser
 */
export const persistCognitoUserTierOnCookie = (user?: StateType['user']) => {
  if (!user) return

  const cookieConfiguration = makeCookieConfiguration()

  setCookie(
    USER_COOKIES.cognitoTier,
    JSON.stringify(getCognitoUserTier(user)),
    cookieConfiguration
  )
}

/**
 * Get the Cognito user tier from cookie
 */
export const getCognitoUserTierFromCookie = (): UserTier[] => {
  try {
    const tier = getCookie(USER_COOKIES.cognitoTier)
    return (tier && JSON.parse(tier)) || []
  } catch {
    return []
  }
}

export const removeCognitoUserTierFromCookie = () => {
  const cookieConfiguration = makeCookieConfiguration()
  removeCookie(USER_COOKIES.cognitoTier, cookieConfiguration)
}

/**
 * Save the last login url, usually from Cognito
 * @param searchURI - url to be saved
 */
export const setLastLoginUrl = (searchURI: string) => {
  const cookieConfiguration = makeCookieConfiguration()
  setCookie(USER_COOKIES.loginPath, searchURI, cookieConfiguration)
}

/**
 * Get the last login url
 * @returns user last login url
 */
export const getLastLoginUrl = () => getCookie(USER_COOKIES.loginPath)

/**
 * Remove the last login url
 */
export const removeLastLoginUrl = () => {
  const cookieConfiguration = makeCookieConfiguration()
  removeCookie(USER_COOKIES.loginPath, cookieConfiguration)
}

/**
 * Save the Google One Tap signed in status
 */
export const setGoogleOneTapSignedIn = () => {
  const cookieConfiguration = makeCookieConfiguration()
  setCookie(USER_COOKIES.googleOneTap, String(1), cookieConfiguration)
}

/**
 * Get the Google One Tap signed in status
 * @returns "1" if the user used Google One Tap to sign in
 */
export const getGoogleOneTapSignedIn = () =>
  getCookie(USER_COOKIES.googleOneTap)

/**
 * Remove the Google One Tap signed in status
 */
export const removeGoogleOneTapSignedIn = () => {
  const cookieConfiguration = makeCookieConfiguration()
  removeCookie(USER_COOKIES.googleOneTap, cookieConfiguration)
}

export const removeUnnecessaryCookies = () => {
  const cookies = getCookie()
  const cognitoCookiePrefix = 'CognitoIdentityServiceProvider'
  const cookiesToRemove = ['userData', 'clockDrift']
  for (const [key] of Object.entries(cookies)) {
    if (key.includes(cognitoCookiePrefix)) {
      const cookieName = last(key.split('.'))
      if (cookieName && cookiesToRemove.includes(cookieName)) {
        removeCookie(key, {
          path: '/',
          domain: getDomainName(location.hostname),
          secure: true,
          sameSite: 'strict'
        })
        console.log('cognito: removed cookie', key)
      }
    }
  }
}
