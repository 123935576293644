import 'react-loading-skeleton/dist/skeleton.css'

import React from 'react'
import Skeleton from 'react-loading-skeleton'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

export type AlignType = 'left' | 'right'

type SkeletonLoaderPropsType = {
  /** Which direction to align the skeleton */
  align?: AlignType
  /** The height of the skeleton. If not provided, it defaults to 100% */
  height?: number
  /** The width of the skeleton. If not provided, it defaults to 100% */
  width?: number
  /** Whether to make the skeleton a circle, defaults to false */
  circle?: boolean // eslint-disable-line react/boolean-prop-naming
  /** The margin below the skeleton */
  marginBottom?: number
  /** The margin above the skeleton. If not provided it defaults to 4 for every skeleton after the first */
  marginTop?: number
  /** The className above the skeleton. If not provided it defaults to undefined, This allows styling with styled for each SkeletonLoader  */
  className?: string
}

interface WrapperProps {
  align?: AlignType
  height?: number
  marginBottom?: number
  marginTop?: number
}

const SkeletonWrapper = styled('div')<WrapperProps>(
  ({marginTop, marginBottom, height, align}) => css`
    margin-top: ${marginTop ?? 0}px;
    ${!!marginBottom && `margin-bottom: ${marginBottom}px;`}
    height: ${height ? `${height}px` : '100%'};
    position: relative;

    * {
      display: flex;
      height: ${height ? `${height}px` : '100%'};
      flex-direction: ${align === 'right' ? 'row-reverse' : 'row'};
    }

    :not(:first-of-type) {
      margin-top: ${marginTop ?? 4}px;
    }
  `
)

export const SkeletonLoader = ({
  marginBottom,
  marginTop,
  align = 'left',
  circle = false,
  height,
  width,
  className
}: SkeletonLoaderPropsType) => (
  <SkeletonWrapper
    data-chromatic="ignore"
    className={className}
    marginTop={marginTop}
    marginBottom={marginBottom}
    align={align}
    height={height}
  >
    <Skeleton height={height} width={width} circle={circle} />
  </SkeletonWrapper>
)

/**
 * Direct export of react-loading-skeleton - the implementation above breaks some of its features
 * Exported alongside to help clean this up later
 * https://app.shortcut.com/findhotel/story/23030/combine-loadingindicator-and-skeletonloader-component
 */
export {default as RawSkeletonLoader} from 'react-loading-skeleton'
