import {createSelector} from '@reduxjs/toolkit'

import {Page} from '@daedalus/core/src/analytics/types/Events'
import {BookingData} from '@daedalus/core/src/booking/types/BookingData'

import {RootState} from '../../type'
import {DealFreezeAnalyticsContext, SearchDetail, State} from './slice'

export const getIsFreezeOverlayOpen = createSelector(
  [(state: RootState) => state.dealFreeze.isOverlayOpen],
  isOverlayOpen => isOverlayOpen
)

export const getIsFreezeConfirmationOverlayOpen = (state: RootState) =>
  state.dealFreeze.isConfirmationOverlayOpen

export const getIsUserEligibleForDealFreeze = (state: RootState) =>
  state.dealFreeze.isUserEligible

export const getFreezeSelectedContext = createSelector(
  [(state: RootState) => state.dealFreeze.data],
  ({selectedOffer, selectedHotel, searchDetail, rooms, sapiMatchedOffer}) => {
    return {
      offer: selectedOffer,
      hotel: selectedHotel,
      searchDetail: searchDetail as SearchDetail,
      rooms,
      sapiMatchedOffer
    }
  }
)

export const getOfferEligibility = (state: RootState) => {
  return (state.dealFreeze.offerCheck || {}) as State['offerCheck']
}

export const getIsDealFreezeEnabled = (state: RootState): boolean => {
  return state.dealFreeze.isDealFreezeEnabled || false
}

export const getBookingStatus = (state: RootState) => {
  return (state.dealFreeze.bookingStatus || {}) as State['bookingStatus']
}

export const getAnalyticsContext = (state: RootState) => {
  return (state.dealFreeze.analyticsContext || {}) as DealFreezeAnalyticsContext
}

export const getAnalyticsLeadContext = createSelector(
  [(state: RootState) => state.dealFreeze.analyticsContext?.leadContext],
  leadContext => leadContext
)

export const getAnalyticsOfferContext = createSelector(
  [(state: RootState) => state.dealFreeze.analyticsContext?.offerContext],
  offerContext => offerContext
)

export const getAnalyticsPriceContext = createSelector(
  [(state: RootState) => state.dealFreeze.analyticsContext?.priceContext],
  priceContext => priceContext
)

export const getApiContext = (state: RootState) => {
  return state.dealFreeze.api
}

export const getError = createSelector(
  [(state: RootState) => state.dealFreeze.error],
  error => error
)

/**
 * Since Deal Freeze is located outside the `client` and `checkout` packages,
 * it doesn't have direct access to their respective values.
 * `application` on Redux provides essential metadata like the version of the `client` or `checkout`,
 * searchId, page, countryCode, and language when Deal Freeze is in use.
 * Centralizing this information aids in debugging and ensures efficient access to these values when required.
 *
 * @param state - RootState
 */
export const getApplication = createSelector(
  [(state: RootState) => state.dealFreeze.application],
  application => {
    return {
      ...application,
      page: application.page as Page
    }
  }
)
export const getComponent = (state: RootState) =>
  state.dealFreeze.data.component

export const getIsOfferViewTracked = (
  state: RootState,
  offerId: string,
  page: string
) => {
  const {trackedOffers} = state.dealFreeze.analytics
  const trackId = `${page}_${offerId}`
  return trackedOffers.includes(trackId)
}

export const getFrozenBookingByHotelId = (
  state: RootState,
  hotelId?: string
) => {
  const {frozenBookings} = state.dealFreeze
  if (!frozenBookings || !hotelId) return undefined
  return frozenBookings.find(booking => booking.hotelId === hotelId) as
    | BookingData
    | undefined
}

export const getFrozenBookingByRoomId = (state: RootState, roomId?: string) => {
  const {frozenBookings} = state.dealFreeze
  if (!frozenBookings || !roomId) return undefined
  return frozenBookings.find(booking => {
    return booking.rooms.some(room => room.id === roomId)
  }) as BookingData | undefined
}

export const getOfferEncounteredError = (state: RootState, offerId?: string) =>
  offerId ? state.dealFreeze.offersWithError?.includes(offerId) : false
