import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Text} from '@daedalus/atlas/Text'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import vioGif from '../../../authentication/CognitoPasswordlessLoader/vio.gif'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 60vh;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 96px;
`

const LoadingImage = styled.img(
  ({theme}) => css`
    width: 88px;
    margin-bottom: ${theme.layout.spacing.s300}px;
  `
)

export const LoadingIndicator = () => {
  return (
    <Wrapper>
      <Content>
        <LoadingImage src={vioGif} alt="loading..." />
        <Text variant="titleL">
          <FormattedMessageWrapper
            id={'df.loadingMessageFreezingDeal'}
            defaultMessage={'Freezing price...'}
          />
        </Text>
      </Content>
    </Wrapper>
  )
}
