import compose from 'lodash/fp/compose'
import entries from 'lodash/fp/entries'
import isNil from 'lodash/fp/isNil'
import map from 'lodash/fp/map'
import omitBy from 'lodash/fp/omitBy'
import qs from 'qs'

import {DeepLinkCustomSearchParams} from './types'

export const META_PARAM_SEPARATOR = '|'

const stringifyAndEncode = compose(encodeURIComponent, qs.stringify)

/**
 * Generates the custom params for the deep link.
 *
 * Be mindful about how many parameters you are adding. As the final AppsFlyer link should not be bigger than 2k characters.
 *
 * @param searchParams - DeepLinkCustomSearchParams
 * @returns array of objects containing the custom params
 */
export const generateSearchParams = (
  searchParams: DeepLinkCustomSearchParams
) => {
  const customSearchParams = {
    deep_link_sub9: stringifyAndEncode(searchParams)
  }

  const appsFlyerParam = ([key, value]: [
    string,
    string | number | undefined
  ]) => ({
    paramKey: key,
    keys: [],
    defaultValue: [value]
  })
  const applyAppsFlyerFormat = map(appsFlyerParam)
  const removeEmptyProps = omitBy(isNil)
  const transformSearchParams = compose(
    applyAppsFlyerFormat,
    entries,
    removeEmptyProps
  )

  return transformSearchParams(customSearchParams)
}
