import React, {useCallback, useEffect} from 'react'
import {useDispatch} from 'react-redux'

import {useDispatchTeamEvent} from '@daedalus/core/src/analytics/hooks/useDispatchTeamEvent'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {isReactNativeWebView} from '@daedalus/core/src/native'
import {getCookie} from '@daedalus/core/src/utils/cookies'
import {useIsGDPRCountry} from '@daedalus/core/src/utils/cookies/hooks/cookieComplianceGeolocation'

import {CookieProBannerContainer} from './CookieProBannerContainer'

/**
 * Custom implementation of a GDPR compliant cookie banner. It makes use
 * of the OneTrust SDK, so make sure the SDK scripts are correctly loaded
 * in the HTML template.
 */
export interface CookieProBannerProps {
  /**
   * Callback invoked when a user accepts the cookies, either in the
   * banner or the preference center
   */
  onAccept?: (acceptedByUser: boolean) => void
}

const shouldCookieBannerBeShown = (): boolean => {
  // if `OptanonAlertBoxClosed` cookie exists then they have interacted with the
  // banner or the cookie is expired and reconsent will occur
  const userHasSavedCookiePreferences = getCookie('OptanonAlertBoxClosed')
  // if CookiePro script fails for some reason (window.OneTrust === undefined) don't show the banner
  const shouldShowBanner = Boolean(
    !userHasSavedCookiePreferences && window.OneTrust
  )

  return shouldShowBanner
}

export const CookieProBanner = ({onAccept}: CookieProBannerProps) => {
  const isGDPRCountry = useIsGDPRCountry()
  const dispatch = useDispatch()
  const dispatchTeamEvent = useDispatchTeamEvent()

  const handleCookieProScriptLoaded = useCallback(() => {
    dispatchTeamEvent(
      trackEvent({
        category: Category.System,
        entity: Entity.CookieBannerScript,
        action: Action.Loaded
      })
    )
  }, [dispatch])

  useEffect(() => {
    if (window.COOKIE_PRO_LOADED) {
      handleCookieProScriptLoaded()
      return
    }

    // sometimes window.OneTrust is undefined as the script takes a while to load
    // so we should listen for the event here too
    window.addEventListener('cookieProLoaded', handleCookieProScriptLoaded)

    return () => {
      window.removeEventListener('cookieProLoaded', handleCookieProScriptLoaded)
    }
  }, [handleCookieProScriptLoaded])

  if (
    isReactNativeWebView() ||
    !isGDPRCountry ||
    !shouldCookieBannerBeShown()
  ) {
    return null
  }

  return <CookieProBannerContainer onAccept={onAccept} />
}
