import {toggle} from 'opticks'

/**
 * Check if the application has a native mobile bottom bar.
 *
 * @returns boolean Return true if the native mobile bottom bar is enabled, otherwise false.
 */
const hasNativeMobileBottomBar = () =>
  toggle('7a47a8fd-enable-bottom-navigation', false, true)

const hasWishlistOnNativeBottomBar = () =>
  toggle('a87807d8-price-watch-toggle-holdout', true, false)

export {hasNativeMobileBottomBar, hasWishlistOnNativeBottomBar}
