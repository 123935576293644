import {Metric, onINP} from 'web-vitals'

import {PerformanceMetric, trackPerformance} from './logPerformanceMetrics'

const reportFormInputFieldsINP =
  (onPerfEntry: typeof trackPerformance) => (metric: Metric) => {
    const allowedFields = new Set([
      'rooms.0.firstName',
      'rooms.0.lastName',
      'rooms.0.email',
      'rooms.0.tel-country-code',
      'rooms.0.phone.number',
      'payment.creditCard.rawExpirationDate',
      'payment.creditCard.cardholdersName',
      'payment.billing.address.line1',
      'payment.billing.address.line2',
      'payment.billing.address.postalCode',
      'payment.billing.address.city',
      'payment.billing.address.countryCode'
    ])

    const allowedEvents = new Set(['keydown', 'pointerdown', 'click'])

    for (const entry of metric?.entries) {
      const fieldName =
        (entry as PerformanceEntry & {target?: {name?: string}})?.target
          ?.name || ''
      const eventType = entry?.name
      if (allowedFields.has(fieldName) && allowedEvents.has(eventType)) {
        const dataToTrack = {
          id: metric?.id,
          name: metric?.name,
          value: metric?.value,
          meta: {
            field: fieldName,
            eventType: entry?.name,
            rating: metric?.rating
          }
        }

        onPerfEntry(dataToTrack as Metric & PerformanceMetric)
      }
    }
  }

const reportWebVitals = (
  onPerfEntry?: (metric: Metric & PerformanceMetric) => void
) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    onINP(reportFormInputFieldsINP(onPerfEntry), {reportAllChanges: true})
  }
}

export default reportWebVitals
