import differenceInDays from 'date-fns/differenceInDays'
import startOfDay from 'date-fns/startOfDay'
export const getDayDistance = (
  start_date: string | Date,
  end_date: string | Date,
  includeTime = false
) => {
  const startDate = includeTime
    ? new Date(start_date)
    : startOfDay(new Date(start_date))
  const endDate = includeTime
    ? new Date(end_date)
    : startOfDay(new Date(end_date))

  return Math.abs(differenceInDays(startDate, endDate))
}
