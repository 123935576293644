export enum ErrorCategory {
  /**
   * The card the customer tried using is not accepted
   */
  CardNotSupported = 'card_not_supported',
  /**
   * A CC payment error that is not yet mapped happens
   */
  GenericCardError = 'generic_card_error',
  /**
   * Customer attempt of purchase flagged as possible fraud
   */
  GenericFraudDeclined = 'generic_fraud_declined',
  /**
   * A payment attempt that is not yet mapped as an specific error
   */
  GenericPaymentDeclined = 'generic_payment_declined',
  /**
   * Customer provided an incorrect CVC
   */
  IncorrectCvc = 'incorrect_cvc',
  /**
   * Customer provided an incorrect CC number
   */
  IncorrectNumber = 'incorrect_number',
  /**
   * Customer's payment method has no funds
   */
  InsufficientFunds = 'insufficient_funds',
  /**
   * Customer supplied an invalid email
   */
  InvalidEmail = 'invalid_email',
  /**
   * The booking process was not finished
   */
  BookingIncomplete = 'booking_incomplete',
  /**
   * The booking could not be cancelled
   */
  BookingNotCancelled = 'booking_not_cancelled',
  /**
   * The reservation was not found
   */
  BookingNotFound = 'booking_not_found',
  /**
   * Error validating the data provided in the payload
   */
  DataValidation = 'data_validation',
  /**
   * The booking attempt was declined
   */
  OrderDeclined = 'order_declined',
  /**
   * Other reasons
   */
  Other = 'other',
  /**
   * Errors related to payment
   */
  Payment = 'payment',
  /**
   * The customer payment attempt was not authorized
   */
  PaymentNotAuthorized = 'payment_not_authorized',
  /**
   * The payment was not cancelled
   */
  PaymentNotCancelled = 'payment_not_cancelled',
  /**
   * There's a difference in price between the data provided by SAPI and the data collected by BoFH
   */
  PriceMismatch = 'price_mismatch',
  /**
   * The Payment Service Provider (PSP) was not found
   */
  PspNotFound = 'psp_not_found',
  /**
   * The API reached a rate limit on the Provider side
   */
  RateLimit = 'rate_limit',
  /**
   * Generic error
   */
  RequestError = 'request_error',
  /**
   * The room tried to be booked is sold out
   */
  SoldOut = 'sold_out',
  /**
   * An error with the customer connection happened during the booking process.
   */
  ConnectionError = 'connection_error',
  /**
   * An error with the duplicate identical reservation.
   */
  DuplicateBooking = 'duplicate_booking',
  /**
   * An error when offer is expired
   */
  OfferExpired = 'offer_expired',
  /**
   * PCI proxy error
   */
  PCIProxy = 'pci_proxy'
}
