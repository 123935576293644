import React, {useCallback, useEffect, useRef} from 'react'
import {
  CaptionLabelProps,
  DateFormatter,
  DayContentProps,
  DayModifiers,
  DayPicker,
  Matcher,
  Months
} from 'react-day-picker'
import {isSameMonth, subMonths} from 'date-fns'
import addDays from 'date-fns/addDays'
import addMonths from 'date-fns/addMonths'
import isBefore from 'date-fns/isBefore'
import isSameDay from 'date-fns/isSameDay'
import {enUS} from 'date-fns/locale'
import parseIso from 'date-fns/parseISO'
import subDays from 'date-fns/subDays'
import throttle from 'lodash/throttle'

import {Icon} from '@daedalus/atlas/Icon'
import {
  MAX_DATE_DIFFERENCE,
  WEEKENDS_INDEXES
} from '@daedalus/core/src/datePicker/config'
import {
  DatePickerType,
  DatePickerVariant,
  DayOfWeekType
} from '@daedalus/core/src/datePicker/types'
import {WithColorPriceDayData} from '@daedalus/core/src/search/modules/ColorPriceCalendar/components/WithColorPriceDayData'
import {
  dateFormat,
  dateStringToMiddayDate,
  dateToMiddayDate,
  MiddayDate,
  UTS_DATE_FORMAT
} from '@daedalus/core/src/utils/date'
import {scrollContainerToElement} from '@daedalus/core/src/utils/dom'

import {
  ColorPriceDayInner,
  MonthName,
  VirtualizedMonths,
  WeekDay
} from '../components'
import {DatePickerElement} from '../styles'

export type DayInnerContainer = typeof WithColorPriceDayData

interface Props {
  containerRef?: React.MutableRefObject<HTMLDivElement | null>
  checkIn: string | null | undefined
  checkOut: string | null | undefined
  firstDayOfWeek: number
  maxMonthsCount?: number
  months: string[]
  numberOfMonthsToShow?: number
  onDOMChange?: (ev?: Event) => void
  onMount?: () => void
  onChange: (checkIn: string, checkOut: string) => void
  onCheckOutSelected?: () => void
  onDatePickerOpen: (dateType: DatePickerType) => void
  onDayClick?: (dateType: DatePickerType, date: string) => void
  openedDatePickerType?: DatePickerType | null | undefined
  weekdaysShort: string[]
  maxLengthOfStay?: number
  variant?: DatePickerVariant
  earliestCheckInDate?: Date
  /**
   * Will wrap Months in a Virtuoso component.
   * Only used in `vertical` variant when `numberOfMonthsToShow` is greater than 10.
   */
  virtualize?: boolean
  showTotalPrices?: boolean
  shouldShowPrices?: boolean
  onMonthView?: (month: Date) => void
  DayInnerContainer?: DayInnerContainer
}

type DisabledDays = {
  before: Date
  after: Date | undefined
}

type DisabledDaysParams = {
  checkInDate: Date | undefined
  earliestCheckInDate: Date
  maxLengthOfStay: number
}

const determineCheckOutDisabledDays = ({
  checkInDate,
  earliestCheckInDate,
  maxLengthOfStay
}: DisabledDaysParams): DisabledDays | (() => true) => {
  if (checkInDate && checkInDate < earliestCheckInDate) {
    // Disable all checkOut days
    return () => true
  }

  const lengthOfStayBoundary =
    checkInDate && addDays(checkInDate, maxLengthOfStay)

  return {
    before: earliestCheckInDate,
    after: lengthOfStayBoundary
  }
}

const getInitialMonth = (
  checkIn: MiddayDate | undefined,
  variant: DatePickerVariant
) => {
  const todayDate = dateToMiddayDate(new Date())
  if (!checkIn) {
    return todayDate
  }

  if (variant === 'vertical') {
    return checkIn < todayDate ? checkIn : todayDate
  }

  return checkIn
}

const DatePickerComponent = ({
  containerRef,
  checkIn,
  checkOut,
  openedDatePickerType = DatePickerType.CheckIn,
  weekdaysShort,
  months,
  firstDayOfWeek,
  numberOfMonthsToShow = 14,
  maxMonthsCount = 12,
  onMount,
  onChange,
  onDatePickerOpen,
  onDayClick,
  onDOMChange,
  onCheckOutSelected,
  maxLengthOfStay = MAX_DATE_DIFFERENCE,
  variant = 'horizontal',
  earliestCheckInDate = dateToMiddayDate(new Date()),
  virtualize = false,
  showTotalPrices,
  shouldShowPrices = false,
  onMonthView = () => null,
  DayInnerContainer
}: Props) => {
  const checkInMonthRef = useRef<null | HTMLDivElement>(null)
  const fetchedDates = useRef(new Set<string>())

  const checkInDate = checkIn ? dateStringToMiddayDate(checkIn) : undefined
  const checkOutDate = checkOut ? dateStringToMiddayDate(checkOut) : undefined
  const maxCheckInDate = subDays(
    addMonths(earliestCheckInDate, maxMonthsCount),
    1
  )
  const fetchAvailabilityData = (date: Date) => {
    const today = new Date()
    const oneYearFromNow = addMonths(today, 12)
    const canFetchAvailabilityData = date && isBefore(date, oneYearFromNow)

    canFetchAvailabilityData && onMonthView(date)
    const monthDateString = `${date.getMonth()}-${date.getFullYear()}`
    fetchedDates.current.add(monthDateString)
  }
  const throttledFetchAvailabilityData = throttle(fetchAvailabilityData, 500)

  const doFetchAvailabilityData =
    variant === 'horizontal'
      ? fetchAvailabilityData
      : throttledFetchAvailabilityData

  useEffect(() => {
    onMount?.()
    if (checkInDate) {
      doFetchAvailabilityData(checkInDate)

      // For the mobile datepicker
      if (variant === 'vertical') {
        const previousMonthDate = subMonths(checkInDate, 1)
        doFetchAvailabilityData(previousMonthDate)
      }
    }
  }, [onMount])

  useEffect(() => {
    const containerElement = containerRef?.current
    const monthElement = checkInMonthRef?.current
    if (!containerElement || !monthElement) return

    if (onDOMChange) {
      setTimeout(() =>
        containerElement.addEventListener('DOMSubtreeModified', onDOMChange)
      )
    }

    window.setTimeout(() => {
      const offsetPx = 12
      scrollContainerToElement(containerElement, monthElement, 0, offsetPx)
    }, 0)

    return () => {
      if (onDOMChange) {
        containerElement.removeEventListener('DOMSubtreeModified', onDOMChange)
      }
    }
  }, [containerRef, onDOMChange])

  // NOTE: Check `core/src/datePicker/business.ts` for a shared version of this logic
  const handleCheckInClick = (date: MiddayDate, modifiers: DayModifiers) => {
    if (modifiers.disabled) return

    const checkInDate = dateFormat(date, UTS_DATE_FORMAT)

    if (onDayClick) onDayClick(DatePickerType.CheckIn, checkInDate)

    onChange(checkInDate, '')

    onDatePickerOpen(DatePickerType.CheckOut)
  }

  // NOTE: Check `core/src/datePicker/business.ts` for a shared version of this logic
  const handleCheckOutClick = (
    date: MiddayDate,
    modifiers: DayModifiers
  ): void => {
    if (modifiers.disabled) return

    const checkInDate = checkIn || dateFormat(subDays(date, 1), UTS_DATE_FORMAT)

    const checkOutDate = dateFormat(date, UTS_DATE_FORMAT)

    if (onDayClick) onDayClick(DatePickerType.CheckOut, checkOutDate)

    onChange(checkInDate, checkOutDate)

    onDatePickerOpen(DatePickerType.CheckIn)

    if (onCheckOutSelected) onCheckOutSelected()
  }

  // NOTE: Check `core/src/datePicker/business.ts` for a shared version of this logic
  const handleDayClick = (date: MiddayDate, modifiers: DayModifiers) => {
    if (openedDatePickerType === 'checkIn') {
      handleCheckInClick(date, modifiers)
    } else if (isBefore(date, parseIso(checkIn as string))) {
      handleCheckInClick(date, modifiers)
    } else if (isSameDay(date, parseIso(checkIn as string))) {
      handleCheckInClick(date, modifiers)
    } else {
      handleCheckOutClick(date, modifiers)
    }
  }

  const initialMonth = getInitialMonth(checkInDate, variant)
  const fromMonth =
    variant === 'horizontal' ? earliestCheckInDate : initialMonth

  const toMonth =
    checkOutDate && checkOutDate > maxCheckInDate
      ? checkOutDate
      : maxCheckInDate

  const handleInView = (date: Date) => {
    const monthDateString = `${date.getMonth()}-${date.getFullYear()}`
    if (!fetchedDates.current.has(monthDateString)) {
      doFetchAvailabilityData(date)
    }

    // For the mobile view we want to fetch data both for the current and previous months to be sure
    // that user can see the prices when he starts to scroll up
    if (variant === 'vertical') {
      const previousMonthDate = subMonths(date, 1)
      const previousMonthDateString = `${previousMonthDate.getMonth()}-${previousMonthDate.getFullYear()}`
      if (!fetchedDates.current.has(previousMonthDateString)) {
        doFetchAvailabilityData(previousMonthDate)
      }
    }
  }

  // FIXME: Days Of Week should be configurable based on Locale
  const modifiers: Record<string, Matcher | Matcher[]> = {
    startEnd: (day: Date) =>
      isSameDay(day, checkInDate as Date) ||
      isSameDay(day, checkOutDate as Date),
    start: (day: Date) => isSameDay(day, checkInDate as Date),
    end: (day: Date) => isSameDay(day, checkOutDate as Date),
    weekends: {dayOfWeek: WEEKENDS_INDEXES}
  }

  const checkOutDisabledDays = determineCheckOutDisabledDays({
    checkInDate,
    earliestCheckInDate,
    maxLengthOfStay
  })

  const disabledDays = {
    checkIn: {
      before: earliestCheckInDate
    },
    checkOut: checkOutDisabledDays
  }

  const DayContent = useCallback(
    ({date}: DayContentProps) => {
      return DayInnerContainer ? (
        <DayInnerContainer day={date}>
          {({hotelAvailabilityPrices, searchComplete}) => (
            <ColorPriceDayInner
              day={date}
              showTotalPrices={showTotalPrices}
              shouldShowPrices={shouldShowPrices}
              hotelAvailabilityPrices={hotelAvailabilityPrices}
              searchComplete={searchComplete}
              noHighPrices={true}
              noUnavailabilityIcon={true}
            />
          )}
        </DayInnerContainer>
      ) : null
    },
    [showTotalPrices, shouldShowPrices, DayInnerContainer]
  )

  const CaptionLabel = useCallback(
    ({displayMonth}: CaptionLabelProps) => {
      const isCheckInMonth = checkInDate
        ? isSameMonth(displayMonth, checkInDate)
        : false
      return (
        <MonthName
          datePickerVariant={variant}
          months={months}
          isCheckInMonth={isCheckInMonth}
          checkInMonthRef={checkInMonthRef}
          date={displayMonth}
          onInView={handleInView}
        />
      )
    },
    [checkInDate, months, variant, handleInView]
  )

  const formatWeekdayName: DateFormatter = useCallback(
    (date: Date) => {
      const weekday = date.getDay()
      const isWeekend = WEEKENDS_INDEXES.includes(weekday)
      return (
        <WeekDay
          variant={variant}
          title={weekdaysShort[date.getDay()]}
          isWeekend={isWeekend}
        >
          {weekdaysShort[date.getDay()]}
        </WeekDay>
      )
    },
    [weekdaysShort, variant]
  )

  const shouldVirtualizeList =
    variant === 'vertical' && numberOfMonthsToShow > 10 && virtualize

  const MonthsComponent = useCallback<React.FC>(
    ({children}) => {
      if (!shouldVirtualizeList) {
        return <Months>{children}</Months>
      }

      return (
        <VirtualizedMonths
          checkInDate={checkInDate}
          initialMonth={initialMonth}
        >
          {children}
        </VirtualizedMonths>
      )
    },
    [shouldVirtualizeList]
  )
  return (
    <DatePickerElement
      data-id="DatePicker"
      ref={containerRef}
      variant={variant}
      singleDaySelected={!!checkIn && !checkOut}
    >
      <DayPicker
        classNames={{
          root: 'wrapper',
          day: 'day-cell',
          months: 'months-wrapper',
          month: 'month-wrapper',
          caption: 'caption-wrapper',
          caption_start: 'caption-start-wrapper',
          caption_label: 'caption-label-wrapper',
          caption_end: 'caption-end-wrapper',
          nav_button: 'nav-button',
          nav_button_next: 'nav-button-next',
          nav_button_previous: 'nav-button-previous',
          head_cell: 'week-day-wrapper',
          table: 'calendar-wrapper',
          head: 'calendar-week-days-wrapper',
          day_selected: 'day-selected',
          day_disabled: 'day-disabled'
        }}
        locale={enUS}
        modifiers={modifiers}
        selected={[{from: checkInDate, to: checkOutDate}]}
        disabled={
          openedDatePickerType ? disabledDays[openedDatePickerType] : undefined
        }
        defaultMonth={initialMonth} // Superfluous but required for test mocking
        fromMonth={fromMonth}
        toMonth={toMonth}
        numberOfMonths={numberOfMonthsToShow}
        onDayClick={handleDayClick}
        formatters={{
          formatWeekdayName
        }}
        disableNavigation={variant !== 'horizontal'}
        weekStartsOn={firstDayOfWeek as DayOfWeekType}
        components={{
          DayContent,
          CaptionLabel,
          IconRight: () => <Icon name="ChevronRight" />,
          IconLeft: () => <Icon name="ChevronLeft" />,
          Months: MonthsComponent
        }}
        modifiersClassNames={{
          selected: 'selected',
          today: 'today',
          startEnd: 'startEnd',
          start: 'start',
          disabled: 'disabled',
          outside: 'outside',
          week: 'week',
          end: 'end'
        }}
      />
    </DatePickerElement>
  )
}

export const ColorPriceDatePicker = React.memo(DatePickerComponent)
