import {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {getEmailDomain} from '@daedalus/core/src/auth/business/authentication'
import {
  selectUserEmail,
  selectUserId
} from '@daedalus/core/src/auth/modules/selectors'
import {Room, RoomsRequestParameters, SapiClient} from '@findhotel/sapi'

import {DealFreezeErrorSource} from '../components/ErrorMessage/types'
import {SapiContext} from '../context/SapiContext'
import {getFreezeSelectedContext} from '../modules/selector'
import {setError, setSapiRoom} from '../modules/slice'
import {DealFreezeEndpoints} from '../services/dealFreezeApi'
import {findExactMatchedOffer} from '../utils/offer'
import {useGetSapiCommonParams} from './useGetSapiCommonParams'

interface Props {
  shouldSkip: boolean
}

export const useGetSapiRoom = ({shouldSkip}: Props) => {
  const [sapiRoomLoading, setSapiRoomLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const sapiClient = useContext(SapiContext)
  const userId = useSelector(selectUserId)
  const {sapiMatchedOffer} = useSelector(getFreezeSelectedContext)
  const {offer, hotel, searchDetail, rooms} = useSelector(
    getFreezeSelectedContext
  )

  const {searchOffer} = offer || {}
  const {rate} = searchOffer || {}

  const {id: offerId, providerCode} = offer || {}
  const {objectID: hotelId} = hotel || {}
  const {checkIn, checkOut} = searchDetail || {}

  const userEmail = useSelector(selectUserEmail)
  const emailDomain = userEmail && getEmailDomain(userEmail)

  const sapiCommonParams = useGetSapiCommonParams()

  useEffect(() => {
    /**
     *  If `rooms` is already present, it indicates Sapi Room was already passed to DealFreezeButton, and there is
     *  no need to re-fetch the room from Sapi
     */
    if (rooms) {
      setSapiRoomLoading(false)
      return
    }
    const getSapiRoom = async () => {
      if (shouldSkip || !offerId || !hotelId) return

      setSapiRoomLoading(true)
      const parameters: RoomsRequestParameters = {
        clickedOfferId: offerId,
        providerCode: providerCode as string,
        userId,
        hotelId,
        checkIn,
        checkOut,
        rooms: searchDetail.roomsSplit,
        ...(emailDomain && {emailDomain}),
        ...(rate && {
          clickedOfferBaseRate: rate.base,
          clickedOfferHotelFees: rate.hotelFees,
          clickedOfferTaxes: rate.taxes
        }),
        ...sapiCommonParams
      }

      try {
        const result = await (sapiClient as SapiClient).rooms(parameters)
        if (result) {
          const rooms = result.rooms as unknown as Room[]
          dispatch(setSapiRoom({rooms, offerId}))
          const matchedOffer = findExactMatchedOffer(rooms, offerId)
          if (!matchedOffer) {
            dispatch(
              setError({
                errored: true,
                message:
                  'Could not find an exact match for the offer in the SAPI /rooms-offers response',
                component: DealFreezeErrorSource.FreezeOverlay,
                endpoint: DealFreezeEndpoints.SapiRoom
              })
            )
          }
        }
      } catch (error) {
        console.error('Failed to get SAPI room offer:', error)
        dispatch(
          setError({
            errored: true,
            message: 'Failed to get SAPI room offer',
            component: DealFreezeErrorSource.FreezeOverlay,
            endpoint: DealFreezeEndpoints.SapiRoom
          })
        )
      } finally {
        setSapiRoomLoading(false)
      }
    }

    getSapiRoom()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerId, dispatch, shouldSkip])

  return {sapiRoomLoading, sapiMatchedOffer}
}
