import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Icon} from '@daedalus/atlas/src/components/designSystem/Icon'
import {Text} from '@daedalus/atlas/src/components/designSystem/Text'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {FormattedExpirationDate} from '../common/FormattedExpirationDate'

interface Props {
  expiresAt?: string
}

const HeaderContentWrapper = styled.div(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.layout.spacing.s200}px;
    text-align: center;
    margin-top: -${theme.layout.spacing.s500}px;
  `
)

const HeaderTitle = styled.div(
  ({theme}) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${theme.layout.spacing.s200}px;
  `
)

export const HeaderContent = ({expiresAt}: Props) => {
  return (
    <HeaderContentWrapper>
      <HeaderTitle>
        <Icon name={'Party'} colorPath="content.highlight.c500" />
        <Text colorPath="content.highlight.c500" variant="titleXL">
          <FormattedMessageWrapper
            id="df.confirmationOverlayHeader"
            defaultMessage="Best price secured"
          />
        </Text>
      </HeaderTitle>

      {expiresAt && (
        <Text variant="bodyM" colorPath="content.neutral.c000">
          <FormattedMessageWrapper
            id="df.confirmationOverlaySubHeader"
            defaultMessage="The price is locked in until {expirationDate}.<br></br>Complete the booking when you're ready."
            values={{
              expirationDate: <FormattedExpirationDate expiresAt={expiresAt} />,
              br: () => <br />
            }}
          />
        </Text>
      )}
    </HeaderContentWrapper>
  )
}
