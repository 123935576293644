import {pickAll} from 'ramda'
import {urlParams} from 'utils/urlParams'

import {LeadContext} from '@daedalus/core/src/analytics/types/Events'
import {getOrGenerateAnonymousId} from '@daedalus/core/src/utils/anonymousId'
import {UrlCheckoutParamsType} from '@daedalus/core/src/utils/url/types/UrlParams'

import {TrackEventMetadata} from '../trackEventV2Helpers'

export const getLeadContext = ({searchId}: TrackEventMetadata): LeadContext => {
  const params = urlParams() as UrlCheckoutParamsType
  const anonymousId = getOrGenerateAnonymousId()

  const pickedParamKeys = [
    'checkIn',
    'checkOut',
    'currency',
    'hotelId',
    'locale',
    'providerCode',
    'providerRateType',
    'redirectId',
    'rooms'
  ] as const

  const pickedParams = pickAll<
    UrlCheckoutParamsType,
    Pick<LeadContext, typeof pickedParamKeys[number]> // pick type trick from https://github.com/microsoft/TypeScript/issues/20965#issuecomment-502888239
  >(pickedParamKeys, params)

  return {
    ...pickedParams,
    searchId: searchId || null,
    anonymousId
  }
}

export default getLeadContext
