import {useRouter} from '../../router/RouterContext'

export const useReferralInfo = () => {
  const {getQueryParams} = useRouter()
  const queryString = getQueryParams()
  const referrerAccountUserId = queryString.referrer as string | undefined
  const isReferrerSusi = Boolean(referrerAccountUserId)

  return {referrerAccountUserId, isReferrerSusi}
}
