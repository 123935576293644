import compose from 'lodash/fp/compose'
import isNil from 'lodash/fp/isNil'
import omitBy from 'lodash/fp/omitBy'
import qs from 'qs'
import {split} from 'ramda'

import {META_PARAM_SEPARATOR} from './generateSearchParams'
import {AppsFlyerDeepLinkData, SearchType} from './types'

const getHotelId = (data?: AppsFlyerDeepLinkData) => {
  if (data?.deep_link_sub3 === SearchType.hotelSearch) {
    return data?.deep_link_sub4
  }

  return data?.hotelId
}

const getPlaceId = (data?: AppsFlyerDeepLinkData) => {
  if (data?.deep_link_sub3 === SearchType.placeSearch) {
    return data?.deep_link_sub4
  }

  return data?.placeId
}

const getUserLocationSearch = (data?: AppsFlyerDeepLinkData) => {
  if (data?.deep_link_sub3 === SearchType.userLocationSearch) {
    return data?.deep_link_sub4
  }

  return data?.userLocationSearch
}

/**
 * @deprecated
 *
 * This function is responsible for extracting the legacy params from the deep link data.
 *
 * The web-to-app flow does not use these params anymore, but they are kept to support links from emails and pushes.
 *
 * @param data - AppsFlyerDeepLinkData
 * @returns object containing the legacy params
 */
const getLegacyParams = (data?: AppsFlyerDeepLinkData) => {
  const [esd, epv] = data?.deep_link_sub6
    ? split(META_PARAM_SEPARATOR, data?.deep_link_sub6)
    : []
  const [clickedOfferRate, currencyCode] = data?.deep_link_sub7
    ? split(META_PARAM_SEPARATOR, data?.deep_link_sub7)
    : []

  const legacyParams = {
    checkIn: data?.deep_link_sub1 || data?.checkIn,
    checkOut: data?.deep_link_sub2 || data?.checkOut,
    hotelId: getHotelId(data),
    placeId: getPlaceId(data),
    userLocationSearch: getUserLocationSearch(data),
    rooms: data?.deep_link_sub5 || data?.rooms,
    esd: esd,
    epv: epv,
    clickedOfferRate: clickedOfferRate,
    currencyCode: currencyCode,
    vclid: data?.deep_link_sub10
  }

  return legacyParams
}

const decodeAndParse = compose(qs.parse, decodeURIComponent)
const removeEmptyProps = omitBy(isNil)

/**
 * This function is responsible for parsing and decoding the deep_link_sub9 param.
 *
 * The deep_link_sub9 param contains the data that comes from the website deep link.
 *
 * @param deep_link_sub9 - string containing the encoded data
 * @returns object containing the decoded search data
 */
const getDecodedData = (deep_link_sub9?: string) => {
  return deep_link_sub9 ? decodeAndParse(deep_link_sub9) : {}
}

export const extractSearchParams = (data?: AppsFlyerDeepLinkData) => {
  const legacyParams = getLegacyParams(data)
  const searchParams = getDecodedData(data?.deep_link_sub9)

  return removeEmptyProps({
    page: data?.deep_link_value,
    ...legacyParams,
    ...searchParams
  })
}
