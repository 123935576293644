import {isFreeCancellationOffer} from 'business/offer'

import {BookingSources} from '@daedalus/core/src/booking/types/BookingData'
import {
  calculateChargeDate,
  isEligibleForChargeLater
} from '@daedalus/core/src/offer/business/chargeLater'
import {Offer} from '@daedalus/core/src/offer/types/offer'
import {
  getDisplayPrice,
  getTotalPrice
} from '@daedalus/core/src/price/business/price'

export const getRoomOfferContext = (offer: Offer) => {
  const isChargeLaterEligible = isEligibleForChargeLater(
    offer.providerCode,
    offer.cancellationPenalties
  )
  const chargeType = isChargeLaterEligible
    ? [BookingSources.ChargeLater]
    : [BookingSources.Default]
  const chargeDate = calculateChargeDate(offer.cancellationPenalties)
  const paymentExpiryAt = {
    ...(isChargeLaterEligible && {chargeExpiryAt: chargeDate?.toISOString()})
  }
  return {
    chargeType,
    offerId: offer.id,
    totalPrice: getTotalPrice(getDisplayPrice(offer.prices)),
    offerTags: offer.tags,
    availableRooms: offer.availableRooms,
    canPayLater: offer.canPayLater || isChargeLaterEligible,
    hasFreeCancellation: isFreeCancellationOffer(offer),
    providerCode: offer.providerCode,
    cancellationPenalties: offer.cancellationPenalties,
    cug: offer.cug,
    providerFeedId: offer.metadata?.feedID,
    providerRateType: offer.metadata?.providerRateType,
    isClicked: offer.isClicked,
    matchType: offer.matchType,
    matchedDim: offer.matchedDim,
    matchedOfferPriceDiff: offer.matchedOfferPriceDiff,
    services: offer.services,
    payAtProperty: offer.canPayLater,
    originalAccessTier: offer.metadata?.originalAccessTier,
    ...(isChargeLaterEligible && {paymentExpiryAt})
  }
}
