import {HotelRequestType} from 'types/Hotel'
import {MetaType} from 'types/Meta'
import {ProviderRequestType} from 'types/providerInfo'
import {OfferRequestAugmentedType, RoomsRequestType} from 'types/Rooms'
import {urlParams} from 'utils/urlParams'

import {
  UrlCheckoutParamsType,
  UrlSearchParamsType
} from '@daedalus/core/src/utils/url/types/UrlParams'

import augmentedOfferRequestParams from './mappers/augmentedOfferRequestParams'
import getHotelRequestParams from './mappers/getHotelRequestParams'
import getRoomsRequestParams from './mappers/getRoomsRequestParams'
import metaParams from './mappers/meta'
import providerParams from './mappers/providerParams'
import searchWebsiteParams from './mappers/searchWebsiteParams'

export type URLParamsMapperType = {
  augmentedOfferRequestParams: OfferRequestAugmentedType
  hotelRequestParams: HotelRequestType
  metaParams: MetaType
  roomsRequestParams: RoomsRequestType
  params: UrlCheckoutParamsType
  providerParams: ProviderRequestType
  searchWebsiteParams: UrlSearchParamsType
}

const URLParamsMapper = (): URLParamsMapperType => {
  const params = urlParams()
  return {
    augmentedOfferRequestParams: augmentedOfferRequestParams(params),
    hotelRequestParams: getHotelRequestParams(params),
    roomsRequestParams: getRoomsRequestParams(params),
    searchWebsiteParams: searchWebsiteParams(params),
    metaParams: metaParams(params),
    providerParams: providerParams(params),
    params
  }
}

export default URLParamsMapper
