export const LOG_EVENT = 'LOG_EVENT'
export type ActionType = {
  meta: any
  metric: string
  level: string | null | undefined
  type: typeof LOG_EVENT
}

export const logEvent = (
  metric: string,
  meta?: any,
  level?: string | null | undefined
): ActionType => ({
  metric,
  meta,
  level,
  type: LOG_EVENT
})

export type LogEvent = typeof logEvent
