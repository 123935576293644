import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import {getIsAuthenticated} from '@daedalus/core/src/auth/modules/selectors'
import {getUserSessionService} from '@daedalus/core/src/auth/services'

export const useGetUserJwtToken = () => {
  const isAuthenticated = useSelector(getIsAuthenticated)
  const [idToken, setIdToken] = useState<string>()

  useEffect(() => {
    const fetchUserSession = async () => {
      try {
        const {idToken} = await getUserSessionService()
        setIdToken(idToken?.toString())
      } catch (error) {
        console.error('useGetUserJwtToken:', error)
      }
    }

    if (isAuthenticated) fetchUserSession()
  }, [isAuthenticated])

  return idToken
}
