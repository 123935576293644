/* eslint-disable fp/no-mutation */
import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {BookingCreationResponse} from '@daedalus/core/src/booking/types/BookingCreationResponse'
import {BookingStatus} from '@daedalus/core/src/booking/types/BookingStatus'

import {BookStateType, initialState} from './reducer'

const setCurrentModuleState = (
  state: BookStateType,
  action: PayloadAction<string | Record<string, any>>
) => {
  state.offerBookState = action.type
}

const {actions, reducer} = createSlice({
  name: 'book',
  initialState,
  reducers: {
    bookOfferCreationError: setCurrentModuleState,
    bookOfferCreationFatalError: setCurrentModuleState,
    bookOfferCreationConfirm: (
      state: BookStateType,
      action: PayloadAction<
        Partial<BookingCreationResponse> & {
          isWithThreeDomainSecure?: boolean
        }
      >
    ) => {
      state.booking.status = BookingStatus.CONFIRMED
      state.offerBookState = action.type
    },
    bookOfferClear: () => {
      return initialState
    }
  }
})

export const {
  bookOfferCreationError,
  bookOfferCreationFatalError,
  bookOfferCreationConfirm,
  bookOfferClear
} = actions

export default reducer
