import React from 'react'
import styled from '@emotion/styled'

import {Icon} from '@daedalus/atlas/src/components/designSystem/Icon'
import {Text} from '@daedalus/atlas/src/components/designSystem/Text'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {ArrowButton} from '../ArrowButton'
import {IconWrapper, Label, RowWrapper} from '../commonStyles'
import {PropsType} from '../types'

const MembershipMessage = styled(FormattedMessageWrapper)`
  display: inline;
`

export const MembershipLink = ({
  onShowMembershipOverlay
}: Pick<PropsType, 'onShowMembershipOverlay'>) => (
  <RowWrapper>
    <ArrowButton onClick={onShowMembershipOverlay}>
      <Label>
        <IconWrapper>
          <Icon name="Sparkles" />
        </IconWrapper>
        <Text variant="labelM">
          <MembershipMessage
            id="membership.GemsMembership"
            defaultMessage=" {gems} Membership"
            values={{
              gems: <Icon className="inline-icon" size="lg" name="GemsLogo" />
            }}
          />
        </Text>
      </Label>
    </ArrowButton>
  </RowWrapper>
)
