import {useRouter} from '../../router/RouterContext'
import {getLastLoginUrl, removeLastLoginUrl} from '../business/persistData'

export const CognitoLogoutHandler = () => {
  const {push} = useRouter()
  const lastLoginUrl = getLastLoginUrl()
  const url = new URL(lastLoginUrl || location.origin)
  push(url.pathname + url.search)
  removeLastLoginUrl()
  return null
}
