import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {ContentWrapper} from '@daedalus/atlas/helpers/ContentWrapper'
import {getUser} from '@daedalus/core/src/auth/modules/selectors'
import {getLinkByType} from '@daedalus/core/src/offer/business/getLinkByType'
import {ActionLinkTypes} from '@daedalus/core/src/offer/types/actionLink'

import {useGetSapiCommonParams} from '../../hooks/useGetSapiCommonParams'
import {getError, getOfferEligibility} from '../../modules/selector'
import {openFreezeConfirmationOverlay} from '../../modules/slice'
import {
  useCreateBookingMutation,
  useGetBookingStatusQuery
} from '../../services/dealFreezeApi'
import {BookingCreationStatus} from '../../types/bookingCreation'
import {HotelCardWithHeaders} from '../HotelCardWithHeaders'
import {LoadingIndicator} from './LoadingIndicator'

const BOOKING_STATUS_POLLING_INTERVAL = 5000
const FINAL_STATUSES = [
  BookingCreationStatus.Frozen,
  BookingCreationStatus.Error
]

interface Props {
  hasErrorCTA?: boolean
}
export const BookingCreation: React.FC<Props> = ({hasErrorCTA = true}) => {
  const dispatch = useDispatch()

  const {errored} = useSelector(getError)
  const [skipStatusPolling, setSkipStatusPolling] = useState(false)
  const userAttributes = useSelector(getUser)?.attributes

  const {cugDeals} = useGetSapiCommonParams()
  const [createBooking, createBookingResult] = useCreateBookingMutation()
  const {response: offerCheckResponse} = useSelector(getOfferEligibility)
  const bookingCreationUrl = getLinkByType(
    offerCheckResponse?.links,
    ActionLinkTypes.DEAL_FREEZE_BOOKING_CREATION
  )

  useEffect(() => {
    if (!bookingCreationUrl || !createBookingResult.isUninitialized) return

    const {given_name, family_name} = userAttributes || {}
    const body = {
      customer: {
        firstName: given_name,
        lastName: family_name
      },
      offerMetadata: {
        accessTier: cugDeals.split(',')
      }
    }

    createBooking({url: bookingCreationUrl, body})
  }, [
    bookingCreationUrl,
    createBooking,
    userAttributes,
    createBookingResult,
    cugDeals
  ])

  const bookingStatusLink = getLinkByType(
    createBookingResult?.data?.links,
    ActionLinkTypes.BOOKING_STATUS
  )

  const bookingStatusResult = useGetBookingStatusQuery(
    {url: bookingStatusLink},
    {
      pollingInterval: BOOKING_STATUS_POLLING_INTERVAL,
      skip: skipStatusPolling || !bookingStatusLink
    }
  )

  useEffect(() => {
    const response = bookingStatusResult?.data
    if (!response) return
    const {status} = response || {}

    if (FINAL_STATUSES.includes(status) || bookingStatusResult.isError) {
      setSkipStatusPolling(true)
    }

    if (status === BookingCreationStatus.Frozen) {
      dispatch(openFreezeConfirmationOverlay(response))
    }
  }, [bookingStatusResult, dispatch])

  if (errored)
    return (
      <ContentWrapper padding="s500">
        <HotelCardWithHeaders hasErrorCTA={hasErrorCTA} />
      </ContentWrapper>
    )

  return <LoadingIndicator />
}
