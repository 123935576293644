import '@daedalus/core/src/utils/scripts/smartscript'

import React from 'react'
import {useSelector} from 'react-redux'

import {generateAppDownloadUrl} from '@daedalus/core/src/deepLinking'
import {BannerName} from '@daedalus/core/src/deepLinking/types'

import {getApplication} from '../../modules/selector'
import appPromotionImage from './app-promotion-banner.png'

export const AppPromotionBanner = () => {
  const {page} = useSelector(getApplication)
  const appDownloadLink = generateAppDownloadUrl(
    page,
    BannerName.DealFreezeConfirmationBanner
  )
  return (
    <a href={appDownloadLink}>
      <img src={appPromotionImage} alt="App Promotion Banner" width="100%" />
    </a>
  )
}
