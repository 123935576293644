import React from 'react'
import {useIntl} from 'react-intl'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import useParams from 'components/data/URLParams/useParams'
import {HeaderWithStore} from 'components/HeaderWithStore'
import {BackButtonMountType} from 'hooks/useHandleBackButtonClick'

import {Button} from '@daedalus/atlas/Button'
import {MOBILE_HEADER_HEIGHT} from '@daedalus/atlas/CompactHeader'
import {ContentWrapper} from '@daedalus/atlas/helpers/ContentWrapper'
import soldOutImage from '@daedalus/atlas/images/no-dates--full.svg'
import {Overlay} from '@daedalus/atlas/Overlay'
import {Text} from '@daedalus/atlas/Text'
import {mq} from '@daedalus/atlas/utils/breakpoints'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

type PropsType = {
  onBackButtonClick?: (type: BackButtonMountType) => void
}

const ImageElement = styled('div')<{src: string}>(
  ({src}) => css`
    width: 100%;
    max-width: 874px;
    height: 305px;
    margin: 0 auto;
    z-index: 0;
    background-position: center;
    background-image: url(${src});
    background-size: cover;

    ${mq.desktopXs(css`
      height: 350px;
    `)}
  `
)

const Wrapper = styled('div')(
  ({theme}) => css`
    height: calc(100% - ${MOBILE_HEADER_HEIGHT}px);
    margin-top: ${theme.layout.spacing.s400}px;
    display: flex;
    z-index: 1;
    flex-direction: column;
    justify-content: space-between;
  `
)

const Title = styled('div')(
  ({theme}) => css`
    margin-bottom: ${theme.layout.spacing.s500}px;
  `
)

const Message = styled('div')(
  ({theme}) => css`
    color: ${theme.colors.content.neutral.c950};
  `
)

const ErrorTextElement = styled('div')(
  ({theme}) => css`
    margin: ${theme.layout.spacing.s300}px auto;
    max-width: 328px;
  `
)

const Footer = styled('div')(
  ({theme}) => css`
    text-align: center;
    margin-top: ${theme.layout.spacing.s600}px;
  `
)

const FooterItem = styled('div')(
  ({theme}) => css`
    margin-bottom: ${theme.layout.spacing.s700}px;
  `
)

const CenteredButton = styled(Button)`
  margin: 0 auto;
`

const SoldOutOverlay = ({onBackButtonClick}: PropsType) => {
  const {currency} = useParams()
  const {locale} = useIntl()
  const handleBackButtonClick = () => {
    onBackButtonClick?.(BackButtonMountType.soldOut)
  }

  return (
    <Overlay isOpen>
      <HeaderWithStore
        disableLogoClick
        currentCurrency={currency}
        currentLanguage={locale}
        forceHideSearchBox
      />
      <Wrapper>
        <Message>
          <ContentWrapper padding="s500">
            <Title>
              <Text as="h2" variant="titleL" align="center">
                <FormattedMessageWrapper
                  id="soldOutMessagesTitle"
                  defaultMessage="Sold out on your dates!"
                />
              </Text>
            </Title>
            <ErrorTextElement>
              <Text align="center">
                <FormattedMessageWrapper
                  id="soldOutMessagesFirstLineV3"
                  defaultMessage="Try adjusting your itinerary."
                />
              </Text>
            </ErrorTextElement>
            <Footer>
              {onBackButtonClick && (
                <FooterItem>
                  <CenteredButton size="lg" onClick={handleBackButtonClick}>
                    <FormattedMessageWrapper
                      id="chooseAnotherProperty"
                      defaultMessage="Back to property"
                    />
                  </CenteredButton>
                </FooterItem>
              )}
            </Footer>
          </ContentWrapper>
        </Message>

        <ImageElement src={soldOutImage} />
      </Wrapper>
    </Overlay>
  )
}

export default SoldOutOverlay
