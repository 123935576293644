import React from 'react'
import {useSelector} from 'react-redux'
import {getAnonymousId} from 'modules/meta/selectors'

import {Brand} from '@daedalus/core/src/brand/types'
import {PasswordlessLogin} from '@findhotel/shared/src/authentication/SUSI/overlay'

interface PasswordlessLoginPropsType {
  brand: Brand
  languageCode: string
}

export const PasswordlessLoginWrapper = ({
  brand,
  languageCode
}: PasswordlessLoginPropsType) => {
  const anonymousId = useSelector(getAnonymousId)

  return (
    <PasswordlessLogin
      brand={brand}
      languageCode={languageCode}
      anonymousId={anonymousId}
    />
  )
}
