import React, {ReactNode} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {hexToRGBAString} from '@daedalus/core/src/utils/string'

import {mq} from '../../../../utils/breakpoints'
import {Icon} from '../../Icon'
import {Text} from '../../Text'

export type ControlType = 'radio' | 'checkbox'

interface Props {
  /** Pass through className to allow styles overrides */
  className?: string
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
  /** Whether the control is checked or not */
  checked: boolean
  /** Whether the control is disabled or not */
  disabled?: boolean
  /** Whether the control container should render full width */
  fullWidth?: boolean
  /** Whether the input has an error */
  hasError?: boolean
  /** Content to render in label next to the control */
  label?: ReactNode
  /** Name used for html input element */
  name: string
  /** The control change handler. Fires when user change state of the Control (radio or checkbox) */
  onChange?: (e: React.SyntheticEvent) => void
  /** Value used for html input element */
  value?: any
  /** Use larger touch area */
  hasTouchArea?: boolean
  /** Whether the type is radio or checkbox */
  type: ControlType
}

const CONTROL_SIZE = 20
const CONTROL_DOT_SIZE = 10
const LABEL_MARGIN_LEFT = 10
const TOUCHAREA_SIZE_SM = 2
const TOUCHAREA_SIZE_LG = 12

const ControlElement = styled.div`
  display: flex;
`

export const ControlIcon = styled.div(
  ({theme}) => css`
    position: relative;
    width: ${CONTROL_SIZE}px;
    min-width: ${CONTROL_SIZE}px;
    height: ${CONTROL_SIZE}px;
    background-color: ${theme.colors.background.neutral.c000};
    border: 1px solid ${theme.colors.border.neutral.c200};
    border-radius: ${theme.layout.radius.rounded}px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0px 2px 4px
      ${hexToRGBAString(theme.colors.background.special.c950, 0.05)};
    cursor: pointer;

    &:after {
      content: '';
      width: ${CONTROL_DOT_SIZE}px;
      height: ${CONTROL_DOT_SIZE}px;
      background-color: ${theme.colors.background.neutral.c000};
      border-radius: ${theme.layout.radius.rounded}px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0);
    }
  `
)

export const ControlInput = styled.input<{
  disabledChecked?: boolean
  checked: string | boolean
}>(
  ({theme, disabledChecked}) => css`
    display: block;
    position: absolute;
    top: 0;
    left: ${CONTROL_SIZE + TOUCHAREA_SIZE_LG}px;
    opacity: 0;
    width: 0;
    height: 0;

    ${mq.desktopXs(css`
      &:focus-visible + ${ControlIcon} {
        box-shadow: 0 0 0 1px ${theme.colors.border.neutral.c000},
          0 0 0 3px ${theme.colors.background.interactive.c500};
        &:before {
          opacity: 1;
          visibility: visible;
        }
      }
    `)}

    &:active:not(:disabled) {
      & + ${ControlIcon} {
        background-color: ${theme.colors.background.interactive.c100};
        border-color: ${theme.colors.border.neutral.c500};
        box-shadow: 0 0 0 2px ${theme.colors.background.interactive.c200};
      }

      &:checked + ${ControlIcon} {
        background-color: ${theme.colors.background.interactive.c600};
        border-color: ${theme.colors.border.neutral.c900};
        box-shadow: 0 0 0 2px ${theme.colors.background.interactive.c100};
      }

      &[data-field-error='true'] + ${ControlIcon} {
        background-color: ${theme.colors.background.danger.c200};
        border-color: ${theme.colors.background.danger.c800};
        box-shadow: 0 0 0 2px ${theme.colors.background.danger.c200};

        &:after {
          background-color: ${theme.colors.background.danger.c800};
        }
      }
    }

    &[data-field-error='false']:checked + ${ControlIcon} {
      background-color: ${theme.colors.background.interactive.c500};
      border: none;
      &:after {
        transform: translate(-50%, -50%) scale(0);
      }
    }

    &[data-field-error='true'],
    &:checked + ${ControlIcon} {
      background-color: ${theme.colors.background.interactive.c500};
      border-color: ${theme.colors.background.interactive.c800};
      &:after {
        transform: translate(-50%, -50%) scale(1);
      }
    }

    &[data-field-error='true'] + ${ControlIcon} {
      background-color: ${theme.colors.background.danger.c050};
      border-color: ${theme.colors.background.danger.c600};

      &:after {
        background-color: ${theme.colors.background.danger.c600};
      }
    }

    &:disabled:checked + ${ControlIcon} {
      background-color: ${disabledChecked
        ? theme.colors.background.neutral.c200
        : theme.colors.background.neutral.c050};
      box-shadow: none;
      cursor: not-allowed;

      &:after {
        background-color: ${theme.colors.border.neutral.c300};
      }
    }

    &:disabled ~ ${RadioContent} {
      cursor: not-allowed;
    }
  `
)

interface LabelProps {
  hasTouchArea: boolean
  fullWidth: boolean
  htmlFor: string
}

export const ControlBody = styled.label<LabelProps>(
  ({hasTouchArea, fullWidth}) => css`
    display: flex;
    position: relative;
    padding: ${hasTouchArea ? TOUCHAREA_SIZE_LG : TOUCHAREA_SIZE_SM}px;

    ${fullWidth && `flex: 1;`}

    @supports selector(:has(input:disabled)) {
      cursor: pointer;
      :has(input:disabled) {
        cursor: not-allowed;
      }
    }
  `
)

export const RadioContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: ${LABEL_MARGIN_LEFT}px;
`

export const StyledText = styled(Text)`
  line-height: ${CONTROL_SIZE}px;
`

export const ControlWithCheckmark = ({
  checked,
  value,
  name,
  dataId,
  onChange,
  label,
  disabled,
  fullWidth = false,
  hasError = false,
  hasTouchArea = false,
  type = 'radio',
  className
}: Props) => {
  const disabledChecked = checked && disabled
  return (
    <ControlElement className={className}>
      <ControlBody
        hasTouchArea={hasTouchArea}
        fullWidth={fullWidth}
        htmlFor={value}
      >
        <ControlInput
          type={type}
          name={name}
          id={value}
          data-id={dataId}
          checked={checked}
          value={value}
          disabled={disabled}
          data-field-error={hasError}
          onChange={onChange}
          disabledChecked={disabledChecked}
        />
        <ControlIcon>
          {((checked && !hasError) || disabledChecked) && (
            <Icon
              name="Checkmark"
              size="sm"
              colorPath={
                disabledChecked
                  ? 'content.neutral.c500'
                  : 'content.neutral.c000'
              }
            />
          )}
        </ControlIcon>
        {Boolean(label) && (
          <RadioContent>
            <StyledText variant="bodyS" as="span">
              {label}
            </StyledText>
          </RadioContent>
        )}
      </ControlBody>
    </ControlElement>
  )
}
