import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {getIsAuthenticated} from '@daedalus/core/src/auth/modules/selectors'
import {ImageProvider as ImageProviderType} from '@daedalus/core/src/staticContent/types/ImageProvider'
import {ProfileKey} from '@findhotel/sapi'

import {FreezeConfirmationOverlay} from './components/FreezeConfirmationOverlay'
import {FreezeDetails} from './components/FreezeDetails'
import {UpdateApplication} from './components/UpdateApplication'
import {
  defaultExternalActions,
  ExternalActions,
  ExternalActionsProvider
} from './context/ExternalActionsContext'
import {ImageProvider} from './context/ImageProviderContext'
import {SapiProvider} from './context/SapiContext'
import {useDealFreezeEnabled} from './hooks/useDealFreezeEnabled'
import {useGetUserJwtToken} from './hooks/useGetUserJwtToken'
import {useSapiClient} from './hooks/useSapiClient'
import {getApiContext, getApplication} from './modules/selector'
import {ApplicationNames, setApiContext} from './modules/slice'
import {
  useCheckUserLimitQuery,
  useGetFrozenBookingsQuery
} from './services/dealFreezeApi'

interface Props {
  imageProvider: ImageProviderType
  appCurrency: string | undefined
  sapiProfileKey: ProfileKey
  application: ApplicationNames
  appVersion: string
  boVioEndpoint: string
  boVioProfile: string
  checkoutUrl: string
  bookingManagementUrl: string
  languageCode: string
  actions: ExternalActions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getSearchId: (state: any) => string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getCountryCode: (state: any) => string | null
}

const InitializeApiCalls = () => {
  const isAuthenticated = useSelector(getIsAuthenticated)
  const {boVioEndpoint, userToken} = useSelector(getApiContext)
  const shouldSkip = !userToken || !boVioEndpoint || !isAuthenticated

  useCheckUserLimitQuery({}, {skip: shouldSkip})
  useGetFrozenBookingsQuery({}, {skip: shouldSkip})
  return null
}

export const DealFreeze = ({
  imageProvider,
  appCurrency,
  sapiProfileKey,
  application,
  appVersion,
  boVioEndpoint,
  boVioProfile,
  checkoutUrl,
  bookingManagementUrl,
  languageCode,
  actions,
  getSearchId,
  getCountryCode
}: Props) => {
  const dispatch = useDispatch()
  const {currency} = useSelector(getApplication)
  const sapiClient = useSapiClient({
    sapiProfileKey,
    currency
  })

  const isDealFreezeEnabled = useDealFreezeEnabled()
  const userToken = useGetUserJwtToken()
  const externalActions = {
    ...defaultExternalActions,
    ...actions
  }

  useEffect(() => {
    const clientUserAgent = `${application}/${appVersion}`
    dispatch(
      setApiContext({boVioProfile, userToken, clientUserAgent, boVioEndpoint})
    )
  }, [
    boVioProfile,
    userToken,
    appVersion,
    application,
    dispatch,
    boVioEndpoint
  ])

  return (
    <ExternalActionsProvider value={externalActions}>
      <ImageProvider value={imageProvider}>
        <SapiProvider value={sapiClient}>
          <UpdateApplication
            appCurrency={appCurrency}
            sapiProfileKey={sapiProfileKey}
            application={application}
            appVersion={appVersion}
            boVioEndpoint={boVioEndpoint}
            boVioProfile={boVioProfile}
            checkoutUrl={checkoutUrl}
            bookingManagementUrl={bookingManagementUrl}
            languageCode={languageCode}
            actions={actions}
            getSearchId={getSearchId}
            getCountryCode={getCountryCode}
          />
          {isDealFreezeEnabled && (
            <>
              <InitializeApiCalls />
              <FreezeDetails />
              <FreezeConfirmationOverlay />
            </>
          )}
        </SapiProvider>
      </ImageProvider>
    </ExternalActionsProvider>
  )
}
