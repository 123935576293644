import {pickAll} from 'ramda'
import {urlParams} from 'utils/urlParams'

import {UrlCheckoutParamsType} from '@daedalus/core/src/utils/url/types/UrlParams'

interface MarketingContext {
  utm_source?: string
  utm_campaign?: string
  clickId?: string
}

const getMarketingContext = (): MarketingContext => {
  const params = urlParams()

  const pickedParamKeys = ['utm_source', 'utm_campaign', 'clickId'] as const

  const pickedParams = pickAll<
    UrlCheckoutParamsType,
    Pick<MarketingContext, typeof pickedParamKeys[number]> // pick type trick from https://github.com/microsoft/TypeScript/issues/20965#issuecomment-502888239
  >(pickedParamKeys, params)

  return {
    ...pickedParams
  }
}

export default getMarketingContext
