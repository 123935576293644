import {useGetBoVioConfiguration} from './useGetBoVioConfiguration'

interface Props {
  boVioUrl: string
  // BoVio API key
  boVioProfile: string
}
export const GetBoVioConfiguration = ({boVioUrl, boVioProfile}: Props) => {
  useGetBoVioConfiguration({boVioUrl, boVioProfile})
  return null
}
