import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Button} from '@daedalus/atlas/Button'
import {Divider} from '@daedalus/atlas/Divider'
import {ContentWrapper} from '@daedalus/atlas/helpers/ContentWrapper'
import {Icon} from '@daedalus/atlas/Icon'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {BookingCreation} from '../BookingCreation'
import {HotelCardWithHeaders} from '../HotelCardWithHeaders'
import {SkeletonLoading} from './SkeletonLoading'

const StickyContainer = styled.div(
  ({theme}) => css`
    position: sticky;
    bottom: 0;
    margin: 0 -16px -16px; /* Overlap BottomSheetContent's padding */
    background-color: ${theme.colors.background.neutral.c000};
  `
)

interface Props {
  isLoading: boolean
  isFreezable: boolean
  isCreateBookingLoading: boolean
  onCreateFreezeBooking: () => void
}
export const Content: React.FC<Props> = ({
  isLoading,
  isFreezable,
  isCreateBookingLoading,
  onCreateFreezeBooking
}) => {
  // Show the skeleton loading
  if (isLoading) return <SkeletonLoading />

  // Show the booking creation component when the freeze button is clicked, and the component will handle the booking creation
  if (isCreateBookingLoading) {
    return <BookingCreation />
  }

  return (
    <>
      <ContentWrapper paddingTop="s500">
        <HotelCardWithHeaders />
      </ContentWrapper>
      {isFreezable && (
        <StickyContainer>
          <Divider />
          <ContentWrapper
            paddingX="s500"
            paddingTop="s500"
            paddingBottom="s600"
          >
            <Button
              dataId="FreezeNowForFree"
              variant="special"
              fullWidth
              iconEnd={<Icon name="Ice" />}
              onClick={onCreateFreezeBooking}
              size="xl"
            >
              <FormattedMessageWrapper
                id="df.freezeOverlayButton"
                defaultMessage="Freeze price for free"
              />
            </Button>
          </ContentWrapper>
        </StickyContainer>
      )}
    </>
  )
}
