import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

import {Booking} from '@daedalus/core/src/api-types/bovio/response/booking'

import {State} from '../modules/slice'
import {
  BookingCreationResponse,
  BookingCreationStatusResponse,
  CreateBookingPayload
} from '../types/bookingCreation'
import {OfferCheckResponse} from '../types/offer'
import {setHeaders} from './utils'

export interface DealFreezeBookingData extends Booking {
  httpRequestId: string
}

enum METHODS {
  GET = 'GET',
  POST = 'POST',
  HEAD = 'HEAD',
  PUT = 'PUT'
}
const FROZEN_BOOKINGS_ENDPOINT = 'bookings?status=frozen'
const USER_LIMIT_CHECK_ENDPOINT = 'bookings/deal-freeze/user-limits-check'
interface QueryConfig<T = unknown> {
  useDefaultBaseUrl?: boolean
  url?: string
  body?: T
  method?: METHODS
}

/**
 * Deal Freeze APIs
 * Check API documentation here: https://github.com/FindHotel/bofh-api/blob/master/docs/consumers/default/endpoints/deal_freeze
 */
export const dealFreezeApi = createApi({
  reducerPath: 'dealFreezeApi',
  baseQuery: async (args: QueryConfig, api, extraOptions) => {
    const state = api.getState()
    const {dealFreeze} = state as {dealFreeze: State}
    const {api: stateApi} = dealFreeze
    const {boVioEndpoint} = stateApi

    const {useDefaultBaseUrl, method, url = METHODS.GET} = args

    /**
     * BoVio usually provides a link object, which has a list of APIs that can be called in their full form.
     * But there are some other cases that we need to initiate the first call to BoVio, for example, for when we need to get
     * user limit check, offer-check or retrieving a booking outside the DF flow. In those cases, when we use
     * RTK, we should add the baseUrl (domain name) to the endpoint path.
     * The condition below first checks if a RTK was called with `useDefaultBaseUrl`, then if required,
     * adds the `boVioEndpoint` that was stored on Redux store.
     */
    const argUrl = useDefaultBaseUrl ? `${boVioEndpoint}${url}` : url

    const fetchBaseArgs = {
      ...args,
      url: argUrl
    }

    const response = await fetchBaseQuery({
      method,
      prepareHeaders: (headers, {getState}) => {
        const dealFreezeState = getState() as {dealFreeze: State}
        return setHeaders(headers, dealFreezeState.dealFreeze)
      }
    })(fetchBaseArgs, api, extraOptions)

    if (method === METHODS.HEAD) {
      const status = response.meta?.response?.status
      const headers = response.meta?.response?.headers
      return {
        data: {
          status,
          headers
        }
      }
    }

    return response
  },
  endpoints: builder => ({
    checkUserLimit: builder.query({
      query: (): QueryConfig => ({
        url: USER_LIMIT_CHECK_ENDPOINT,
        method: METHODS.HEAD,
        useDefaultBaseUrl: true
      }),
      keepUnusedDataFor: 0
    }),
    offerCheck: builder.query<OfferCheckResponse, QueryConfig>({
      query: (queryConfig: QueryConfig): QueryConfig => ({
        url: queryConfig.url,
        useDefaultBaseUrl: true
      })
    }),
    createBooking: builder.mutation<BookingCreationResponse, QueryConfig>({
      query: (queryConfig: QueryConfig<CreateBookingPayload>): QueryConfig => ({
        url: queryConfig.url,
        body: queryConfig.body,
        method: METHODS.POST
      })
    }),
    getBookingStatus: builder.query<BookingCreationStatusResponse, QueryConfig>(
      {
        query: (queryConfig: QueryConfig): QueryConfig => ({
          url: queryConfig.url
        })
      }
    ),
    retrieveBooking: builder.query<DealFreezeBookingData, QueryConfig>({
      query: (queryConfig: QueryConfig): QueryConfig => ({
        url: queryConfig.url,
        useDefaultBaseUrl: queryConfig.useDefaultBaseUrl
      }),
      transformResponse: (response: Booking, meta) => {
        return {
          ...response,
          httpRequestId: meta?.response?.headers.get('x-request-id')
        } as DealFreezeBookingData
      }
    }),
    // documentation: https://github.com/FindHotel/bofh-api/blob/master/docs/consumers/default/endpoints/bookings.md
    getFrozenBookings: builder.query({
      query: (): QueryConfig => ({
        url: FROZEN_BOOKINGS_ENDPOINT,
        useDefaultBaseUrl: true
      })
    })
  })
})

export const {
  useCheckUserLimitQuery,
  useOfferCheckQuery,
  useCreateBookingMutation,
  useGetBookingStatusQuery,
  useRetrieveBookingQuery,
  useGetFrozenBookingsQuery
} = dealFreezeApi

export enum DealFreezeEndpoints {
  CheckUserLimit = 'checkUserLimit',
  OfferCheck = 'offerCheck',
  CreateBooking = 'createBooking',
  GetBookingStatus = 'getBookingStatus',
  RetrieveBooking = 'retrieveBooking',
  GetFrozenBookings = 'getFrozenBookings',
  // This is for SAPI room request (GET /rooms-offers), and is not covered by the above RTK endpoints
  SapiRoom = 'sapiRoom'
}
