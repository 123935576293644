import Settings from 'Settings'

import {MY_BOOKINGS_PATH} from '@daedalus/core/src/booking/constants'
import {ensureUrlCorrectSlashes} from '@daedalus/core/src/utils/url'

import buildUrl from './buildUrl'

const SEARCH_RESULTS_PATH = '/Hotel/Search'
const BOOKINGS_PATH = '/bookings'

// Ensures that public url always has a trailling slash
export const publicUrl = ensureUrlCorrectSlashes([Settings.get('PUBLIC_URL')])

export const getSearchUrl = (): string => {
  return Settings.get('REACT_APP_VIO_SEARCH_URL')
}

export const getSearchResultsUrl = () => {
  return new URL(SEARCH_RESULTS_PATH, getSearchUrl()).href
}

export const getBookingManagementUrl = (): string => {
  return new URL(BOOKINGS_PATH, Settings.get('REACT_APP_MY_VIO_URL')).href
}

export const getMyBookingsUrl = (searchParams?: Record<string, unknown>) => {
  try {
    const host = new URL(MY_BOOKINGS_PATH, Settings.get('REACT_APP_MY_VIO_URL'))
      .href

    return buildUrl(host, searchParams)
  } catch {
    return ''
  }
}

export const buildBookingManagementLink = (
  searchParams: Record<string, unknown>
) => {
  return buildUrl(getBookingManagementUrl(), searchParams)
}
