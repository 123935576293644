import * as React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {ThemeType} from 'types/Theme'

import {RangeSizesType} from '../../../../../types/Sizes'
import {mq} from '../../../../utils/breakpoints'

type PageWidthContainerPropsType = {
  /** The page content */
  children: React.ReactNode
  /** A class name that can be given to the container */
  className?: string
  /** The max width the page will be refined to. If not supplied it is taken from the theme file */
  maxPageWidth?: number
  /** Whether to span the container full width - this will override maxPageWidth */
  isFullWidth?: boolean
  /** The page padding at the desktop-sm breakpoint. Only spacing sizes from the theme are accepted */
  paddingDesktopSmSize?: RangeSizesType
  /** The page padding at the desktop-xs breakpoint. Only spacing sizes from the theme are accepted */
  paddingDesktopXsSize?: RangeSizesType
  /** The page padding at the desktop-md breakpoint. Only spacing sizes from the theme are accepted */
  paddingDesktopMdSize?: RangeSizesType
  /** The page padding at the desktop-lg breakpoint. Only spacing sizes from the theme are accepted */
  paddingDesktopLgSize?: RangeSizesType
  /** The page padding at the mobile-sm breakpoint. Only spacing sizes from the theme are accepted */
  paddingMobileSmSize?: RangeSizesType
  /** The page padding at the mobile-lg breakpoint. Only spacing sizes from the theme are accepted */
  paddingMobileLgSize?: RangeSizesType
}

type PageWidthContainerElementPropsType = {
  isFullWidth?: boolean
  maxPageWidth?: number
  paddingDesktopXsSize?: RangeSizesType
  paddingDesktopSmSize?: RangeSizesType
  paddingDesktopMdSize?: RangeSizesType
  paddingDesktopLgSize?: RangeSizesType
  paddingMobileSmSize?: RangeSizesType
  paddingMobileLgSize?: RangeSizesType
}

const pageWidthContainerStyles = ({
  theme,
  isFullWidth = false,
  maxPageWidth,
  paddingDesktopLgSize,
  paddingDesktopMdSize,
  paddingDesktopSmSize,
  paddingDesktopXsSize,
  paddingMobileSmSize,
  paddingMobileLgSize
}: PageWidthContainerElementPropsType & {theme: ThemeType}) => css`
  max-width: ${isFullWidth
    ? '100%'
    : `${maxPageWidth || theme.layout.maxPageWidth}px`};
  margin: 0 auto;
  padding: 0 ${theme.layout.spacing[paddingMobileSmSize || 's000']}px;
  ${mq.desktopXs(`
    width: 100%;
  `)}
  ${paddingMobileLgSize &&
  mq.mobileLg(`
    padding: 0 ${theme.layout.spacing[paddingMobileLgSize]}px;
  `)};
  ${paddingDesktopXsSize &&
  mq.desktopXs(`
    padding: 0 ${theme.layout.spacing[paddingDesktopXsSize]}px;
  `)};
  ${paddingDesktopSmSize &&
  mq.desktopSm(`
      padding: 0 ${theme.layout.spacing[paddingDesktopSmSize]}px;
    `)};
  ${paddingDesktopMdSize &&
  mq.desktopMd(`
    padding: 0 ${theme.layout.spacing[paddingDesktopMdSize]}px;
  `)};
  ${paddingDesktopLgSize &&
  mq.desktopLg(`
    padding: 0 ${theme.layout.spacing[paddingDesktopLgSize]}px;
  `)};
`

const PageWidthContainerElement = styled('div')(pageWidthContainerStyles)

export const PageWidthContainer = ({
  children,
  className,
  isFullWidth,
  maxPageWidth,
  paddingDesktopLgSize,
  paddingDesktopMdSize,
  paddingDesktopSmSize,
  paddingDesktopXsSize,
  paddingMobileSmSize,
  paddingMobileLgSize
}: PageWidthContainerPropsType) => (
  <PageWidthContainerElement
    className={className}
    maxPageWidth={maxPageWidth}
    isFullWidth={isFullWidth}
    paddingDesktopLgSize={paddingDesktopLgSize}
    paddingDesktopMdSize={paddingDesktopMdSize}
    paddingDesktopSmSize={paddingDesktopSmSize}
    paddingDesktopXsSize={paddingDesktopXsSize}
    paddingMobileSmSize={paddingMobileSmSize}
    paddingMobileLgSize={paddingMobileLgSize}
  >
    {children}
  </PageWidthContainerElement>
)
