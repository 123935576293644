import React from 'react'
import {useIntl} from 'react-intl'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Text} from '@daedalus/atlas/Text'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {
  numberOfRooms,
  roomsToConfigurationObject
} from '@daedalus/core/src/room/business/roomConfiguration'
import {Room, RoomOffer} from '@findhotel/sapi'

import {guestPickerMessages} from '../../../search/GuestPicker/guestPickerMessages'
import {RoomLabels} from './RoomLabels'

interface Props {
  roomsSplit: string
  numberOfNights: number
  room: Room | undefined | null
  offer: RoomOffer | undefined | null
}

const RoomTitle = styled(Text)(
  ({theme}) => css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: ${theme.layout.spacing.s500}px;
  `
)

const CenteredDot = styled.span(
  ({theme}) => css`
    vertical-align: text-bottom;
    margin: 0 ${theme.layout.spacing.s200}px;
  `
)

export const RoomDetail = ({
  roomsSplit,
  numberOfNights,
  room,
  offer
}: Props) => {
  const {formatMessage} = useIntl()

  if (!room) return null

  const roomsNumber = numberOfRooms(roomsSplit)
  const roomObject = roomsToConfigurationObject(roomsSplit)
  const guestsNumber = roomObject.numberOfAdults + roomObject.numberOfChildren

  const guestMessage = formatMessage(guestPickerMessages.guest, {
    count: guestsNumber
  })

  const roomsMessage = formatMessage(guestPickerMessages.room, {
    count: roomsNumber
  })

  const guestsText = guestsNumber ? `${guestsNumber} ${guestMessage}` : null
  const commaText =
    guestsNumber && roomsNumber ? <CenteredDot>.</CenteredDot> : null
  const roomsText = roomsNumber ? `${roomsNumber} ${roomsMessage}` : null

  const {name} = room

  return (
    <>
      <RoomTitle variant="titleM">{name}</RoomTitle>
      <Text variant="bodyS" colorPath="content.neutral.c600">
        {numberOfNights}{' '}
        <FormattedMessageWrapper
          id="nights"
          defaultMessage="{count, plural, one {Night} other {Nights}}"
          values={{
            count: numberOfNights
          }}
        />
        <CenteredDot>.</CenteredDot>
        {guestsText}
        {commaText}
        {roomsText}
      </Text>
      {offer && <RoomLabels offer={offer} />}
    </>
  )
}
