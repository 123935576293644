import {css} from '@emotion/react'
import styled from '@emotion/styled'

export const Header = styled('div')(
  ({theme}) => css`
    background: ${theme.colors.background.neutral.c000};
    position: relative;
  `
)

export const HeaderWrapper = styled('div')(
  ({theme}) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${theme.layout.spacing.s500}px ${theme.layout.spacing.s900}px
      ${theme.layout.spacing.s200}px;
  `
)

export const HeaderContent = styled('div')(
  ({theme}) => css`
    touch-action: none;
    flex-grow: 1;
    text-align: center;
    ${theme.typography.text.titleM};
    margin: 0;
    color: ${theme.colors.content.neutral.c950};
  `
)

export const SubHeader = styled('div')(
  ({theme}) => css`
    flex-grow: 1;
    text-align: center;
    ${theme.typography.text.titleS};
    color: ${theme.colors.content.neutral.c950};
    padding: 0 ${theme.layout.spacing.s900}px ${theme.layout.spacing.s400}px;
  `
)

export const OverlayContent = styled('div')(
  () => css`
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    height: 100%;
    // needed to apply extra padding at the bottom for iOS gesture bar
    padding-bottom: env(safe-area-inset-bottom);
  `
)

export const Handle = styled('div')(
  ({theme}) => css`
    touch-action: none;
    width: 32px;
    height: 4px;
    border-radius: ${theme.layout.radius.rounded}px;
    background-color: ${theme.colors.background.neutral.c200};
    margin-left: auto;
    margin-right: auto;
  `
)
