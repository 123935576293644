import React, {AnchorHTMLAttributes, ReactNode} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {SizesType} from 'types/Sizes'
import {Colors, ThemeType} from 'types/Theme'

import {mq} from '../../../utils/breakpoints'

export type LinkSize = Extract<SizesType, 'xs' | 'sm' | 'md'>
export type LinkVariant = keyof Colors['link']

interface Props {
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
  /** Size variant. If no size is provided, font-size inherited from surrounding text. */
  size?: LinkSize
  /** Link variant. Can be one of 'default', 'strong', 'info' or 'inverse'. 'strong' by default */
  variant?: LinkVariant
  /** An Icon to display on the right side of the Link for LTR languages. Inverted on RTL */
  iconEnd?: ReactNode
}

interface StyleProps {
  variant: LinkVariant
  isLight?: boolean
}

const textSizeMap = {
  xs: 'labelXS',
  sm: 'labelS',
  md: 'labelM'
} as const

const withTextSizes = ({theme, size}: {theme: ThemeType; size?: LinkSize}) => {
  if (size) {
    return css`
      ${theme.typography.text[textSizeMap[size]]}
    `
  }

  return css`
    font-size: inherit;
    line-height: inherit;
  `
}

const withColors = ({theme, variant}: StyleProps & {theme: ThemeType}) => {
  return css`
    color: ${theme.colors.link[variant].content};
    text-decoration: underline;

    ${mq.desktopXs(css`
      &:hover,
      :focus {
        color: ${theme.colors.link[variant].hover.content};
        text-decoration: none;
      }
    `)}

    &:active {
      color: ${theme.colors.link[variant].active.content};
      text-decoration: underline;
    }

    &:visited {
      color: ${theme.colors.link[variant].visited.content};
      text-decoration: underline;
    }
  `
}

const LinkWrapper = styled('a')(
  () => css`
    display: inline-block;
    vertical-align: baseline;
    text-decoration: none;
    font-size: inherit;
    line-height: inherit;
  `,
  withColors
)

export const TextWrapper = styled('span')<{size?: LinkSize}>(
  withTextSizes,
  () => css`
    user-select: none;
    ${mq.desktopXs(`user-select: auto;`)}
  `
)

export const IconWrapper = styled('span')(
  ({theme}) => css`
    display: inline-block;
    vertical-align: text-bottom;
    &:first-child {
      margin-right: ${theme.layout.spacing.s100}px;
    }
    &:last-child {
      margin-left: ${theme.layout.spacing.s100}px;
    }
  `
)

export const Link = ({
  children,
  size,
  variant = 'strong',
  dataId = '',
  iconEnd,
  className,
  ...anchorProps
}: Props & AnchorHTMLAttributes<HTMLAnchorElement>) => (
  <LinkWrapper
    data-id={dataId}
    variant={variant}
    className={className}
    {...anchorProps}
  >
    <TextWrapper size={size}>{children}</TextWrapper>
    {iconEnd && <IconWrapper>{iconEnd}</IconWrapper>}
  </LinkWrapper>
)
