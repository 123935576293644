import {CommonErrorPayloadFormatType} from 'types/errorResponse'

import {ErrorDetails} from '@daedalus/core/src/booking/types/ErrorDetails'
import {ErrorTypes} from '@daedalus/core/src/booking/types/ErrorTypes'

export const FATAL_ERROR = 'FATAL_ERROR'

type FatalErrorStackTraceType = {
  error: Error
  info: any
}

export type FatalErrorActionType = {
  error?: CommonErrorPayloadFormatType
  stackTrace: FatalErrorStackTraceType
  type: typeof FATAL_ERROR
}

export type ActionType = FatalErrorActionType

export interface OriginalError {
  response: {
    data: {
      details: ErrorDetails
    }
  }
}

export const fatalError = (
  originalError: Error & OriginalError,
  errorInfo?: any
): FatalErrorActionType => ({
  type: FATAL_ERROR,
  error: {
    originalErrorAction: FATAL_ERROR,
    errorResponse: {
      type: ErrorTypes.Fatal,
      providerCode: 'fatal_error',
      message: 'An Unknown Error happened',
      details: {
        recoverable: false,
        message: originalError?.message,
        category: originalError?.response?.data?.details?.category
      }
    }
  },
  stackTrace: {
    error: originalError,
    info: errorInfo
  }
})
