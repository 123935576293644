import {UserTier} from '../../offer/business/privateDeals'
import {User as CognitoUser, UserPoolId} from './Cognito'

export type AppMetaData = {
  tier?: UserTier[]
  lastCheckoutDate?: string
}

export type ClaimsType = {
  // Domain is dynamic, we only look at logins_count
  'http://findhotel.net/logins_count': number
  'http://findhotel.net/app_metadata': AppMetaData
  __raw: string
  aud: string
  email: string
  email_verified: boolean
  exp: number
  iat: number
  iss: string
  name: string
  nickname: string
  nonce: string
  picture: string
  sub: string
  updated_at: string
  'https://findhotel.net/user_id': string
  'https://findhotel.net/createdAt': string
}

/**
 * Source component enum for which component users clicked on login/signup button
 */
export enum SourceComponentType {
  Signup = 'Signup',
  Login = 'Login',
  UnlockOverlay = 'UnlockOverlay',
  Header = 'Header',
  PreferencesOverlay = 'PreferencesOverlay',
  PreferencesOverlayFooter = 'PreferencesOverlayFooter',
  UnlockOverlayFooter = 'UnlockOverlayFooter',
  MembershipActivate = 'MembershipActivate',
  MembershipPage = 'MembershipPage',
  AccountSideBar = 'AccountSideBar',
  CongratulationsOverlay = 'CongratulationsOverlay',
  PaymentPage = 'PaymentPage',
  // Those might be deprecated, if they are not being logged they should be removed
  UnauthorizedBookingErrorMonitor = 'UnauthorizedBookingErrorMonitor',
  RoomSelectionCard = 'RoomSelectionCard',
  DealFreezeButton = 'DealFreezeButton',
  SavingSectionHomePage = 'SavingSectionHomePage'
}

/**
 * Different types of authentication methods
 */
export enum AuthenticationMethod {
  App = 'App',
  Apple = 'Apple',
  Facebook = 'Facebook',
  Google = 'Google',
  SMS = 'SMS',
  Whatsapp = 'Whatsapp'
}

export type AuthConnection = {
  domain: string
  clientId: string
}

/**
 * Enumerable that holds the possible authentication flows an user can go through
 */
export enum AuthenticationFlows {
  UnlockOverlay = 'unlockOverlay',
  DealFreeze = 'dealFreeze',
  SignUp = 'signUp',
  Login = 'login',
  GiveawayCampaign = 'giveawayCampaign'
}

/**
 * Passwordless login flow necessary params
 */
export type ShowPasswordlessLoginPayload = {
  anonymousId: string
  flow: AuthenticationFlows
  isPasswordlessLoginVisible: boolean
  sourceComponent: string
  unlockHotelId?: string
  analyticsData?: Record<string, unknown>
}

export type AuthenticatedUser = {
  isAuthenticated: boolean
}

export type UserAction = {
  user: CognitoUser | null
  isAuthenticated: boolean
  shouldSeeOffersUnlocked: boolean
  userPoolId: UserPoolId | null
}

export type UserMetaType = {
  language?: string
}

export type UserType = {
  id?: string
  sub?: string
  name?: string
  phoneNumber?: string
  picture?: string
  email?: string
  email_verified?: boolean
  updated_at?: string
  firstName?: string
  lastName?: string
  userMetaData?: UserMetaType
}
