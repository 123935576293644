import {AuthenticationMethod} from '@daedalus/core/src/auth/types/Auth'
import {SearchParameters} from '@findhotel/sapi/dist/types/packages/core/src/types'

import {UserSearchHistoryItem} from '../searchHistory'
import {RNWebViewMessageTypes} from './types'

import type {User, UserAttributes} from '@daedalus/core/src/auth/types/Cognito'

type RNMessagableScreen =
  | 'Home'
  | 'ManageNotifications'
  | 'PrivacyPolicy'
  | 'Debug'

export type UserAuthenticatedPayload =
  | {
      user: User | undefined
    }
  | undefined

export type Message = {
  type: RNWebViewMessageTypes
  payload?:
    | {screen: RNMessagableScreen}
    | {currency: string}
    | {visibility: boolean}
    | {provider: AuthenticationMethod}
    | {userId: UserAttributes['sub']}
    | UserAuthenticatedPayload
    | {url: string}
    | {userSearchHistory: UserSearchHistoryItem[]}
    | SearchParameters
}

/**
 * Uses the `ReactNativeWebView` on the `window` to post a message to Vio.com mobile app.
 */
const postMessageToWebView = ({type, payload}: Message): void => {
  window.ReactNativeWebView?.postMessage(
    JSON.stringify({
      type: type,
      payload
    })
  )
}

export {postMessageToWebView}
