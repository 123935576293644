/**
 A Merchant Of Record or MoR is the legal entity responsible for processing payments and taking liability on the payment. Sometimes FindHotel will be the entity responsible
 for processing the payments. 
 In our checkout platform, the MoR sometimes is the destination hotel or other sources to be defined in the future.
 */
enum MerchantOfRecordEntity {
  /**
   * The hotel is responsible for the payment processing
   * */
  Hotel = 'hotel',
  /**
   * FindHotel is responsible for the payment processing
   * */
  FindHotel = 'findhotel'
}

export default MerchantOfRecordEntity
