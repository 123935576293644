import {Link} from 'react-router-dom'
import isPropValid from '@emotion/is-prop-valid'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Button} from '../Button'

const TOP_CONTENT_ELEMENT_HEIGHT = 48
const ICON_SIZE = 44
const MAX_LOGO_WIDTH = 120
export const MOBILE_HEADER_HEIGHT = 65

const mirror = () => css`
  /* @mirror */
`

interface HeaderElementProps {
  hasShadow: boolean
}

export const HeaderElement = styled('header')<HeaderElementProps>(
  ({theme, hasShadow}) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: ${theme.layout.spacing.s300}px 0;
    background-color: ${theme.colors.background.neutral.c000};
    text-align: center;

    ${hasShadow &&
    `
      box-shadow: ${theme.shadows.fixed};
    `}
  `
)

export const HeaderTopContentElement = styled('div')(
  ({theme}) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 ${theme.layout.spacing.s400}px;
    height: ${TOP_CONTENT_ELEMENT_HEIGHT}px;
  `
)

const logoStyles = ({compact = false}: {compact?: boolean}) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: ${ICON_SIZE}px;
  max-width: ${MAX_LOGO_WIDTH}px;
  width: auto;
  margin: 0;

  ${compact &&
  `
    width: ${ICON_SIZE}px;
    height: ${ICON_SIZE}px;
  `}
`
export const LogoLinkElement = styled(Link, {shouldForwardProp: isPropValid})(
  logoStyles
)

export const LogoAnchorElement = styled('a', {shouldForwardProp: isPropValid})(
  logoStyles
)

export const LogoElement = styled('div')(logoStyles)

export const BackButtonElement = styled(Button)(mirror)

export const CenterContentWrapper = styled('div')(
  ({theme}) => css`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    min-width: 0;
    text-align: center;
    ${theme.typography.text.titleM};
  `
)

export const Spacer = styled('div')`
  width: ${ICON_SIZE}px;
`

export const EndContentWrapper = styled('div')(
  () => css`
    min-width: ${ICON_SIZE}px;
  `
)
