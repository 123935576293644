import {OffersConfigurations} from '@daedalus/core/src/api-types/bovio/response/offers_configurations'
import {
  freeCancellationPenalty,
  getCancellationDeadline
} from '@daedalus/core/src/offer/business/cancellationPenalties'
import {Offer as OfferType} from '@daedalus/core/src/offer/types/offer'
import {Offer} from '@findhotel/sapi'
import {CancellationPenalties} from '@findhotel/sapi/dist/types/packages/core/src/types/types'

import {getDayDistance} from '../date'
export const isCheckInDateValid = (
  checkIn: string | Date,
  todayDate: string | Date,
  minCheckInDistance?: number,
  maxCheckInDistance?: number
) => {
  const checkInDistance = getDayDistance(checkIn, todayDate)
  if (!maxCheckInDistance || !minCheckInDistance) return false

  return (
    checkInDistance >= minCheckInDistance &&
    checkInDistance <= maxCheckInDistance
  )
}

/**
 * Checks if an offer is eligible for DealFreeze based on the defined criteria.
 * Returns true if the offer is eligible, false otherwise.
 * @param offer - The offer to be checked for eligibility.
 * @param todayDate - To keep the function pure and easy to test, the current date is passed as a parameter.
 * @param checkIn - The check-in date the user selected.
 * @param dealFreezeConfiguration - The deal freeze configurations from the offersConfigurations API.
 * @returns A boolean indicating whether the offer is eligible for DealFreeze.
 */
export const isOfferEligibleForDealFreeze = (
  offer: Offer | OfferType,
  checkIn: string,
  todayDate: string,
  dealFreezeConfiguration: OffersConfigurations['dealFreeze']
) => {
  const {
    offerCheckInDayDistanceEndLimit,
    offerCheckInDayDistanceStartLimit,
    offerAllowedProviders,
    offerMinimumDaysToCancel
  } = dealFreezeConfiguration || {}
  if (!offerAllowedProviders?.includes(offer?.providerCode)) {
    return false
  }

  if (
    !isCheckInDateValid(
      checkIn,
      todayDate,
      offerCheckInDayDistanceStartLimit,
      offerCheckInDayDistanceEndLimit
    )
  ) {
    return false
  }

  const cancellationPenalties =
    offer?.cancellationPenalties as CancellationPenalties[]

  const isFreeCancellable =
    cancellationPenalties?.[0]?.amount !== undefined &&
    freeCancellationPenalty(cancellationPenalties[0])
  const cancellationDeadline = getCancellationDeadline(cancellationPenalties)
  if (!isFreeCancellable || !cancellationDeadline) return false

  const freeCancellationDeadlineDistance = getDayDistance(
    todayDate,
    cancellationDeadline,
    true
  )
  return (
    offerMinimumDaysToCancel &&
    freeCancellationDeadlineDistance >= offerMinimumDaysToCancel
  )
}
