import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

const LineWrapper = styled.div(
  ({width}: {width: number}) => css`
    width: ${width}px;
  `
)

interface Props {
  width: number
}
export const Line = ({width}: Props) => (
  <LineWrapper width={width}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 8">
      <circle
        cx="4"
        cy="4"
        r="3"
        fill="#F36"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <circle cx="10.5" cy="4" r="1.5" fill="#F36" />
      <circle cx="15.5" cy="4" r="1.5" fill="#F36" />
      <circle cx="20.5" cy="4" r="1.5" fill="#F36" />
      <circle cx="25.5" cy="4" r="1.5" fill="#F36" />
      <circle
        cx="32"
        cy="4"
        r="3"
        fill="#F36"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  </LineWrapper>
)
