import React from 'react'
import {useSelector} from 'react-redux'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import ContentWrapper from '@daedalus/atlas/helpers/ContentWrapper'
import {Text} from '@daedalus/atlas/Text'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {
  getFreezeSelectedContext,
  getOfferEligibility
} from '../../modules/selector'
import {findRoomByOfferId} from '../../utils/offer'
import {FormattedExpirationDate} from '../common/FormattedExpirationDate'
import {HotelCard} from '../HotelCard'

type TextAlign = 'left' | 'center' | 'right'
const HeaderContainer = styled.div<{align: TextAlign}>(
  ({align = 'center'}) => css`
    text-align: ${align};
  `
)

interface Props {
  hasErrorCTA?: boolean
  headerAlign?: TextAlign
}
export const HotelCardWithHeaders: React.FC<Props> = ({
  hasErrorCTA = true,
  headerAlign = 'center'
}) => {
  const {hotel, searchDetail, rooms, sapiMatchedOffer} = useSelector(
    getFreezeSelectedContext
  )
  const {checkIn, checkOut, roomsSplit} = searchDetail || {}
  const {hotelName, starRating, imageURIs} = hotel || {}

  const {response: offerCheckResponse} = useSelector(getOfferEligibility)
  const room = findRoomByOfferId(rooms || [], sapiMatchedOffer?.id)

  return (
    <>
      <HeaderContainer align={headerAlign}>
        <Text variant="titleXL">
          <FormattedMessageWrapper
            id="df.freezeOverlayTitle"
            defaultMessage="Freeze this price for free"
          />
        </Text>

        {offerCheckResponse?.expiresAt && (
          <ContentWrapper paddingX={headerAlign === 'center' ? 's500' : 's000'}>
            <Text variant="bodyM">
              <FormattedMessageWrapper
                id="df.freezeOverlayExplanation"
                defaultMessage="Lock in the current price until {date} and book when you're ready."
                values={{
                  date: (
                    <FormattedExpirationDate
                      expiresAt={offerCheckResponse?.expiresAt}
                    />
                  )
                }}
              />
            </Text>
          </ContentWrapper>
        )}
      </HeaderContainer>

      <HotelCard
        hotelName={hotelName as string}
        checkIn={checkIn}
        checkOut={checkOut}
        roomsSplit={roomsSplit}
        imageUrl={imageURIs?.[0] as string}
        rating={starRating}
        room={room}
        offer={sapiMatchedOffer}
        hasErrorCTA={hasErrorCTA}
        shouldShowPayNow
      />
    </>
  )
}
