import {OfferCheckAPIResponse} from '@daedalus/core/src/offer/types/OfferCheck'
import {RoomWithoutOffers} from '@daedalus/core/src/room/types/room'

import {Bedroom} from '../../api-types/bovio/response/offer_check'

export const adaptOfferCheckBedTypes = (
  offerCheckBedrooms?: OfferCheckAPIResponse['room']['bedrooms']
) =>
  offerCheckBedrooms?.map((bedroom: Bedroom, index: number) => ({
    id: String(index),
    name: bedroom.description
  })) || []

export const adaptOfferCheckRoom = (
  offerCheckRoom?: OfferCheckAPIResponse['room']
): RoomWithoutOffers => {
  return {
    amenities: offerCheckRoom?.amenities || [],
    area: offerCheckRoom?.area,
    bedTypes: adaptOfferCheckBedTypes(offerCheckRoom?.bedrooms),
    name: offerCheckRoom?.displayName || '',
    description: offerCheckRoom?.description || '',
    id: offerCheckRoom?.id || '',
    masterId: offerCheckRoom?.masterId || '',
    images: offerCheckRoom?.images || [],
    occupationPerRoom: offerCheckRoom?.occupancy?.maxAllowed?.adults || 0
  }
}
