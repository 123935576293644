import {State} from '../modules/slice'

export const setHeaders = (headers: Headers, state: State) => {
  const {boVioProfile, userToken, xRequestId, clientUserAgent} = state.api

  const headersMap = {
    'Content-Type': 'application/json',
    'X-Auth-Token': userToken,
    'X-Api-Key': boVioProfile,
    'X-Request-Id': xRequestId,
    'X-Client-User-Agent': clientUserAgent
  }

  Object.entries(headersMap).forEach(([key, value]) => {
    if (value) headers.set(key, value)
  })

  return headers
}

export const getDealFreezeRetrieveBookingUrl = (bookingId: string) =>
  `bookings/deal-freeze/${bookingId}`
