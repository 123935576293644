import React, {Suspense} from 'react'
import {useSelector} from 'react-redux'
import {Router} from 'react-router-dom'
import {CacheProvider, ThemeProvider} from '@emotion/react'
import GetTheme from 'components/data/GetTheme'
import useParams from 'components/data/URLParams/useParams'
import ErrorOverlayMonitor from 'components/errorMonitors/ErrorOverlayMonitor'
import NetworkMonitor from 'components/errorMonitors/NetworkMonitor'
import {FetchIpInfo} from 'components/FetchIpInfo'
import InitializeABTesting from 'components/InitializeABTesting'
import InitializeSentry from 'components/InitializeSentry'
import {PasswordlessLoginWrapper} from 'components/PasswordlessLoginWrapper'
import UnknownErrorBoundary from 'components/UnknownErrorBoundary'
import {getCurrentBrand} from 'configs/brand'
import Settings from 'Settings'
import {ThemeType} from 'types/Theme'
import {history} from 'utils/history'
import {publicUrl} from 'utils/urls'

import {
  DeviceLayoutProvider,
  useDeviceLayout
} from '@daedalus/atlas/context/deviceLayout'
import GridGlobalStyles from '@daedalus/atlas/foundation/GridGlobalStyles'
import {CssBaseline} from '@daedalus/atlas/helpers/CssBaseline'
import FontsLoader from '@daedalus/atlas/helpers/FontsLoader'
import InitializeDataDogClient from '@daedalus/core/src/analytics/components/DataDogClient'
import {InjectFullStoryBodyClass} from '@daedalus/core/src/analytics/components/FullStoryLoader'
import {InitializeCustomerIo} from '@daedalus/core/src/analytics/components/InitializeCustomerIo'
import {TeamContextProvider} from '@daedalus/core/src/analytics/components/TeamContext'
import TrackScreenshot from '@daedalus/core/src/analytics/components/TrackScreenshot'
import {TrackWebVitals} from '@daedalus/core/src/analytics/components/TrackWebVitals'
import {Team} from '@daedalus/core/src/analytics/types/Events'
import {FetchUser} from '@daedalus/core/src/auth/components/FetchUser'
import SyncStateWithPrivilege from '@daedalus/core/src/auth/components/SyncStateWithPrivilege'
import TrackUserAuthentication from '@daedalus/core/src/auth/components/TrackUserAuthentication'
import OpticksProvider, {
  experimentVariationsStore
} from '@daedalus/core/src/experiments/components/OpticksProvider'
import {getDataFile} from '@daedalus/core/src/experiments/modules/selectors'
import ConfigureI18n from '@daedalus/core/src/localization/components/ConfigureI18n'
import InjectMetaDescription from '@daedalus/core/src/localization/components/InjectMetaDescription'
import LanguageConfig from '@daedalus/core/src/localization/components/LanguageConfig'
import {getTextDirection} from '@daedalus/core/src/localization/services/locale'
import {GetBoVioConfiguration} from '@daedalus/core/src/offer/services/offerConfigurationApi/GetBoVioConfiguration'
import {RouterProvider} from '@daedalus/core/src/router/RouterProvider'
import {getCookie} from '@daedalus/core/src/utils/cookies'
import {useSyncToCookieFromUrlParam} from '@daedalus/core/src/utils/cookies/hooks/useSyncToCookieFromUrlParam'
import {evaluateInRuntime} from '@daedalus/core/src/utils/css/emotion-rtl'
import {DebugPanelModules, DebugPanelProvider} from '@daedalus/debug-panel'
import {SusiProvider} from '@daedalus/shared/src/authentication/SUSI/bottomSheet/SusiBottomSheet/SusiProvider'
import {DealFreeze} from '@daedalus/shared/src/dealFreeze'
import {ApplicationNames} from '@daedalus/shared/src/dealFreeze/modules/slice'
import {CookieProBanner} from '@daedalus/shared/src/search/cookies'
import {ProfileKey} from '@findhotel/sapi/dist/types/packages/core/src/app-config'

import {openSearchBox} from './modules/common/actions/searchBox'
import {
  getAnonymousId,
  getSearchId,
  getUserCountry
} from './modules/meta/selectors'
import Routes from './routes'
import {imageProvider} from './utils/imageProvider'

const DEFAULT_LANGUAGE = 'en'
const FORCE_DISABLE_OPTIMIZELEY_LOG_KEY = 'force-disable-optimizely-logs'

const App = () => {
  const urlParams = useParams()
  const {locale: urlLocale, userCountry, currency} = urlParams
  const locale = urlLocale || getCookie('locale') || DEFAULT_LANGUAGE
  const {isRtl} = getTextDirection(locale)
  const brand = getCurrentBrand()
  const anonymousId = useSelector(getAnonymousId)
  useSyncToCookieFromUrlParam('locale', urlLocale)
  const appEnv = Settings.get('REACT_APP_ENV')
  const customerIoSiteId = Settings.get('WEBSITE_CUSTOMER_IO_TRACKING_SITE_ID')
  const boVioEndpoint = Settings.get('REACT_APP_BOFH_ENDPOINT')
  const boVioProfile = Settings.get('REACT_APP_BOFH_PROFILE_DEFAULT')
  const {isMobile} = useDeviceLayout()
  const datafile = useSelector(getDataFile) as {
    featureFlags: Array<{key: string}>
  }
  const optimizelyFlags = datafile?.featureFlags?.map(({key}) => key) || []

  const sapiProfileKey = Settings.get(
    'REACT_APP_SAPI_PROFILE_KEY'
  ) as ProfileKey
  const checkoutUrl = Settings.get('REACT_APP_CHECKOUT_URL')
  const bookingManagementUrl = Settings.get('REACT_APP_MY_VIO_URL')
  const appVersion = Settings.get('REACT_APP_VERSION')
  const forceDisableOptimizelyLog =
    localStorage.getItem(FORCE_DISABLE_OPTIMIZELEY_LOG_KEY) === 'true'

  return (
    <>
      <InitializeDataDogClient
        clientToken={Settings.get('REACT_APP_DD_CLIENT_TOKEN')}
        applicationId={Settings.get('REACT_APP_DD_RUM_APP_ID')}
        appVersion={Settings.get('REACT_APP_VERSION')}
        appEnv={Settings.get('REACT_APP_ENV')}
        sampleRate={Number(Settings.get('REACT_APP_DD_SAMPLE_RATE'))}
        premiumSampleRate={Number(
          Settings.get('REACT_APP_DD_REPLAY_SAMPLE_RATE')
        )}
        service="checkout"
      />
      <TrackScreenshot />
      <TrackWebVitals />
      <Suspense fallback="">
        <OpticksProvider
          dataFileUrl={Settings.get('REACT_APP_OPTIMIZELY_DATAFILE_URL')}
          disableOptimizelyLogs={forceDisableOptimizelyLog}
        >
          <FetchIpInfo>
            <InitializeABTesting>
              <LanguageConfig locale={locale} />
              <CacheProvider value={evaluateInRuntime(isRtl)}>
                <ConfigureI18n languageCode={locale} countryCode={userCountry}>
                  <DeviceLayoutProvider>
                    <GetTheme>
                      {(theme: ThemeType) => (
                        <ThemeProvider theme={theme}>
                          <InitializeSentry />
                          <InitializeCustomerIo
                            publicUrl={publicUrl}
                            customerIoSiteId={customerIoSiteId}
                          />
                          <CssBaseline />
                          <GridGlobalStyles />
                          <NetworkMonitor />
                          <FontsLoader brand={brand} languageCode={locale} />
                          <InjectFullStoryBodyClass />
                          <UnknownErrorBoundary>
                            <InjectMetaDescription brandName={brand.name} />
                            <Router history={history}>
                              <RouterProvider>
                                <SyncStateWithPrivilege />
                                <SusiProvider
                                  brand={brand}
                                  userCountryCode={userCountry}
                                >
                                  <DebugPanelProvider
                                    isMobile={isMobile}
                                    appEnv={appEnv}
                                    thirdPartyExperiments={optimizelyFlags}
                                    meta={{anonymousId: anonymousId || ''}}
                                    variationsStoreReader={
                                      experimentVariationsStore
                                    }
                                    modulesConfig={[
                                      DebugPanelModules.EXPERIMENTS,
                                      DebugPanelModules.SETTINGS
                                    ]}
                                    isSyntheticTest={false}
                                  >
                                    <Routes />
                                  </DebugPanelProvider>
                                </SusiProvider>
                                <ErrorOverlayMonitor />
                                <PasswordlessLoginWrapper
                                  brand={brand}
                                  languageCode={locale}
                                />
                                <TeamContextProvider team={Team.Select}>
                                  <CookieProBanner />
                                </TeamContextProvider>
                                <FetchUser publicUrl={publicUrl} />
                                <TrackUserAuthentication />
                                <GetBoVioConfiguration
                                  boVioUrl={boVioEndpoint}
                                  boVioProfile={boVioProfile}
                                />
                                <DealFreeze
                                  imageProvider={imageProvider}
                                  appCurrency={currency}
                                  sapiProfileKey={sapiProfileKey}
                                  application={ApplicationNames.Checkout}
                                  appVersion={appVersion}
                                  boVioEndpoint={boVioEndpoint}
                                  boVioProfile={boVioProfile}
                                  checkoutUrl={checkoutUrl}
                                  bookingManagementUrl={bookingManagementUrl}
                                  languageCode={locale}
                                  actions={{
                                    openSearchBox
                                  }}
                                  getSearchId={getSearchId}
                                  getCountryCode={getUserCountry}
                                />
                              </RouterProvider>
                            </Router>
                          </UnknownErrorBoundary>
                        </ThemeProvider>
                      )}
                    </GetTheme>
                  </DeviceLayoutProvider>
                </ConfigureI18n>
              </CacheProvider>
            </InitializeABTesting>
          </FetchIpInfo>
        </OpticksProvider>
      </Suspense>
    </>
  )
}

export default App
