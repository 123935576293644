import {useEffect, useMemo, useState} from 'react'

const useIsImageloaded = (src?: string) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isErrored, setIsErrored] = useState(false)

  const loadingState = useMemo(
    () => ({
      isLoaded,
      isErrored
    }),
    [isLoaded, isErrored]
  )

  useEffect(() => {
    if (src) {
      const image = new Image()

      const onLoad = () => {
        setIsLoaded(true)
        setIsErrored(false)
      }

      const onError = () => {
        setIsErrored(true)
      }

      image.addEventListener('load', onLoad)
      image.addEventListener('error', onError)

      // eslint-disable-next-line fp/no-mutation
      image.src = src

      return () => {
        image.removeEventListener('load', onLoad)
        image.removeEventListener('error', onError)
      }
    }
  }, [src])

  return loadingState
}

export default useIsImageloaded
