import React from 'react'

import {LinkType} from '@daedalus/core/src/booking/types/Link'

export enum ServerCode {
  Stripe = 'STP',
  Eps = 'EPS',
  Ppn = 'PPN'
}

export enum StatusCodes {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  TIMEOUT = 'TIMEOUT',
  ERROR = 'ERROR'
}

export interface FinalizationBookingResponse {
  success: boolean
  link?: LinkType
  error?: Error
}

export interface Psd2Strategy<SetupResponse, InitializeBookingParams> {
  setup: <Config>(config?: Config) => Promise<SetupResponse>
  beforeBooking?: () => void
  initializeBooking: (
    res: InitializeBookingParams
  ) => Promise<BaseInitializationResponse>
  finalizeBooking: () => Promise<FinalizationBookingResponse>
  iframeRef?: React.RefObject<HTMLIFrameElement>
  challenge: <Config>(config?: Config) => Promise<ChallengeResponse>
  isEnrolled: (
    resp: InitializeBookingParams,
    isChargeLater?: boolean
  ) => boolean
  serverCode: ServerCode
}

export interface BaseInitializationResponse {
  statusCode: StatusCodes
  message?: string
}

export interface ChallengeResponse {
  status: StatusCodes
  error?: any
}

export interface BoFHResponses<InitializationType> {
  initialization?: InitializationType
  challenge: unknown
  isChargeLater?: boolean
}
