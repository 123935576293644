/**
 * An {@link ActionLink} is the representation of a URL or request with its basic configurations.
 * Used to store the URLs the application needs to use to communicate with the API.
 * Example: The url to finalize the booking process is a `PUT` method with the query of `?book=true`
 */
export enum ActionLinkTypes {
  /**
   * Represents a link for the [GET /pre-book](https://github.com/FindHotel/bofh-api/blob/master/docs/consumers/default/endpoints/pre-book.md)
   * */
  PRE_BOOK = 'pre_book',
  /**
   * Represents a link for the [POST /bookings](https://github.com/FindHotel/bofh-api/blob/master/docs/consumers/default/endpoints/booking_creation.md)
   * */
  BOOK = 'booking_creation',
  /**
   * Represents a link for the [GET /payment-options](https://github.com/FindHotel/bofh-api/blob/master/docs/consumers/default/endpoints/payment-options.md)
   */
  PAYMENT_OPTIONS = 'payment_options',
  /**
   * Represents a link for the [GET /bookings/:id](https://github.com/FindHotel/bofh-api/blob/master/docs/consumers/default/endpoints/booking_retrieve.md)
   */
  MANAGE_BOOKING = 'manage_booking',
  /**
  /**
   * Represents a link for the [POST /bookings/async](https://github.com/FindHotel/bofh-api/blob/master/docs/consumers/default/endpoints/async/booking_creation.md)
   */
  BOOKING_CREATION_ASYNC = 'booking_creation_async',
  /**
   * Represents a link for the [POST /bookings/sb](https://github.com/FindHotel/bofh-api/blob/master/docs/consumers/default/endpoints/split_bookings/booking_creation.md)
   */
  SB_BOOKING_CREATION = 'sb_booking_creation',
  /**
   * Represents a link for the [PUT /bookings/:id/finalize/async](https://github.com/FindHotel/bofh-api/blob/master/docs/consumers/default/endpoints/async/booking_finalization.md)
   */
  BOOKING_FINALIZATION_ASYNC = 'booking_finalization_async',
  /**
   * Represents a link for the [PUT bookings/sb/finalization?ids[]](https://github.com/FindHotel/bofh-api/blob/master/docs/consumers/default/endpoints/split_bookings/booking_finalization.md)
   */
  SB_BOOKING_FINALIZATION = 'sb_booking_finalization',
  /**
   * Represents a link for the [DELETE /bookings/:id](https://github.com/FindHotel/bofh-api/blob/master/docs/consumers/default/endpoints/booking_cancellation.md)
   */
  BOOKING_CANCELLATION = 'booking_cancellation',
  /**
   * Represents a link for the [GET /bookings/:id/status](https://github.com/FindHotel/bofh-api/blob/master/docs/consumers/default/endpoints/booking_status.md)
   */
  BOOKING_STATUS = 'booking_status',
  /**
   * Represents a link for the [GET /bookings/:id/status](https://github.com/FindHotel/bofh-api/blob/master/docs/consumers/default/endpoints/split_bookings/booking_status.md)
   */
  SB_BOOKING_STATUS = 'sb_booking_status',
  /**
   * Represents a link for booking retrievement
   */
  BOOKING_RETRIEVE = 'booking_retrieve',
  /**
   * Represents a link for the [POST /bookings/:booking_id/send-confirmation](https://github.com/FindHotel/bofh-api/blob/master/docs/consumers/default/endpoints/send_confirmation.md)
   */
  BOOKING_CONFIRMATION_NOTIFICATION = 'booking_confirmation_notification',
  /**
   * Represents a link for the payment page on the checkout experience
   */
  CHECKOUT = 'checkout',
  /**
   * Represents a link for the [POST /v2/bookings/deal-freeze](https://github.com/FindHotel/bofh-api/blob/master/docs/consumers/default/endpoints/deal_freeze/booking_creation.md)
   */
  DEAL_FREEZE_BOOKING_CREATION = 'deal_freeze_booking_creation',
  /**
   * Represents a link for the [PUT /v2/bookings/:id/payment/confirmation](https://github.com/FindHotel/bofh-api/blob/master/docs/consumers/default/endpoints/booking_payment_confirmation.md)
   */
  BOOKING_PAYMENT_CONFIRMATION = 'booking_payment_confirmation',
  /**
   * Represents a link for the [POST /bookings/charge-later](https://github.com/FindHotel/bofh-api/blob/master/docs/consumers/default/endpoints/charge_later/booking_creation.md)
   */
  CHARGE_LATER_BOOKING_CREATION = 'charge_later_booking_creation',
  /**
   * Represents a link for the [POST /bookings/charge-later](https://github.com/FindHotel/bofh-api/blob/master/docs/consumers/default/endpoints/charge_later/booking_finalization.md)
   */
  CHARGE_LATER_BOOKING_FINALIZATION = 'charge_later_finalization',
  /**
   * Represents a link for the [PUT /v2/bookings/:id/payment](https://github.com/FindHotel/bofh-api/blob/master/docs/consumers/default/endpoints/booking_payment.md)
   */
  BOOKING_PAYMENT = 'booking_payment',
  /**
   * Represents a link for the [GET /v2/payment-methods](https://github.com/FindHotel/bofh-api/blob/master/docs/consumers/default/endpoints/payment_methods.md)
   */
  PAYMENT_METHODS = 'payment_methods'
}

/**
 * An {@link ActionLink} is the representation of a URL or request with its basic configurations.
 * Used to store the URLs the application needs to use to communicate with the API.
 * Example: The url to finalize the booking process is a `PUT` method with the query of `?book=true`
 */
export interface ActionLink {
  href: string
  method: 'GET' | 'POST' | 'PUT' | 'DELETE'
  type: ActionLinkTypes
  query?: string
}
