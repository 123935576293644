import React from 'react'
import {useIntl} from 'react-intl'

import {CrashErrorMessage} from './CrashErrorMessage'
import {searchErrorMessages} from './searchCrashErrorMessage'

const reload = () => {
  window.location.reload()
}

export const CrashErrorMessageContainer = () => {
  const {formatMessage} = useIntl()

  const t = {
    heading: formatMessage(searchErrorMessages.crashPageMessageTitle),
    message: formatMessage(searchErrorMessages.crashPageMessageBody),
    button: formatMessage(searchErrorMessages.crashPageButtonRefresh)
  }

  return <CrashErrorMessage t={t} onButtonClick={reload} />
}
