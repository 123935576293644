import {reject} from 'ramda'

import {
  HotelOfferEntity,
  Offer
} from '@findhotel/sapi/dist/types/packages/core/src'

import {isReactNativeWebView} from '../../native'
import {hasUnlockPlusTokenInUrl} from '../../offer/business/privateDeals'

/**
 * Returns string value for the `tier` parameter.
 */
interface GetUserTierForSapi {
  isMemberPlus: boolean
  isAuthenticated: boolean
  urlParams?: {token?: string}
  isBrandOffersLockedByDefault?: boolean
}
export const getUserTierForSapi = ({
  isMemberPlus,
  isAuthenticated,
  urlParams,
  isBrandOffersLockedByDefault = true
}: GetUserTierForSapi) => {
  const tier = isAuthenticated ? 'member' : ''

  const hasUnlockPlusToken = urlParams && hasUnlockPlusTokenInUrl(urlParams)
  const isReactNative = isReactNativeWebView()
  const shouldForcePlus =
    isMemberPlus ||
    hasUnlockPlusToken ||
    isReactNative ||
    !isBrandOffersLockedByDefault
  return shouldForcePlus ? 'plus' : tier
}

export const applyRateControl = (
  offerEntity: HotelOfferEntity,
  visibleTopOffersLimit: number
): HotelOfferEntity => {
  if (offerEntity?.offers) {
    const offers = reject(
      (offer: Offer) => Boolean(offer?.accessTier),
      offerEntity.offers
    )
    const lengthDifference = offerEntity.offers.length - offers.length

    const availableOffersCount =
      offerEntity.availableOffersCount - lengthDifference

    const hasMoreOffers = availableOffersCount > visibleTopOffersLimit

    return {
      ...offerEntity,
      availableOffersCount,
      // [OffersV3] Type mismatch during migration
      hasMoreOffers,
      offers
    } as HotelOfferEntity
  }

  return offerEntity
}
